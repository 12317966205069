import { useCallback, useState } from 'react'

import { CommentImage, CommentUploadedImage } from 'interfaces/comment'

const useCommentPopup = () => {
  // states and their setters specific to each Comments component in main canvas
  // note that temporal states for creating/moving comment are stored in comment.ts as redux states
  const [commentName, setCommentName] = useState('')
  const [commentImages, setCommentImages] = useState<CommentImage[]>([])
  const [commentBody, setCommentBody] = useState('')
  const [commentDeletingImageIndexes, setCommentDeletingImageIndexes] = useState<number[]>([])
  const [commentEditingCaptions, setCommentEditingCaptions] = useState<string[]>([])
  const [commentImageHandlingIndex, setCommentImageHandlingIndex] = useState(-1)
  const [commentOpeningImageIds, setCommentOpeningImageIds] = useState<string[]>([])
  const [commentUploadedImages, setCommentUploadedImages] = useState<CommentUploadedImage[]>([])
  const [isMovingComment, setIsMovingComment] = useState(false)

  const toggleCommentOpeningImageId = useCallback((id: string) => {
    setCommentOpeningImageIds((states) => {
      const ids = [...states]
      if (ids.includes(id)) {
        ids.splice(ids.indexOf(id), 1)
      } else {
        ids.push(id)
      }
      return ids
    })
  }, [])

  return {
    commentName,
    setCommentName,
    commentImages,
    setCommentImages,
    commentBody,
    setCommentBody,
    commentDeletingImageIndexes,
    setCommentDeletingImageIndexes,
    commentEditingCaptions,
    setCommentEditingCaptions,
    commentImageHandlingIndex,
    setCommentImageHandlingIndex,
    commentOpeningImageIds,
    setCommentOpeningImageIds,
    commentUploadedImages,
    setCommentUploadedImages,
    isMovingComment,
    setIsMovingComment,
    toggleCommentOpeningImageId,
  }
}

export default useCommentPopup
