import dayjs from 'dayjs'
import Decimal from 'decimal.js'

import { DIAMETERS, EDITOR_SHAPE_TEMP_ID_PREFIX, EDITOR_TOOLS, MILLIMETER_SCALE } from 'config/constants'

import { AnchorPoints, Anchors, CuboidAnchor, RectangleInBlueprint, ShapeKey, Shapes } from 'interfaces/interfaces'

/**
 * ファイル拡張子からContentTypeを返す
 * @param {string} fileName ファイル名
 * @return {string | null} 拡張子もしくはnull
 */
export const returnContentType = (fileName: string): string | null => {
  const extension: string | undefined = fileName.split('.').pop()
  const allowedExtensions = new Set<string | undefined>(['las', 'laz', 'ply', 'pts', 'xyz', 'xyzrgb', 'ifc'])
  if (allowedExtensions.has(extension)) return 'text/plain'
  return null
}

/**
 * 任意の桁で四捨五入する関数
 * @param {number} num 四捨五入する数値
 * @param {string} decimalBase numをdecimalBaseで整数化したときに、どの桁まで残すか。decimalBaseの1つ下の位で四捨五入する
 * @return {number} 四捨五入した値
 */
export const roundNumber = (
  num: number,
  decimalBase: '0.0000001' | '0.000001' | '0.00001' | '0.0001' | '0.001' | '0.01' | '0.1' | '1'
) => {
  //* decimalBaseの0の数をカウント
  const zeroMatch = decimalBase.match(/0/g)
  const zeroCount = zeroMatch ? zeroMatch.length : 0
  //* 丸め誤差対策のため、decimalBaseを整数値に変換
  const intDecimalBase = 10 ** zeroCount
  return Math.round(num * intDecimalBase) / intDecimalBase
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

//* ミリメートルからメートルへ変換（丸め誤差対策）
export const millimeterToMeter = (value: number) => new Decimal(value).div(MILLIMETER_SCALE).toNumber()

//* メートルからミリメートルへ変換（丸め誤差対策）
export const meterToMillimeter = (value: number) => new Decimal(value).mul(MILLIMETER_SCALE).toNumber()

/**
 * Get the distance label with unit
 * @param distance in meters
 * @returns label with unit
 */
export const getDistanceLabel = (distance: number) => {
  const roundedDistance = roundNumber(distance, '0.001')
  const distanceInMillimeter = meterToMillimeter(roundedDistance)

  if (distanceInMillimeter < 10000) {
    return `${distanceInMillimeter}mm`
  }

  return `${roundedDistance}m`
}

//* Used for Diameter dropdown
export const findDiameterKeyByValue = (diameterValue: number | undefined) =>
  Object.keys(DIAMETERS).find((key) => DIAMETERS[key] === diameterValue) || (diameterValue || '').toString()

export const shapesExist = (shapes: Shapes): boolean =>
  !!shapes.cylinders.filter((s) => !s.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)).length ||
  !!shapes.planes.filter((s) => !s.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)).length ||
  !!shapes.tori.filter((s) => !s.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)).length ||
  !!shapes.rhombi?.filter((s) => !s.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)).length

//* Used for checking clearing guidelines before switching tool
export const needClearAnchorFrames = (
  newTool: string,
  cuboidAnchor: CuboidAnchor | undefined,
  anchors: Anchors,
  spacerAnnotationAnchors: AnchorPoints[]
) => {
  // not clear guidelines if user switches to the tools that not modify the points
  if (newTool === EDITOR_TOOLS.DISTANCE || newTool === EDITOR_TOOLS.COMMENT) {
    return false
  }
  if (newTool === EDITOR_TOOLS.MOVE) {
    // clear guidelines if user switches to move tool but not for editing the points
    // editing points on guidelines is not allowed on CUBOID tools
    if (cuboidAnchor) {
      return true
    }

    // not clear guidelines if user switches to move tool for editing the points
    // editing points on guidelines is allowed on CYLINDER, TORUS and PLANE tools
    return false
  }

  // At this point, newTool is limited to: CYLINDER_CUBOID, TORUS_CUBOID, COMMENT_CUBOID, CYLINDER, TORUS, PLANE

  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (
    newTool !== EDITOR_TOOLS.TORUS_CUBOID &&
    newTool !== EDITOR_TOOLS.CYLINDER_CUBOID &&
    newTool !== EDITOR_TOOLS.COMMENT_CUBOID &&
    newTool !== EDITOR_TOOLS.PCD_TRIM_CUBOID &&
    !!cuboidAnchor
  ) {
    return true
  }
  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (newTool !== EDITOR_TOOLS.CYLINDER && !!anchors.cylinders.filter((a) => !a.deleted).length) {
    return true
  }
  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (newTool !== EDITOR_TOOLS.TORUS && !!anchors.tori.filter((a) => !a.deleted).length) {
    return true
  }
  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (
    newTool !== EDITOR_TOOLS.PLANE &&
    newTool !== EDITOR_TOOLS.PLANE_VIRTUAL &&
    !!anchors.planes.filter((a) => !a.deleted).length
  ) {
    return true
  }
  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (newTool !== EDITOR_TOOLS.SPACER_ANNOTATION && !!spacerAnnotationAnchors.filter((a) => !a.deleted).length) {
    return true
  }

  return false
}
export const shapesWithKeyShouldShow = (shapes: Shapes | Anchors, key: ShapeKey): boolean =>
  !!shapes[key].length && shapes[key].some((shape) => !shape.deleted)

export const scaleBlueprintRectangle = (
  position: RectangleInBlueprint | undefined,
  scale: number
): RectangleInBlueprint | undefined => {
  if (!position) {
    return undefined
  }
  return {
    coordinate: {
      x: position.coordinate.x * scale,
      y: position.coordinate.y * scale,
    },
    extent: {
      width: position.extent.width * scale,
      height: position.extent.height * scale,
    },
  }
}

export const compareUnsortedArrays = (array1: string[] | number[], array2: string[] | number[]): boolean =>
  JSON.stringify(array1) === JSON.stringify(array2)

export const compareSortedArrays = (array1: string[] | number[], array2: string[] | number[]): boolean =>
  JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())

/**
 * Get remaining days to the first day of the next month
 */
export const getRemainingDays = () => {
  const today = dayjs()
  const lastDayOfThisMonth = dayjs().endOf('month')

  return lastDayOfThisMonth.diff(today, 'day') + 1
}

/**
 * Remove special characters from the filename
 * @param {string} filename
 * @returns {string} file name that has been sanitized
 */
export const sanitizeFilename = (filename: string): string => filename.replaceAll(/[<>:"/\\|?*]+/g, '_')

/**
 * extract texts sandwiched with given letters
 *
 * @param {string} text text to extract sandwiched letters
 * @param {string} letterStart start letter
 * @param {string} letterEnd end letter
 * @returns {string[]} extracted texts
 */
export function splitWithSandwichLetters(text: string, letterStart: string, letterEnd: string): string[] {
  const regex = new RegExp(`(${letterStart}.*?${letterEnd})`, 'g')
  return text.split(regex)
}
