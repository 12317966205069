import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL, AUTH0_AUDIENCE } from 'config/environments'

import { Invitation, UserInfo, UserProfile } from 'interfaces/user'

const USER_API_URL = `${API_GATEWAY_URL}/users`
const SEND_INVITATION_API_URL = `${API_GATEWAY_URL}/invitations`
const SEND_INVITATION_EMAIL_API_URL = `${API_GATEWAY_URL}/send-email`
const REGISTER_USER_API_URL = `${API_GATEWAY_URL}/register-auth0-account`

/**
 * Get user information
 * @param {string} access_token access token
 * @param {string} user_id user ID
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {UserInfo | null} user information
 */
export const getUser = async (
  access_token: string,
  user_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<UserInfo | null> => {
  const user = await axios
    .get<UserInfo>(`${USER_API_URL}/${user_id}?organization_details=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_USER)
      return null
    })

  return user
}

/**
 * invite someone to create a user account
 * @param {string} access_token access token
 * @param {string} email_address email address of the invitee
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Invitation} invitation information
 */
export const inviteUser = async (
  access_token: string,
  email_address: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Invitation | null> => {
  const user = await axios
    .post<Invitation>(
      `${SEND_INVITATION_API_URL}`,
      {
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.INVITE_USER)
      return null
    })

  return user
}

/**
 * Send email notification
 * @param {string} access_token access token
 * @param {string} email_address email address
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {boolean} whether the API call was successful
 */
export const sendInvitationEmail = async (
  access_token: string,
  email_address: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .post<boolean>(
      `${SEND_INVITATION_EMAIL_API_URL}`,
      {
        category: 'invite-to-modely',
        info: {
          recipient: email_address,
          url: `${AUTH0_AUDIENCE}/register?email_address=${email_address}`,
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.INVITE_USER)
      return false
    })

  return result
}

/**
 * Register a user
 * @param {string} email_address email address
 * @param {string} language_preference language preference
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {boolean} whether the API call was successful
 */
export const registerUser = async (
  email_address: string,
  language_preference: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const param = language_preference ? `?lng_pref=${language_preference}` : ''
  const result = await axios
    .post<boolean>(`${REGISTER_USER_API_URL}${param}`, {
      email_address,
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.REGISTER_USER)
      return false
    })

  return result
}

/**
 * Get user list, for admin account only
 * @param {string} access_token
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {UserProfile[] | null} list of user profiles
 */
export const getUserList = async (
  access_token: string,
  handleError: (err: unknown, processName: string) => void
): Promise<UserProfile[] | null> => {
  const users = await axios
    .get<{ results: UserProfile[] }>(`${USER_API_URL}?hubspot_only=true&hubspot_properties=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_USER)
      return null
    })

  return users
}

/**
 * Set language preference
 * @param {string} access_token access token
 * @param {string} user_id user ID
 * @param {string} language_preference language preference
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {UserProfile | null} updated user profile
 */
export const updateLanguage = async (
  access_token: string,
  user_id: string,
  language_preference: string,
  handleError: (err: unknown, processName: string) => void
): Promise<UserProfile | null> => {
  const userProfile = await axios
    .patch<UserProfile>(
      `${USER_API_URL}/${user_id}`,
      { language_preference },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_USER)
      return null
    })

  return userProfile
}
