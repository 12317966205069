import { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { ColorResult, SketchPicker } from 'react-color'
import { Trans, useTranslation } from 'react-i18next'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import usePrevious from 'hooks/PreviousState'

import { EDITOR_SHAPE_TEMP_ID_PREFIX, MODAL_TYPES } from 'config/constants'

import { ShapeGroup } from 'interfaces/shape'

import { createShapeGroup, deleteShapeGroup, getShapeGroups, updateShapeGroup } from 'services/ShapeGroups'

export const DEFAULT_GROUP_COLOR = '#F5A623'

const CreateShapeGroupModal: FC<{
  isOpen: boolean
  selectedShapeGroup?: ShapeGroup
  onClose: () => void
}> = ({ isOpen, selectedShapeGroup, onClose }) => {
  const { t } = useTranslation(['projects'])
  const { getAccessToken } = useContext(UserContext)
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { selectedShapeIds, shapes, shapeGroups, project, updateShapeGroups, updateSelectedShapeIds } =
    useContext(EditorContext)
  const prevShapes = usePrevious(shapes)
  const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
  const cylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const tori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))

  const [name, setName] = useState('')
  const [color, setColor] = useState(DEFAULT_GROUP_COLOR)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setName(selectedShapeGroup?.grouping_shape_type_name || '')
    setColor(selectedShapeGroup?.color_palette_code || DEFAULT_GROUP_COLOR)
  }, [isOpen, selectedShapeGroup])

  const fetchShapeGroups = useCallback(async () => {
    if (!project?.project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const retrievedShapeGroups = await getShapeGroups(token, project.project_id, handleError)

    if (retrievedShapeGroups) {
      updateShapeGroups(retrievedShapeGroups)
    } else {
      updateShapeGroups([])
    }

    setIsLoading(false)
    return true
  }, [getAccessToken, project, handleError, updateShapeGroups])

  useEffect(() => {
    // fetch for the first load
    void fetchShapeGroups()
  }, [fetchShapeGroups])

  useEffect(() => {
    // refetch if any cylinder/torus are deleted
    if (
      prevShapes &&
      (shapes.cylinders.length < prevShapes.cylinders.length || shapes.tori.length < prevShapes.tori.length)
    ) {
      void fetchShapeGroups()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapes])

  const onCreate = async () => {
    if (!project?.project_id) {
      return false
    }

    setIsLoading(true)
    const groupName = name.trim()

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const cylinder_ids = cylinders.map((s) => s.shape_id)
    const torus_ids = tori.map((s) => s.shape_id)
    const result = await createShapeGroup(
      token,
      project.project_id,
      {
        grouping_shape_type_name: groupName,
        color_palette_code: color,
        cylinder_ids,
        torus_ids,
      },
      handleError
    )

    if (!result) {
      setIsLoading(false)
      return false
    }

    // track with mixpanel
    mixpanel.track('Create Type (種別)', {
      'Inspection area ID': project.project_id,
      'Type name': result.grouping_shape_type_name,
      'Type color': result.color_palette_code,
      'Type ID': result.grouping_shape_type_id,
      'Shape type': cylinder_ids.length > 0 ? 'cylinders' : 'tori',
      'Shape number': cylinder_ids.length + torus_ids.length,
    })

    updateShapeGroups([...shapeGroups, result])

    onClose()
    setIsLoading(false)
    return true
  }

  const onUpdate = async () => {
    if (!selectedShapeGroup || !project?.project_id) {
      return false
    }

    setIsLoading(true)
    const groupName = name.trim()

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const result = await updateShapeGroup(
      token,
      project.project_id,
      {
        ...selectedShapeGroup,
        grouping_shape_type_name: groupName,
        color_palette_code: color,
      },
      handleError
    )

    if (!result) {
      setIsLoading(false)
      return false
    }

    // track with mixpanel
    const updatedItems = { 'type name': {}, 'type color': {} }
    if (selectedShapeGroup.grouping_shape_type_name !== groupName)
      updatedItems['type name'] = { 'old value': selectedShapeGroup.grouping_shape_type_name, 'new value': groupName }
    if (selectedShapeGroup.color_palette_code !== color)
      updatedItems['type color'] = { 'old value': selectedShapeGroup.color_palette_code, 'new value': color }
    mixpanel.track('Update Type (種別)', {
      'Inspection area ID': project.project_id,
      'Updated property': 'type name and/or type color',
      'Updated items': updatedItems,
      'Type ID': selectedShapeGroup.grouping_shape_type_id,
      'Shape type': selectedShapeGroup.cylinder_ids?.length ? 'cylinders' : 'tori',
      'Shape number': (selectedShapeGroup.cylinder_ids?.length ?? 0) + (selectedShapeGroup.torus_ids?.length ?? 0),
    })

    const groupIndex = shapeGroups.indexOf(selectedShapeGroup)
    const newGroups = [...shapeGroups]
    newGroups[groupIndex].color_palette_code = color
    newGroups[groupIndex].grouping_shape_type_name = groupName
    updateShapeGroups(newGroups)

    onClose()
    setIsLoading(false)
    return true
  }

  const onRepick = () => {
    if (!selectedShapeGroup) {
      return
    }

    const groupIndex = shapeGroups.indexOf(selectedShapeGroup)
    const newGroups = [...shapeGroups]
    newGroups[groupIndex].selected = true
    updateShapeGroups(newGroups)
    updateSelectedShapeIds((newGroups[groupIndex].cylinder_ids || []).concat(newGroups[groupIndex].torus_ids || []))

    onClose()
  }

  const onDelete = () => {
    if (!selectedShapeGroup || !project?.project_id) {
      return false
    }

    return showModal({
      body: (
        <Text>
          <Trans i18nKey="main_canvas.modals.delete_shape_group.text" ns="projects" />
        </Text>
      ),
      confirmText: t('main_canvas.modals.delete_shape_group.confirm', { ns: 'projects' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          setIsLoading(true)

          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          const result = await deleteShapeGroup(token, project.project_id, selectedShapeGroup, handleError)

          if (!result) {
            setIsLoading(false)
            return false
          }

          // track with mixpanel
          mixpanel.track('Delete Type (種別)', {
            'Inspection area ID': project.project_id,
            'Type name': selectedShapeGroup.grouping_shape_type_name,
            'Type color': selectedShapeGroup.color_palette_code,
            'Type ID': selectedShapeGroup.grouping_shape_type_id,
            'Shape type': selectedShapeGroup.cylinder_ids?.length ? 'cylinders' : 'tori',
            'Shape number':
              (selectedShapeGroup.cylinder_ids?.length ?? 0) + (selectedShapeGroup.torus_ids?.length ?? 0),
          })

          updateShapeGroups(shapeGroups.filter((group) => group !== selectedShapeGroup))

          onClose()
          setIsLoading(false)
          return true
        })()
        return true
      },
      title: t('main_canvas.modals.delete_shape_group.title', { ns: 'projects' }),
    })
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} trapFocus={false} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedShapeGroup
            ? t('main_canvas.action_buttons.update_group', { ns: 'projects' })
            : t('main_canvas.modals.create_shape_group.title', { ns: 'projects' })}
        </ModalHeader>
        <ModalBody position="relative">
          <VStack spacing={4}>
            {selectedShapeIds.some((id) => id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) && (
              <Text>{t('main_canvas.modals.create_shape_group.warning_unsaved_rebars', { ns: 'projects' })}</Text>
            )}
            <FormControl flex={1}>
              <FormLabel htmlFor="name">
                {t('main_canvas.modals.create_shape_group.group_name', { ns: 'projects' })}
              </FormLabel>
              <InputGroup size="md">
                <Input id="name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="name">
                {t('main_canvas.modals.create_shape_group.color', { ns: 'projects' })}
              </FormLabel>
              <Popover matchWidth>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    height={8}
                    p={0}
                    backgroundColor={color}
                    borderColor="secondary.200"
                    _hover={{ bg: color }}
                    _focus={{ bg: color }}
                    _active={{ bg: color }}
                  />
                </PopoverTrigger>
                <PopoverContent w="auto">
                  <SketchPicker
                    disableAlpha
                    color={color}
                    onChangeComplete={(newColor: ColorResult) => setColor(newColor.hex)}
                  />
                </PopoverContent>
              </Popover>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={onClose}>
            {t('main_canvas.modals.create_shape_group.cancel', { ns: 'projects' })}
          </Button>
          {!!selectedShapeGroup && (
            <>
              <Button me={3} py={2} minW="100px" onClick={onRepick}>
                {t('main_canvas.modals.create_shape_group.reselect_rebars', { ns: 'projects' })}
              </Button>
              <Button disabled={isLoading} me={3} py={2} minW="90px" colorScheme="red" onClick={onDelete}>
                {t('main_canvas.modals.create_shape_group.delete', { ns: 'projects' })}
              </Button>
            </>
          )}
          <Button
            disabled={isLoading || !name.trim()}
            me={3}
            py={2}
            minW="90px"
            colorScheme="primary"
            onClick={() => (selectedShapeGroup ? onUpdate() : onCreate())}
          >
            {selectedShapeGroup
              ? t('main_canvas.modals.create_shape_group.update', { ns: 'projects' })
              : t('main_canvas.modals.create_shape_group.create', { ns: 'projects' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateShapeGroupModal
