import axios from 'extends/axios'
import { BufferAttribute, BufferGeometry, Color, DoubleSide, Matrix4, Mesh, MeshPhongMaterial } from 'three'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils'
import * as WebIFC from 'web-ifc'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { IFCFile } from 'interfaces/interfaces'

const PROJECT_GROUPS_API_URL = `${API_GATEWAY_URL}/project-groups`

/**
 * fetch the meta data of all the ifc files in a project group
 * including the signed url to download the files
 *
 * @async
 * @param {string} access_token access token
 * @param {string} project_group_id project group id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Promise<IFCFile[] | null>} list of ifc meta data
 */
export const getIFCFiles = async (
  access_token: string,
  project_group_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<IFCFile[] | null> => {
  const ifcFiles = await axios
    .get<{ results: IFCFile[] }>(`${PROJECT_GROUPS_API_URL}/${project_group_id}/ifc-files?original_file_url=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_IFC_FILES)
      return null
    })

  return ifcFiles
}

/**
 * Get signed url for upload ifc file
 * @param {string} access_token access token
 * @param {string} project_group_id project group id
 * @param {string} filename filename
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {IFCFile}
 */
export const getSignedUrlForUploadIFC = async (
  access_token: string,
  project_group_id: string,
  filename: string,
  handleError: (err: unknown, processName: string) => void
): Promise<IFCFile | null> => {
  const signedFile = await axios
    .post<IFCFile>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}/ifc-files`,
      {
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.ADD_IFC)
      return null
    })

  return signedFile
}

/**
 * Update or insert a ifc metadata
 * @param {string} access_token access token
 * @param {string} project_group_id project_group_id
 * @param {IFCFile} ifcFile ifc file
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {IFCFile}
 */
export const upsertIFCFileMetaData = async (
  access_token: string,
  project_group_id: string,
  ifcFile: IFCFile,
  handleError: (err: unknown, processName: string) => void
): Promise<IFCFile | null> => {
  const ifcMetaData = await axios
    .put<IFCFile>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}/ifc-files/${ifcFile.ifc_file_id}`,
      {
        s3_filename: ifcFile.s3_filename,
        original_filename: ifcFile.original_filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.ADD_IFC)
      return null
    })

  return ifcMetaData
}

/**
 * Delete an ifc file from the project group
 *
 * @async
 * @param {string} access_token access token
 * @param {string} project_group_id project group id
 * @param {string} ifc_file_id ifc file id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Promise<boolean>} whether deletion is successful
 */
export const deleteIFCFile = async (
  access_token: string,
  project_group_id: string,
  ifc_file_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECT_GROUPS_API_URL}/${project_group_id}/ifc-files/${ifc_file_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_IFC)
      return false
    })

  return result
}

/**
 * convert color/vertices/indices data (ifc) to buffer geometry (three.js)
 *
 * @param {WebIFC.Color} color color for all the vertices
 * @param {Float32Array} vertexData vertices (position and normal vector)
 * @param {Uint32Array} indexData indices
 * @returns {BufferGeometry} resultant geometry
 */
const ifcGeometryToBuffer = (color: WebIFC.Color, vertexData: Float32Array, indexData: Uint32Array) => {
  const geometry = new BufferGeometry()

  const posFloats = new Float32Array(vertexData.length / 2)
  const normFloats = new Float32Array(vertexData.length / 2)
  const colorFloats = new Float32Array(vertexData.length / 2)

  for (let i = 0; i < vertexData.length; i += 6) {
    posFloats[i / 2 + 0] = vertexData[i + 0]
    posFloats[i / 2 + 1] = vertexData[i + 1]
    posFloats[i / 2 + 2] = vertexData[i + 2]

    normFloats[i / 2 + 0] = vertexData[i + 3]
    normFloats[i / 2 + 1] = vertexData[i + 4]
    normFloats[i / 2 + 2] = vertexData[i + 5]

    colorFloats[i / 2 + 0] = color.x
    colorFloats[i / 2 + 1] = color.y
    colorFloats[i / 2 + 2] = color.z
  }

  geometry.setAttribute('position', new BufferAttribute(posFloats, 3))
  geometry.setAttribute('normal', new BufferAttribute(normFloats, 3))
  geometry.setAttribute('color', new BufferAttribute(colorFloats, 3))
  geometry.setIndex(new BufferAttribute(indexData, 1))

  return geometry
}

/**
 * download ifc file and convert it to meshes (three.js)
 *
 * @async
 * @param {string} fileURL URL to download the ifc file to be converted
 * @returns {meshArray: {combinedGeometry: BufferGeometry, mat: MeshPhongMaterial}[],
 * transMeshArray: {combinedGeometryTransp: BufferGeometry, matTransp: MeshPhongMaterial}[],
 * geometries: BufferGeometry[]}
 */
export const convertIFCToMeshes = async (fileURL: string) => {
  const ifcApi = new WebIFC.IfcAPI()
  await ifcApi.Init()

  const fetchedResponse = await fetch(fileURL)
  const data = await fetchedResponse.arrayBuffer()
  const buffer = new Uint8Array(data)
  const modelID = ifcApi.OpenModel(buffer)

  // load all geometry in a model
  const flatMeshes = ifcApi.LoadAllGeometry(modelID)

  const transparentGeometries: BufferGeometry[] = []
  const geometries: BufferGeometry[] = []

  for (let i = 0; i < flatMeshes.size(); i += 1) {
    const flatMesh = flatMeshes.get(i)
    const flatMeshGeometries = flatMesh.geometries

    for (let j = 0; j < flatMeshGeometries.size(); j += 1) {
      const geometryExpressId = flatMeshGeometries.get(j).geometryExpressID

      const geometry = ifcApi.GetGeometry(modelID, geometryExpressId)

      const geometryVertexArray = ifcApi.GetVertexArray(geometry.GetVertexData(), geometry.GetVertexDataSize())
      const geometryIndexData = ifcApi.GetIndexArray(geometry.GetIndexData(), geometry.GetIndexDataSize())

      const { color } = flatMeshGeometries.get(j)
      const col = new Color(color.x, color.y, color.z)
      const material = new MeshPhongMaterial({ color: col, side: DoubleSide })
      material.transparent = color.w !== 1
      if (material.transparent) material.opacity = color.w

      const geometryD = ifcGeometryToBuffer(color, geometryVertexArray, geometryIndexData)
      const mesh = new Mesh(geometryD, material)

      const { flatTransformation } = flatMeshGeometries.get(j)
      mesh.matrix = new Matrix4().fromArray(flatTransformation)
      mesh.matrixAutoUpdate = false
      const geom = mesh.geometry.applyMatrix4(mesh.matrix)

      const propertySet = ifcApi.GetLine(modelID, geometryExpressId) as WebIFC.IFC2X3.IfcPropertySet
      geom.name = propertySet.Name?.value || ''

      if (color.w !== 1) {
        transparentGeometries.push(geom)
      } else {
        geometries.push(geom)
      }
    }
  }

  const mergedMeshes = []
  if (geometries.length > 0) {
    const combinedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries)
    const mat = new MeshPhongMaterial({ side: DoubleSide })
    mat.vertexColors = true

    mergedMeshes.push({ combinedGeometry, mat })
  }

  const transMeshes = []
  if (transparentGeometries.length > 0) {
    const combinedGeometryTransp = BufferGeometryUtils.mergeBufferGeometries(transparentGeometries)
    const matTransp = new MeshPhongMaterial({ side: DoubleSide })
    matTransp.vertexColors = true
    matTransp.transparent = true
    matTransp.opacity = 0.5

    transMeshes.push({ combinedGeometryTransp, matTransp })
  }

  ifcApi.CloseModel(modelID)

  return { meshArray: mergedMeshes, transMeshArray: transMeshes, geometries }
}
