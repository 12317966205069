import axios from 'extends/axios'

import { API_PROCESS_MAP, LICENSE_ASSIGNMENT_STATUS, PRICING_PLANS } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { Organization } from 'interfaces/interfaces'

/**
 * decides the status of the license assignment
 *
 * @param userId ID of the target user
 * @param licenseAssignedUsers list of user IDs to which license is assigned
 * @returns status of the license assignment
 */
export function decideLicenseAssignmentStatus(
  userId: string,
  licenseAssignedUsers: string[],
  pricingPlan: string | null,
  additionalFeatureActivatedUsers: string[]
): string {
  if (!licenseAssignedUsers.includes(userId)) return LICENSE_ASSIGNMENT_STATUS.view_only

  // license is assigned
  if (pricingPlan !== PRICING_PLANS.PAY_ON_DEMAND) return LICENSE_ASSIGNMENT_STATUS.assigned

  // pricing plan is pay-on-demand
  if (additionalFeatureActivatedUsers.includes(userId)) return LICENSE_ASSIGNMENT_STATUS.assigned_premium
  return LICENSE_ASSIGNMENT_STATUS.assigned_no_premium
}

/**
 * Rename an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The organization ID to update.
 * @param {string} organization_name - The new organization name.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Organization | null} The updated organization.
 */
export const renameOrganization = async (
  access_token: string,
  organization_id: string,
  organization_name: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Organization | null> =>
  axios
    .patch<Organization>(
      `${API_GATEWAY_URL}/organizations/${organization_id}`,
      {
        organization_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_ORGANIZATIONS)
      return null
    })

/**
 * Invite a user to an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization to invite the user to.
 * @param {string} email_address - The email address of the user to invite.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Organization | null} The updated organization.
 */
export const inviteUserToOrganization = async (
  access_token: string,
  organization_id: string,
  email_address: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Organization | null> =>
  axios
    .put<Organization | null>(
      `${API_GATEWAY_URL}/organizations/${organization_id}/users/${email_address}`,
      {
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.INVITE_USER_TO_ORGANIZATION)
      return null
    })

/**
 * Remove a user from an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization to remove the user from.
 * @param {string} user_id - The ID of the user to remove.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean} True if the user was removed successfully, false otherwise.
 */
export const removeUserFromOrganization = async (
  access_token: string,
  organization_id: string,
  user_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> =>
  axios
    .delete(`${API_GATEWAY_URL}/organizations/${organization_id}/users/${user_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.REMOVE_USER_FROM_ORGANIZATION)
      return false
    })

/**
 * Assign a license to a user
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization.
 * @param {string} user_id - The ID of the user to assign the license to.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean} True if the license was assigned successfully, false otherwise.
 */
export const assignLicenseToUser = async (
  access_token: string,
  organization_id: string,
  user_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> =>
  axios
    .put(
      `${API_GATEWAY_URL}/organizations/${organization_id}/users/${user_id}/license`,
      {},
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.ASSIGN_LICENSE_TO_USER)
      return false
    })

/**
 * Strip a license from a user
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization.
 * @param {string} user_id - The ID of the user to strip the license from.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean} True if the license was stripped successfully, false otherwise.
 */
export const stripLicenseFromUser = async (
  access_token: string,
  organization_id: string,
  user_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> =>
  axios
    .delete(`${API_GATEWAY_URL}/organizations/${organization_id}/users/${user_id}/license`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.STRIP_LICENSE_FROM_USER)
      return false
    })
