import { FC, useContext, useState } from 'react'

import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { inviteUserToOrganization } from 'services/Organizations'
import { isValidEmail } from 'services/Validation'

const InviteUserModal: FC<{
  isOpen: boolean
  onConfirm: (result?: boolean) => void
  organizationId: string
}> = ({ isOpen, onConfirm, organizationId }) => {
  const { handleError } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { t } = useTranslation(['organization'])

  // const toast = useToast()

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onInviteUser = async () => {
    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      onConfirm(false)
      setIsLoading(false)
      return false
    }

    const invitation = await inviteUserToOrganization(token, organizationId, emailAddress, handleError)
    if (!invitation) {
      onConfirm(false)
      setIsLoading(false)
      return false
    }

    // TODO: Send email notification later
    // const sendEmailNotificationResult = await sendInvitationEmail(token, emailAddress, handleError)
    // if (sendEmailNotificationResult) {
    //   toast({
    //     ...TOAST_CONFIG,
    //     title: t('invite_user_modal.send_invitation_email_success'),
    //   })
    // }

    onConfirm(true)
    setIsLoading(false)

    // track with mixpanel
    mixpanel.track('Invite user to organization', {
      'Email of target user': emailAddress,
      'Organization ID': organizationId,
    })

    return true
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => onConfirm()} trapFocus={false} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{t('invite_user_modal.modal_header')}</ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody position="relative">
          <FormControl>
            <Input
              placeholder={t('invite_user_modal.input_placeholder')}
              id="email_address"
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value.trim())}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={() => onConfirm()} disabled={isLoading}>
            {t('invite_user_modal.cancel')}
          </Button>
          <Button
            disabled={isLoading || !isValidEmail(emailAddress)}
            colorScheme="primary"
            me={3}
            py={2}
            minW="100px"
            onClick={() => onInviteUser()}
            isLoading={isLoading}
            loadingText={t('invite_user_modal.inviting')}
            spinnerPlacement="end"
          >
            {t('invite_user_modal.invite')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InviteUserModal
