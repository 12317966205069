import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { setUser as setSentryUser } from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { Trans, useTranslation } from 'react-i18next'

import { API_PROCESS_MAP, MODAL_TYPES } from 'config/constants'

import { ErrorResponse, Organization, TextsError, UserContextProps } from 'interfaces/interfaces'

import { extractStatusId, generateErrorMessage } from 'services/ErrorHandler'
import { getUser } from 'services/Users'

import { GlobalModalContext } from './GlobalModal'

export const UserContext = createContext<UserContextProps>({
  userLoaded: false,
  userType: '',
  userTypeForOrganizations: {},
  subscriptionId: '',
  organizations: [],
  refreshAccessToken: () => null,
  getAccessToken: async () => Promise.resolve(''),
  fetchUserInfo: async () => Promise.resolve(null),
} as UserContextProps)

export function useUserContext(): UserContextProps {
  const { showErrorModal, showModal, handleError } = useContext(GlobalModalContext)
  const { error, logout } = useAuth0()
  const { i18n, t } = useTranslation(['error_message'])

  const handleUserNotFound = () => {
    showModal({
      body: <Trans i18nKey="modal_user_not_found.text" ns="error_message" />,
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      unClosable: true,
      size: '2xl',
      confirmText: t('modal_user_not_found.confirm', { ns: 'error_message' }),
      onConfirm: () => {
        logout({ returnTo: window.location.origin })
        return false
      },
    })
  }

  useEffect(() => {
    if (error) {
      handleUserNotFound()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  //* 初期ロード管理
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userType, setUserType] = useState('')
  const [userTypeForOrganizations, setUserTypeForOrganizations] = useState<{ [key: string]: string }>({})
  const [subscriptionId, setSubscriptionId] = useState('')
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const { user, getAccessTokenSilently } = useAuth0()

  const refreshAccessToken = useCallback(async () => {
    await getAccessTokenSilently()
  }, [getAccessTokenSilently])

  const getAccessToken = useCallback(
    async (): Promise<string> =>
      getAccessTokenSilently().catch((err) => {
        handleError(err, API_PROCESS_MAP.GET_ACCESS_TOKEN)
        return ''
      }),
    [getAccessTokenSilently, handleError]
  )

  //* 初回ロード
  useEffect(() => {
    if (user && !userLoggedIn) {
      refreshAccessToken()
        .then(() => {
          setUserLoggedIn(true)
        })
        .catch((err) => {
          handleError(err, API_PROCESS_MAP.GET_ACCESS_TOKEN)
        })
    }
  }, [userLoggedIn, refreshAccessToken, user, handleError])

  //* Check user information
  const fetchUserInfo = useCallback(async () => {
    if (userLoggedIn && user?.sub) {
      const access_token = await getAccessTokenSilently().catch((err) => {
        handleError(err, API_PROCESS_MAP.GET_ACCESS_TOKEN)
        return ''
      })

      if (!access_token) {
        return false
      }

      const userInfo = await getUser(
        access_token,
        user.sub,
        // override error handling function
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err: any, processName: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const statusId = extractStatusId(err.response.data as ErrorResponse)
          if (statusId === 40401) {
            // user not found
            handleUserNotFound()
          } else {
            const textMap: TextsError = t('error_apis', {
              returnObjects: true,
              processName,
              ns: 'error_message',
            })
            const errorMessage = generateErrorMessage(err, processName, textMap)
            showErrorModal(errorMessage, true)
          }
        }
      )
      if (!userInfo?.user_id) {
        return false
      }

      setUserType(userInfo.user_type)
      setUserTypeForOrganizations((userInfo.organization_ids as { [key: string]: string }) || {})
      setSubscriptionId(userInfo.subscription_id || '')
      setOrganizations(Object.values(userInfo.organization_details || []))
      await i18n.changeLanguage(userInfo.language_preference || i18n.resolvedLanguage)
      setUserLoaded(true)

      // register user for mixpanel
      mixpanel.identify(userInfo.user_id)
      mixpanel.people.set({ 'User type': userInfo.user_type, $name: user.email, $email: user.email })

      // register user for Sentry
      setSentryUser({ id: userInfo.user_id, email: user.email, 'Email Domain': user.email?.split('@')[1] })

      return true
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, i18n, handleError, showErrorModal, user, userLoggedIn])

  useEffect(() => {
    void fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn, user])

  return {
    userLoaded,
    userType,
    userTypeForOrganizations,
    subscriptionId,
    organizations,
    refreshAccessToken,
    getAccessToken,
    fetchUserInfo,
  }
}
