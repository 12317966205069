import { createContext, useContext } from 'react'

import { GlobalModal } from 'interfaces/interfaces'

const initialState: GlobalModal = {
  showModal: () => null,
  handleError: () => null,
  showErrorModal: () => null,
  updateModal: () => null,
  hideModal: () => null,
}

export const GlobalModalContext = createContext(initialState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)
