import { FC, useContext, useEffect, useRef, useState } from 'react'

import {
  Box,
  HStack,
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { toPng } from 'html-to-image'
import mixpanel from 'mixpanel-browser'
import ContentEditable from 'react-contenteditable'
import { isTablet } from 'react-device-detect'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'

import { CameraIcon, CheckCircleIcon, InputEditorCancelIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { InspectionSheet } from 'interfaces/inspection'
import { Timeout } from 'interfaces/interfaces'

import { updateProjectBlackboardMemo } from 'services/Projects'

const Blackboard: FC<{ inspectionSheet?: InspectionSheet; onClose: () => void }> = ({ inspectionSheet, onClose }) => {
  const { t } = useTranslation(['projects'])

  const { getAccessToken } = useContext(UserContext)
  const { handleError } = useContext(GlobalModalContext)
  const { canvasRenderers, project } = useContext(EditorContext)
  const [coverImage, setCoverImage] = useState<HTMLImageElement>()
  const [canvasImage, setCanvasImage] = useState<HTMLImageElement>()
  const [labelsImage, setLabelsImage] = useState<HTMLImageElement>()

  const [saveTimeout, setSaveTimeout] = useState<Timeout>()
  const [loading, setLoading] = useState<boolean>()

  const ref = useRef<HTMLDivElement>(null)
  const textRef = useRef(project?.blackboard_memo || '')
  const buttonSize = isTablet ? 12 : 8

  const onUpdate = async () => {
    if (!project?.project_id) {
      return
    }

    setLoading(true)

    const token = await getAccessToken()

    if (!token) {
      return
    }

    await updateProjectBlackboardMemo(token, project.project_id, textRef.current, handleError)

    // track with mixpanel
    mixpanel.track('Update blackboard memo', {
      'Inspection area ID': project.project_id,
      'Memo text (old)': project?.blackboard_memo,
      'Memo text (new)': textRef.current,
    })

    setLoading(false)
  }

  const buildPng = async (node: HTMLCanvasElement) => {
    let dataUrl = ''
    const minDataLength = 100000
    let i = 0
    const maxAttempts = 10
    dataUrl = await toPng(node, { pixelRatio: isTablet ? undefined : 1 })
    while (dataUrl.length < minDataLength && i < maxAttempts) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(() => resolve(null), 300)
      })
      // eslint-disable-next-line no-await-in-loop
      dataUrl = await toPng(node, { pixelRatio: isTablet ? undefined : 1 })
      i += 1
    }

    return dataUrl
  }

  useEffect(() => {
    if (saveTimeout) {
      clearTimeout(saveTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    void (async () => {
      if (coverImage && canvasImage && labelsImage && ref.current) {
        const canvas = document.createElement('canvas')
        const bodyRect = document.body.getBoundingClientRect()
        canvas.width = bodyRect.width
        canvas.height = bodyRect.height

        const imageRect = ref.current.getBoundingClientRect()
        const ctx = canvas.getContext('2d')

        ctx?.drawImage(canvasImage, 0, 0, bodyRect.width, bodyRect.height)
        ctx?.drawImage(labelsImage, 0, 0, bodyRect.width, bodyRect.height)
        ctx?.drawImage(coverImage, imageRect.left, imageRect.top, imageRect.width, imageRect.height)
        document.body.appendChild(canvas)

        await buildPng(canvas).then((dataUrl) => {
          const link = document.createElement('a')
          link.download = `${project?.project_name || '電子小黒板'}.png`
          link.href = dataUrl
          link.click()

          setCoverImage(undefined)
          setCanvasImage(undefined)
          setLabelsImage(undefined)
          canvas.remove()
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, coverImage, canvasImage, labelsImage])

  const captureScreenshot = async () => {
    const canvasBox = document.getElementsByClassName('editor-main-canvas').item(0) as HTMLElement
    if (!ref.current || !canvasBox) {
      return
    }

    const image = document.createElement('img')
    image.onload = () => setCanvasImage(image)
    if (canvasRenderers) {
      canvasRenderers.gl.render(canvasRenderers.scene, canvasRenderers.camera)
      image.src = canvasRenderers.gl.domElement.toDataURL() || ''
    }

    const unprintableNodes = Array.from(canvasBox.childNodes).filter((node) =>
      (node as HTMLElement).querySelector('.unprintable')
    ) as HTMLElement[]
    // Temporary hide unprintable nodes
    for (let i = 0; i < unprintableNodes.length; i += 1) {
      unprintableNodes[i].style.opacity = '0'
    }
    await toPng(canvasBox).then((dataUrl) => {
      const labelsImg = document.createElement('img')
      labelsImg.onload = () => setLabelsImage(labelsImg)
      labelsImg.src = dataUrl
      labelsImg.width = canvasBox.clientWidth
      labelsImg.height = canvasBox.clientHeight
    })
    // Show unprintable nodes
    for (let i = 0; i < unprintableNodes.length; i += 1) {
      unprintableNodes[i].style.opacity = '1'
    }

    await toPng(ref.current).then((dataUrl) => {
      const coverImg = document.createElement('img')
      coverImg.onload = () => setCoverImage(coverImg)
      coverImg.src = dataUrl
      coverImg.width = ref.current?.clientWidth || 0
      coverImg.height = ref.current?.clientHeight || 0
    })

    // track with mixpanel
    mixpanel.track('Take screenshot of blackboard', {
      'Inspection area ID': project?.project_id,
    })
  }

  if (!inspectionSheet) {
    return null
  }

  return (
    <Box left={1} bottom={isTablet ? 14 : 10} position="fixed" w={0}>
      <Draggable cancel=".need-interaction">
        <Box>
          <Box
            p={1}
            w={420}
            backgroundColor="#025c39"
            color="white"
            textAlign="center"
            fontWeight="bold"
            cursor="grab"
            ref={ref}
          >
            <TableContainer position="relative" overflow="hidden">
              <Table variant="bordered" cellPadding={0}>
                <Thead>
                  <Tr>
                    <Th width="1%" padding={0} />
                    <Th padding={0} />
                    <Th width="1%" padding={0} />
                    <Th padding={0} />
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontSize="90%">
                      {t('main_canvas.inspection_sheet.sheet_property.construction_project_name', { ns: 'projects' })}
                    </Td>
                    <Td>{inspectionSheet.construction_properties.construction_project_name}</Td>
                    <Td fontSize="90%">
                      {t('main_canvas.inspection_sheet.sheet_property.inspection_area_name', { ns: 'projects' })}
                    </Td>
                    <Td>{project?.project_name || ''}</Td>
                  </Tr>
                  <Tr>
                    <Td fontSize="90%">
                      {t('main_canvas.inspection_sheet.sheet_property.construction_type_name', { ns: 'projects' })}
                    </Td>
                    <Td>{inspectionSheet.construction_properties.construction_type}</Td>
                    <Td fontSize="90%">
                      {t('main_canvas.inspection_sheet.sheet_property.creator', { ns: 'projects' })}
                    </Td>
                    <Td>{inspectionSheet.creator_name}</Td>
                  </Tr>
                  <Tr>
                    <Td fontSize="90%">
                      <HStack justify="space-between">
                        <Text>
                          {t('main_canvas.inspection_sheet.sheet_property.construction_detail', { ns: 'projects' })}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{inspectionSheet.construction_properties.construction_type_detailed}</Td>
                    <Td fontSize="90%">
                      {t('main_canvas.inspection_sheet.sheet_property.creation_day', { ns: 'projects' })}
                    </Td>
                    <Td>
                      {inspectionSheet.create_time_user_specified
                        ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
                        : ''}
                    </Td>
                  </Tr>
                  <Tr height={160}>
                    <Td fontSize="250%" colSpan={4}>
                      <ContentEditable
                        html={textRef.current.replace(/(<([^>]+)>)/gi, '')}
                        onChange={(e) => {
                          textRef.current = e.target.value

                          if (saveTimeout) {
                            clearTimeout(saveTimeout)
                          }
                          const handler = setTimeout(() => {
                            void onUpdate()
                          }, 1000)
                          setSaveTimeout(handler)
                        }}
                        className="need-interaction"
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-all',
                          textAlign: 'center',
                          lineHeight: 1.28,
                          fontWeight: 'normal',
                          cursor: 'text',
                        }}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Box
                position="absolute"
                pointerEvents="none"
                top={0}
                left={0}
                right={0}
                bottom={0}
                border="1px solid white"
              />
              <Box position="absolute" right={2} bottom={2}>
                {loading === false && <CheckCircleIcon size={21} />}
                {!!loading && <Spinner speed="0.65s" color="white" size="sm" mb="-2px" mr="2px" />}
              </Box>
            </TableContainer>
          </Box>
          <IconButton
            aria-label="close-blackboard"
            icon={<InputEditorCancelIcon size={isTablet ? 30 : 18} />}
            onClick={() => {
              setLoading(undefined)
              onClose()
            }}
            position="absolute"
            top={0}
            left={424}
            width={buttonSize}
            maxWidth={buttonSize}
            minWidth={buttonSize}
            height={buttonSize}
            padding={0}
            colorScheme="secondary"
            variant="toolbar"
            className="need-interaction"
          />
          <IconButton
            aria-label="photo-blackboard"
            icon={<CameraIcon size={isTablet ? 24 : 16} />}
            onClick={() => captureScreenshot()}
            position="absolute"
            bottom={0}
            left={424}
            width={buttonSize}
            maxWidth={buttonSize}
            minWidth={buttonSize}
            height={buttonSize}
            padding={0}
            colorScheme="secondary"
            variant="toolbar"
            className="need-interaction"
          />
        </Box>
      </Draggable>
    </Box>
  )
}

export default Blackboard
