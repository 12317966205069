import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { evaluateSpacer } from 'services/Spacers'

import { spacerGridFormationFinished } from '../store/editor'
import { spacerGridFormed } from '../store/spacerAnnotation'

const ConvertSpacerAnnotationButton: FC = () => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { getAccessToken } = useContext(UserContext)
  const { project } = useContext(EditorContext)
  const { handleError } = useContext(GlobalModalContext)

  const { isToolProcessing } = useSelector((state: RootState) => state.editor)

  const [isLoading, setIsLoading] = useState(false)

  const { spacerAnnotationAnchors } = useSelector((state: RootState) => state.spacerAnnotation)

  if (!project || !spacerAnnotationAnchors?.filter((a) => !a.deleted).length) {
    return null
  }

  const convertSpacerAnnotation = async () => {
    if (!spacerAnnotationAnchors) {
      return false
    }

    const filteredAnchors = spacerAnnotationAnchors.filter((a) => !a.deleted)
    if (!filteredAnchors.length) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const result = await evaluateSpacer(token, filteredAnchors[0].points, handleError)

    if (!result) {
      setIsLoading(false)
      return false
    }

    // track with mixpanel
    mixpanel.track('Finish forming spacer unit cell', {
      'Inspection area ID': project?.project_id,
      'Shape type': result.shape_type || '',
    })

    setIsLoading(false)

    // add detected shape to spacers list
    // TODO: can we rewrite as a single action to update the states in 2 slices at once?
    dispatch(spacerGridFormed([result]))
    dispatch(spacerGridFormationFinished())

    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={convertSpacerAnnotation}
      isLoading={isLoading}
      disabled={isToolProcessing || isLoading}
      spinnerPlacement="end"
      loadingText={t('main_canvas.action_buttons.detecting', { ns: 'projects' })}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.detect', { ns: 'projects' })}
    </Button>
  )
}

export default ConvertSpacerAnnotationButton
