import { configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/react'
import editorReducer from 'pages/projects/editor/store/editor'
import spacerAnnotationReducer from 'pages/projects/editor/store/spacerAnnotation'
import commentReducer from 'pages/projects/editor/store/temporalComment'
import { useDispatch } from 'react-redux'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = createReduxEnhancer()

export const storeConfig = {
  reducer: {
    editor: editorReducer,
    temporal_comment: commentReducer,
    spacerAnnotation: spacerAnnotationReducer,
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  enhancers: (getDefaultEnhancers: any) => getDefaultEnhancers().concat(sentryReduxEnhancer),
}

export const store = configureStore(storeConfig)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
