import { FC, useCallback, useMemo, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { MODAL_TYPES } from 'config/constants'

import { ModalProps, TextsError } from 'interfaces/interfaces'

import { generateErrorMessage } from 'services/ErrorHandler'

const GlobalModal: FC = ({ children }) => {
  const { t } = useTranslation(['global_modal', 'error_message'])

  const [isOpen, setIsOpen] = useState(false)
  const [props, setProps] = useState<ModalProps>({})

  const showModal = useCallback(
    (modalProps: ModalProps) => {
      setIsOpen(true)
      setProps({
        ...(modalProps.modalType === MODAL_TYPES.ERROR
          ? { confirmText: t('ok'), title: t('title_error') }
          : { cancelText: t('cancel'), confirmText: t('confirm'), title: t('title_default') }),
        ...modalProps,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const showErrorModal = useCallback(
    (message: string, unClosable?: boolean) => {
      setIsOpen(true)
      setProps({
        confirmText: t('ok'),
        title: t('title_error'),
        body: message,
        unClosable,
        modalType: MODAL_TYPES.ERROR,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleError = useCallback(
    (err: unknown, processName: string) => {
      const textMap: TextsError = t('error_apis', {
        returnObjects: true,
        processName,
        ns: 'error_message',
      })
      const errorMessage = generateErrorMessage(err, processName, textMap)
      showErrorModal(errorMessage)
    },
    [t, showErrorModal]
  )

  const updateModal = useCallback(
    (modalProps: ModalProps) => {
      setProps({
        ...props,
        ...modalProps,
      })
    },
    [props]
  )

  const hideModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const contextValue = useMemo(
    () => ({ showModal, showErrorModal, handleError, updateModal, hideModal }),
    [showModal, showErrorModal, handleError, updateModal, hideModal]
  )

  const handleModalToggle = () => {
    hideModal()
  }

  const handleModalConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm()
    }
    hideModal()
  }

  const handleModalCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
    hideModal()
  }

  const handleModalResolve = () => {
    if (props.onResolve) {
      props.onResolve()
    }
    hideModal()
  }

  const getColorScheme = (modalType: string | undefined) => {
    switch (modalType) {
      case MODAL_TYPES.CONFIRMATION_CRITICAL:
        return 'red'
      default:
        return 'primary'
    }
  }

  const renderComponent = () => {
    if (!isOpen) {
      return null
    }

    return (
      <Modal
        closeOnEsc={!props.unClosable}
        closeOnOverlayClick={!props.unClosable}
        id="globalModal"
        isOpen
        onClose={handleModalToggle}
        size={props.size || 'sm'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          {!props.unClosable && <ModalCloseButton />}
          <ModalBody whiteSpace="pre-wrap">{props.body}</ModalBody>
          <ModalFooter>
            {props.modalType !== MODAL_TYPES.ALERT && props.modalType !== MODAL_TYPES.ERROR && !props.unClosable && (
              <Button mr={3} onClick={handleModalCancel}>
                {props.cancelText}
              </Button>
            )}
            {props.resolveText && props.onResolve && (
              <Button mr={3} onClick={handleModalResolve}>
                {props.resolveText}
              </Button>
            )}
            {(props.modalType !== MODAL_TYPES.ERROR || !props.unClosable) && (
              <Button
                colorScheme={getColorScheme(props.modalType)}
                onClick={handleModalConfirm}
                disabled={props.unConfirmable}
              >
                {props.confirmText}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}

export default GlobalModal
