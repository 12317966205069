import { FC, useContext, useState } from 'react'

import { IconButton, Link, Menu, MenuButton, MenuItem, MenuList, Portal, Td, Text, Tr } from '@chakra-ui/react'
import bytes from 'bytes'
import dayjs from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { BigChevronDownIcon, BigChevronUpIcon, HDotsIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Project, ProjectGroup } from 'interfaces/interfaces'

import { deleteProjectGroup } from 'services/ProjectGroups'

import ProjectRow from './ProjectRow'

const ProjectGroupRow: FC<{
  projectGroup: ProjectGroup
  isAllowedModify: boolean
  setInvitingProjectGroup: (projectGroup: ProjectGroup) => void
  setEditingNameProjectGroup: (projectGroup: ProjectGroup) => void
  setEditingNameProject: (project: Project) => void
  setUpdatingFileProject: (project: Project) => void
  onCreateProject: () => void
}> = ({
  projectGroup,
  isAllowedModify,
  setInvitingProjectGroup,
  setEditingNameProjectGroup,
  setEditingNameProject,
  setUpdatingFileProject,
  onCreateProject,
}) => {
  const { t } = useTranslation(['dashboard'])
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { getProjectGroups } = useContext(ProjectsContext)
  const { getAccessToken } = useContext(UserContext)

  const [isExpanded, setIsExpanded] = useState(false)

  const confirmProjectGroupDelete = () => {
    showModal({
      body: (
        <>
          <Text>
            {t('components.project_group_row.delete_side_effect_warning', {
              ns: 'dashboard',
            })}
            <br />
            {t('components.project_group_row.delete_warning', {
              ns: 'dashboard',
            })}
          </Text>
          <Text mt="1" fontWeight="semibold">
            {projectGroup.project_group_name}
          </Text>
        </>
      ),
      confirmText: t('components.project_group_row.delete', {
        ns: 'dashboard',
      }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const deleteProjectApi = async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const deleteResult = await deleteProjectGroup(token, projectGroup.project_group_id, handleError)
          if (!deleteResult) {
            return false
          }

          await getProjectGroups()
          return true
        }

        void deleteProjectApi()

        // track with mixpanel
        mixpanel.track('Delete project', { 'Project ID': projectGroup.project_group_id })

        return true
      },
      title: t('components.project_group_row.confirm_delete_project', {
        ns: 'dashboard',
      }),
    })
  }

  return (
    <>
      <Tr key={projectGroup.project_group_id}>
        <Td borderBottomWidth={isExpanded ? 0 : 1}>
          <IconButton
            aria-label={`expand.${projectGroup.project_group_name}`}
            icon={isExpanded ? <BigChevronUpIcon /> : <BigChevronDownIcon />}
            bg="none"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          />
        </Td>
        <Td wordBreak="break-word" whiteSpace="normal" borderBottomWidth={isExpanded ? 0 : 1}>
          <Text
            cursor="pointer"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {projectGroup.project_group_name}
          </Text>
        </Td>
        <Td textAlign="right" borderBottomWidth={isExpanded ? 0 : 1}>
          {bytes(projectGroup.total_size, {
            decimalPlaces: 1,
            unit: 'MB',
          })}
        </Td>
        <Td borderBottomWidth={isExpanded ? 0 : 1}>
          {projectGroup.shared_user && !!Object.keys(projectGroup.shared_user).length && (
            <Link as={RouterLink} to={`/project-groups/${projectGroup.project_group_id}/users`} variant="underline">
              {t('components.project_group_row.list_of_invitees', {
                ns: 'dashboard',
              })}
              ({projectGroup.shared_user ? Object.keys(projectGroup.shared_user).length : 0})
            </Link>
          )}
          {(!projectGroup.shared_user || !Object.keys(projectGroup.shared_user).length) && (
            <Text color="secondary.400">
              {' '}
              {t('components.project_group_row.no_invitees', {
                ns: 'dashboard',
              })}
            </Text>
          )}
        </Td>
        <Td textAlign="right" borderBottomWidth={isExpanded ? 0 : 1}>
          {dayjs(projectGroup.updated_at).format('YYYY/MM/DD')}
        </Td>
        <Td textAlign="right" borderBottomWidth={isExpanded ? 0 : 1}>
          {dayjs(projectGroup.created_at).format('YYYY/MM/DD')}
        </Td>
        <Td borderBottomWidth={isExpanded ? 0 : 1}>{projectGroup.creator_email_address}</Td>
        <Td borderBottomWidth={isExpanded ? 0 : 1}>
          <Menu autoSelect={false}>
            <MenuButton
              variant="ghost"
              as={IconButton}
              aria-label={`actions.${projectGroup.project_group_name}`}
              fontSize="lg"
              icon={<HDotsIcon />}
            />
            <Portal>
              <MenuList aria-label={`${isAllowedModify ? 'owner' : 'invited'}-project-group-menu`}>
                {isAllowedModify && (
                  <MenuItem
                    aria-label={`invite.${projectGroup.project_group_name}`}
                    onClick={() => setInvitingProjectGroup(projectGroup)}
                  >
                    {t('components.project_group_row.invite_user', {
                      ns: 'dashboard',
                    })}
                  </MenuItem>
                )}
                <MenuItem
                  aria-label={`ifc-files.${projectGroup.project_group_name}`}
                  as={RouterLink}
                  to={`/project-groups/${projectGroup.project_group_id}/ifc-files`}
                >
                  {t('components.project_group_row.list_of_ifc_files', {
                    ns: 'dashboard',
                  })}
                </MenuItem>
                {isAllowedModify && (
                  <>
                    <MenuItem
                      aria-label={`change-name.${projectGroup.project_group_name}`}
                      onClick={() => setEditingNameProjectGroup(projectGroup)}
                    >
                      {t('components.project_group_row.change_name', {
                        ns: 'dashboard',
                      })}
                    </MenuItem>
                    <MenuItem
                      aria-label={`delete.${projectGroup.project_group_name}`}
                      onClick={() => confirmProjectGroupDelete()}
                    >
                      {t('components.project_group_row.delete', {
                        ns: 'dashboard',
                      })}
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Portal>
          </Menu>
        </Td>
      </Tr>
      <ProjectRow
        projectGroup={projectGroup}
        isExpanded={isExpanded}
        isAllowedModify={isAllowedModify}
        setEditingNameProject={setEditingNameProject}
        setUpdatingFileProject={setUpdatingFileProject}
        onCreateProject={onCreateProject}
      />
    </>
  )
}

export default ProjectGroupRow
