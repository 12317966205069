import { FC, useContext } from 'react'

import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import CylindersOnAxisImage from 'assets/imgs/cylinders_on_axis.png'
import SpacersImage from 'assets/imgs/spacers.png'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'

export const INSPECTION_TYPE = {
  REBARS: 'rebars',
  SPACERS: 'spacers',
}
const InspectionTypeModal: FC<{
  isOpen: boolean
  onConfirm: (type?: string) => void
}> = ({ isOpen, onConfirm }) => {
  const { t } = useTranslation(['projects'])
  const { selectedShapeIds, shapes } = useContext(EditorContext)
  const { spacerAnnotations } = useSelector((state: RootState) => state.spacerAnnotation)

  const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
  const cylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const tori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const spacers = spacerAnnotations.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))

  const renderTypeButton = (type: string, imageSrc: string, label: string) => (
    <Box position="relative" key={type}>
      <Button
        onClick={() => onConfirm(type)}
        variant="outlinePrimary"
        color="secondary"
        borderRadius={30}
        height={180}
        disabled={type === INSPECTION_TYPE.SPACERS && spacers.length > 1}
      >
        <VStack>
          <Image src={imageSrc} height={130} />
          <Text>{label}</Text>
        </VStack>
      </Button>
      {type === INSPECTION_TYPE.REBARS &&
        ((!!cylinders.length && cylinders.some((cylinder) => cylinder.diameter !== cylinders[0].diameter)) ||
          (!!tori.length && tori.some((torus) => torus.minor_diameter !== tori[0].minor_diameter))) && (
          <Center position="absolute" w="100%" h="100%" top={0} left={0} p={5} backgroundColor="whiteAlpha.700">
            <Text fontWeight="bold" textAlign="center">
              {t('main_canvas.inspection_sheet.error_modal.message_different_diameters', { ns: 'projects' })}
            </Text>
          </Center>
        )}
      {type === INSPECTION_TYPE.SPACERS && spacers.length > 1 && (
        <Center position="absolute" w="100%" h="100%" top={0} left={0} p={5} backgroundColor="whiteAlpha.700">
          <Text fontWeight="bold" textAlign="center">
            {t('main_canvas.inspection_sheet.error_modal.message_multiple_cells', { ns: 'projects' })}
          </Text>
        </Center>
      )}
    </Box>
  )

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => onConfirm()} trapFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody position="relative">
          <VStack height="100%" spacing={6} paddingTop={6}>
            <Text fontWeight="bold" textAlign="center">
              {t('main_canvas.inspection_sheet.error_modal.message_mixed_selection', { ns: 'projects' })}
            </Text>
            {renderTypeButton(
              INSPECTION_TYPE.REBARS,
              CylindersOnAxisImage,
              t('main_canvas.inspection_sheet.error_modal.rebar', { ns: 'projects' })
            )}
            {renderTypeButton(
              INSPECTION_TYPE.SPACERS,
              SpacersImage,
              t('main_canvas.inspection_sheet.error_modal.spacer_grid', { ns: 'projects' })
            )}
          </VStack>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('main_canvas.inspection_sheet.error_modal.cancel', { ns: 'projects' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InspectionTypeModal
