import { FC, useCallback, useContext, useEffect, useState } from 'react'

import InstructionBarContent from 'pages/projects/components/InstructionBarContent'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_REQUIRED_ANCHORS, EDITOR_TOOLS } from 'config/constants'

import { ActionInstruction, AnchorPoints, Instruction, TextsInstruction } from 'interfaces/interfaces'

const InstructionBar: FC = () => {
  const { t } = useTranslation(['projects'])
  const isJobRunning = useSelector((state: RootState) => state.editor.isJobRunning)
  const { spacerAnnotationAnchors } = useSelector((state: RootState) => state.spacerAnnotation)
  const { selectedTool, anchors, distanceAnchors, cuboid } = useContext(EditorContext)
  const [instruction, setInstruction] = useState<Instruction>()

  const handleCuboidCreation = useCallback(
    (intro: ActionInstruction) => {
      // before creation
      if (!cuboid) {
        setInstruction(intro.before_action)
        // after creation
      } else {
        setInstruction(intro.after_action)
      }
    },
    [cuboid]
  )
  const handleFrameSelection = useCallback(
    (frames: AnchorPoints[], intro: ActionInstruction, requiredAnchors: number) => {
      const filteredFrames = frames.filter((anchorPoints) => !anchorPoints.deleted)
      // before selection
      // if there is no frame yet, or there is only a frame that does not have all the selected points yet
      if (
        !filteredFrames.length ||
        (filteredFrames.length === 1 && filteredFrames[0].points.length < requiredAnchors)
      ) {
        setInstruction(intro.before_action)
        // after selection
      } else {
        setInstruction(intro.after_action)
      }
    },
    []
  )

  useEffect(() => {
    const texts: TextsInstruction = t('main_canvas.instructions.tools', {
      returnObjects: true,
    })

    switch (selectedTool) {
      case EDITOR_TOOLS.MOVE:
        // only show instructions when there is some points to move
        if (
          !isJobRunning &&
          (anchors.cylinders.filter((cylinder) => !cylinder.deleted).length ||
            anchors.planes.filter((plane) => !plane.deleted).length ||
            anchors.tori.filter((torus) => !torus.deleted).length ||
            distanceAnchors.filter((distanceAnchor) => !distanceAnchor.deleted).length)
        ) {
          setInstruction(texts.move_point)
        } else {
          setInstruction(undefined)
        }
        break
      case EDITOR_TOOLS.COMMENT:
        setInstruction(texts.comment_point)
        break
      case EDITOR_TOOLS.COMMENT_CUBOID:
        handleCuboidCreation(texts.comment_cuboid)
        break
      case EDITOR_TOOLS.CYLINDER:
        handleFrameSelection(anchors.cylinders, texts.detect_cylinder_points, EDITOR_REQUIRED_ANCHORS.cylinders)
        break
      case EDITOR_TOOLS.CYLINDER_CUBOID:
        handleCuboidCreation(texts.detect_cylinder_cuboid)
        break
      case EDITOR_TOOLS.DISTANCE:
        handleFrameSelection(distanceAnchors, texts.measure_distance, EDITOR_REQUIRED_ANCHORS.distance)
        break
      case EDITOR_TOOLS.PLANE:
        handleFrameSelection(anchors.planes, texts.detect_plane, EDITOR_REQUIRED_ANCHORS.planes)
        break
      case EDITOR_TOOLS.PLANE_VIRTUAL:
        handleFrameSelection(anchors.planes, texts.detect_plane_virtual, EDITOR_REQUIRED_ANCHORS.planes)
        break
      case EDITOR_TOOLS.TORUS:
        handleFrameSelection(anchors.tori, texts.detect_torus_points, EDITOR_REQUIRED_ANCHORS.tori)
        break
      case EDITOR_TOOLS.TORUS_CUBOID:
        handleCuboidCreation(texts.detect_torus_cuboid)
        break
      case EDITOR_TOOLS.PCD_TRIM_CUBOID:
        handleCuboidCreation(texts.trim_pcd)
        break
      case EDITOR_TOOLS.SPACER_ANNOTATION:
        handleFrameSelection(spacerAnnotationAnchors, texts.annotate_spacers, EDITOR_REQUIRED_ANCHORS.spacerAnnotation)
        break
      default:
        setInstruction(undefined)
    }
  }, [
    anchors,
    cuboid,
    distanceAnchors,
    handleCuboidCreation,
    handleFrameSelection,
    isJobRunning,
    selectedTool,
    spacerAnnotationAnchors,
    t,
  ])

  return <InstructionBarContent instruction={instruction} />
}

export default InstructionBar
