import { FC, useContext, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { SELECTED_ORGANIZATION_COOKIE_NAME } from 'config/constants'

import { createProjectGroup } from 'services/ProjectGroups'

const CreateProjectGroupModal: FC<{ isOpen: boolean; onConfirm: (result?: boolean) => void }> = ({
  isOpen,
  onConfirm,
}) => {
  const { t } = useTranslation(['dashboard'])
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal, handleError } = useContext(GlobalModalContext)
  const [cookie] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])

  const [projectGroupName, setProjectGroupName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onCreateProjectGroup = async () => {
    if (!projectGroupName) {
      showErrorModal(
        t('components.create_project_group_modal.project_name_is_not_provided', {
          ns: 'dashboard',
        })
      )
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const projectGroup = await createProjectGroup(
      token,
      cookie[SELECTED_ORGANIZATION_COOKIE_NAME] as string,
      projectGroupName,
      handleError
    )
    if (projectGroup) {
      setIsLoading(false)
      onConfirm(true)
      // track event to mixpanel
      mixpanel.track('Create Project', {
        'Project ID': projectGroup.project_group_id,
        'Project Name': projectGroup.project_group_name,
      })
      return true
    }

    setIsLoading(false)
    onConfirm()

    return false
  }

  return (
    <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={onConfirm} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('components.create_project_group_modal.create_project', {
            ns: 'dashboard',
          })}
        </ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="project_group_name">
              {t('components.create_project_group_modal.project_name', {
                ns: 'dashboard',
              })}
            </FormLabel>
            <Input
              id="project_group_name"
              type="text"
              value={projectGroupName}
              onChange={(e) => setProjectGroupName(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8}>
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('components.create_project_group_modal.cancel', {
              ns: 'dashboard',
            })}
          </Button>

          <Button
            colorScheme="primary"
            disabled={!projectGroupName || isLoading}
            isLoading={isLoading}
            loadingText={t('components.create_project_group_modal.creating', {
              ns: 'dashboard',
            })}
            minW="100px"
            onClick={onCreateProjectGroup}
            py={2}
          >
            {t('components.create_project_group_modal.create', {
              ns: 'dashboard',
            })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateProjectGroupModal
