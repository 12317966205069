import { AxiosProgressEvent } from 'axios'
import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'

/**
 * upload file to S3
 * @param {string} url URL to upload file
 * @param {string} content_type Content-Type
 * @param {File} file file to be uploaded
 * @param setUploadPercent function to set upload percentage
 * @param {function} handleError - Function to handle errors (open error modal).
 */
export const uploadFile = async (
  url: string,
  content_type: string,
  file: File,
  setUploadPercent: React.Dispatch<React.SetStateAction<number>> | null,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .put(url, file, {
      headers: {
        'Content-Type': content_type,
      },
      timeout: 1800000, // unit: ms; 30 minutes
      onUploadProgress(progressEvent: AxiosProgressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
        if (setUploadPercent) setUploadPercent(percentCompleted)
      },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPLOAD_FILE)
      return false
    })

  return result
}
