import { RefObject, useContext, useState } from 'react'

import { ThreeEvent } from '@react-three/fiber'
import dayjs from 'dayjs'
import { commentPointSelected } from 'pages/projects/editor/store/temporalComment'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Camera, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_MEASURE_KEYS, EDITOR_MOUSE_EVENT_DELAY, EDITOR_TOOLS } from 'config/constants'

import { Cylinder, Plane, SpacerAnnotation, Timeout, Torus } from 'interfaces/interfaces'

import { getSelectedPointOnMesh } from 'services/PointPicker'
import { getCameraDistance } from 'services/Points'

const useShape = (shape: Plane | Cylinder | Torus | SpacerAnnotation, cameraRef: RefObject<Camera> | null) => {
  const {
    addDistanceAnchor,
    changeHoveredShapeId,
    changeProcessingAnchor,
    changeSelectedShapeIds,
    updateAnchorPoint,
    updateDistanceAnchorPoint,
    hoveredShapeId,
    isDragging,
    isMouseDown,
    selectedShapeIds,
    selectedTool,
    hoveredPoint,
    shapesPreviewDistances,
    isAllActionsDisabled,
  } = useContext(EditorContext)

  const { isToolProcessing } = useSelector((state: RootState) => state.editor)
  const { isMovingComment } = useSelector((state: RootState) => state.temporal_comment)
  const dispatch = useAppDispatch()

  //* Smooth mouse movement
  const [mouseTimeout, setMouseTimeout] = useState<Timeout>()
  // User need to click down an up on the same mesh to make a selection.
  // Store the down status on this state object for the up status checking
  const [isPointerDown, setIsPointerDown] = useState(false)

  const onPointerOver = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging || isMovingComment || isMouseDown) {
      return
    }
    changeHoveredShapeId(shape.shape_id)
  }

  const onPointerMove = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    // Prevent selecting shape when moving camera
    setIsPointerDown(false)

    if (isMovingComment) {
      return
    }

    if (mouseTimeout) {
      clearTimeout(mouseTimeout)
    }

    const timeout = setTimeout(() => {
      if (isDragging || isToolProcessing) {
        const anchorPoint = getSelectedPointOnMesh(event.sourceEvent.target as HTMLElement, cameraRef?.current, event)
        if (!anchorPoint) return

        if (selectedTool === EDITOR_TOOLS.DISTANCE && isToolProcessing) {
          changeProcessingAnchor(anchorPoint)
        } else if (isDragging && hoveredPoint) {
          if (hoveredPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE) {
            updateDistanceAnchorPoint(hoveredPoint, anchorPoint)
          } else {
            updateAnchorPoint(hoveredPoint, anchorPoint)
          }
        }
      }
    }, EDITOR_MOUSE_EVENT_DELAY)

    setMouseTimeout(timeout)
  }

  const onPointerOut = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging || isMovingComment) {
      return
    }
    changeHoveredShapeId(shape.shape_id)
    changeHoveredShapeId('')
    setIsPointerDown(false)
  }

  const onPointerDown = () => {
    setIsPointerDown(true)
  }

  const onPointerUp = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()
    if (isPointerDown) {
      onClick(event)
    }
    setIsPointerDown(false)
  }

  const onClick = (event: ThreeEvent<PointerEvent>) => {
    if (isMovingComment || shapesPreviewDistances || isAllActionsDisabled) {
      return
    }

    if (selectedTool === EDITOR_TOOLS.DISTANCE) {
      const anchorPoint = getSelectedPointOnMesh(event.sourceEvent.target as HTMLElement, cameraRef?.current, event)
      if (!anchorPoint || !cameraRef) return

      addDistanceAnchor(
        anchorPoint,
        new Vector2(Math.floor(event.sourceEvent.clientX), Math.floor(event.sourceEvent.clientY)),
        getCameraDistance(new Vector3(...anchorPoint), cameraRef.current),
        dayjs().unix()
      )
    }

    if (selectedTool === EDITOR_TOOLS.COMMENT) {
      const anchorPoint = getSelectedPointOnMesh(event.sourceEvent.target as HTMLElement, cameraRef?.current, event)
      if (!anchorPoint || !cameraRef) return

      dispatch(commentPointSelected(anchorPoint))
    }

    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging) {
      return
    }
    const newShapeIds = [...selectedShapeIds]
    const idIndex = newShapeIds.indexOf(shape.shape_id)
    if (idIndex >= 0) {
      newShapeIds.splice(idIndex, 1)
    } else {
      newShapeIds.push(shape.shape_id)
    }

    changeSelectedShapeIds(newShapeIds)
  }

  return {
    onPointerOver,
    onPointerOut,
    onPointerMove,
    onPointerDown,
    onPointerUp,
    isFocus: shape.shape_id === hoveredShapeId,
  }
}

export default useShape
