import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { load } from 'js-yaml'
import { initReactI18next } from 'react-i18next'

import { EXPIRATION_TIME_I18N } from 'config/constants'

const backendOptions = {
  backends: [LocalStorageBackend, HttpApi],
  backendOptions: [
    {
      prefix: 'i18next_res_', // prefix for stored languages
      expirationTime: EXPIRATION_TIME_I18N,
    },
    {
      loadPath: '/locales/{{lng}}/{{ns}}.yaml',
      parse: (data: string) => load(data),
    },
  ],
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['navbar'],
    defaultNS: 'navbar',
    backend: backendOptions,
    load: 'languageOnly',
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch((e) => {
    throw e
  })

export default i18n
