import { FC, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Text, useToast } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { AddIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { PRICING_PLANS, SELECTED_ORGANIZATION_COOKIE_NAME, SUBSCRIPTION_ALERT_REMAINING_DAYS } from 'config/constants'
import { CONTAINER_MAX_WIDTH, TOAST_CONFIG } from 'config/styles'

import { Subscription } from 'interfaces/interfaces'

import { getUserSubscription } from 'services/Usage'
import { getRemainingDays } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import ProjectsTable from './ProjectsTable'
import CreateProjectGroupModal from './components/CreateProjectGroupModal'

const remainingDays = getRemainingDays()

const Dashboard: FC = () => {
  const { t } = useTranslation(['dashboard'])
  const toast = useToast()
  const { search } = useLocation()
  const navigate = useNavigate()

  const parameters = new URLSearchParams(search)
  const user_email = parameters.get('user_email')

  const { user } = useAuth0()
  const [cookies] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])
  const { getProjectGroups } = useContext(ProjectsContext)
  const { userType, userTypeForOrganizations, subscriptionId, getAccessToken } = useContext(UserContext)
  const { handleError } = useContext(GlobalModalContext)

  // userTypeForOrganizations should be used if the user belongs to any organization.
  // otherwise, use userType.
  let userTypeForPermission = userType
  if (cookies) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const organizationId = cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userTypeForPermission = userTypeForOrganizations[organizationId] || userType
  }
  const permissions = decideActionPermission(userTypeForPermission).ACTIONS_WITHOUT_PROJECT
  const isAllowedCreateProjectGroup = permissions.USE_PREMIUM_FEATURES
  const isAllowedActivatePremiumFeatures = permissions.ACTIVATE_PREMIUM_FEATURES
  const isAllowedShowUserEmail = permissions.SHOW_USER_EMAIL

  const [isOpeningCreateProjectGroupModal, setIsOpeningCreateProjectGroupModal] = useState(false)
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(null)

  useEffect(() => {
    void (async () => {
      if (!user?.sub || !subscriptionId) {
        return
      }

      const token = await getAccessToken()
      if (!token) {
        return
      }

      const subscription = await getUserSubscription(token, user.sub, subscriptionId, handleError)
      setUserSubscription(subscription)
    })()
  }, [user, subscriptionId, getAccessToken, handleError])

  const openCreateProjectGroupModal = () => {
    setIsOpeningCreateProjectGroupModal(true)
    return true
  }

  const onProjectGroupCreated = async (result?: boolean) => {
    setIsOpeningCreateProjectGroupModal(false)

    if (result) {
      toast({
        ...TOAST_CONFIG,
        title: t('project_created', {
          ns: 'dashboard',
        }),
      })
      await getProjectGroups()
    }
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>
          {t('project', {
            ns: 'dashboard',
          })}
          {isAllowedShowUserEmail && user_email ? `(${user_email || ''})` : ''}
        </PageHeading>
        {(() => {
          if (!userTypeForPermission) {
            return null
          }

          // if it is permitted to create a project group, show the button
          if (isAllowedCreateProjectGroup) {
            return (
              <Box mb={7}>
                <Button
                  variant="outlinePrimary"
                  size="md"
                  onClick={openCreateProjectGroupModal}
                  rightIcon={<AddIcon />}
                >
                  {t('create_project', {
                    ns: 'dashboard',
                  })}
                </Button>
                {remainingDays <= SUBSCRIPTION_ALERT_REMAINING_DAYS &&
                  userSubscription?.pricing_system === PRICING_PLANS.PAY_ON_DEMAND && (
                    <Text color="orange" mt={1} fontSize="xs">
                      {t('subscription_expires_soon_message', {
                        ns: 'dashboard',
                      })}
                    </Text>
                  )}
              </Box>
            )
          }

          // if it is permitted to activate premium features, show the button
          if (isAllowedActivatePremiumFeatures) {
            return (
              <Button variant="outlinePrimary" size="md" mb={7} onClick={() => navigate('/usage')}>
                {t('get_started_with_premium_feature', {
                  ns: 'dashboard',
                })}
              </Button>
            )
          }

          // otherwise, show nothing
          return null
        })()}
        <ProjectsTable />
      </Container>
      <CreateProjectGroupModal isOpen={isOpeningCreateProjectGroupModal} onConfirm={onProjectGroupCreated} />
    </>
  )
}

export default Dashboard
