import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'
import { INSPECTION_CONTRACTEE_ERROR_COLOR } from 'config/styles'

import { InspectionItemNumberValues, SpacerInspectionItem } from 'interfaces/interfaces'

import { roundNumber } from './Util'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

export const getInspectionItems = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<SpacerInspectionItem[]> => {
  const sheets = await axios
    .get<{ results: SpacerInspectionItem[] }>(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) =>
      response.data.results.map((item) => ({
        ...item,
        target_area: item.target_area ? roundNumber(item.target_area, '0.1') : item.target_area,
      }))
    )
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_INSPECTION_SHEET)
      return []
    })

  return sheets
}

export const upsertInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  item: SpacerInspectionItem,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const { inspection_item_id, need_update, ...values } = item

  if (inspection_item_id) {
    const result = await axios
      .patch(
        `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items/${inspection_item_id}`,
        { ...values, need_update },
        {
          responseType: 'json',
          headers: { 'X-Authorization': `Bearer ${access_token}` },
        }
      )
      .then(() => true)
      .catch((err) => {
        handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
        return false
      })

    return result
  }

  const result = await axios
    .post(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items`,
      { ...values },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
      return false
    })

  return result
}

export const evaluateInspectionItemValue = (
  values: InspectionItemNumberValues,
  texts: Record<string, string>
): { result: boolean | null; description: string; color: string } => {
  if (values.estimated_value === null && values.specified_value === null) {
    return {
      result: true,
      description: '',
      color: '',
    }
  }

  const result = {
    result: null,
    description: '',
    color: '',
  }

  // values are not valid (not exists)
  if (
    values.estimated_value === undefined ||
    values.specified_value === undefined ||
    values.estimated_value === null ||
    values.specified_value === null
  ) {
    return result
  }

  // Computes difference of designed/estimated values.
  // The order is estimated value - designed value:
  const difference = values.estimated_value - values.specified_value

  if (difference === 0) {
    return {
      result: true,
      description: `${texts.actual} = ${texts.specification}`,
      color: '',
    }
  }

  return {
    result: difference > 0,
    description: `${texts.actual} ${difference > 0 ? '>' : '<'} ${texts.specification}`,
    color: difference > 0 ? '' : INSPECTION_CONTRACTEE_ERROR_COLOR,
  }
}
