import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

const ORIGIN_FILE_API_URL = `${API_GATEWAY_URL}/origin-files`
const DOWN_SAMPLED_FILES_API_URL = `${API_GATEWAY_URL}/down-sampled-files`

/**
 * To query the job status of down-sampling point cloud.
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the downsample pcd API
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {object | null} downsampling result
 */
export const checkJobStatusForDownsampling = async (
  access_token: string,
  jobToken: string,
  handleError: (err: unknown, processName: string) => void
): Promise<{
  translation: number[]
  error_object?: string
} | null> => {
  const results = await axios
    .get<{
      job_status: string
      results: {
        translation: number[]
        error_object?: string
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { translation: [], error_object: response.data.results.error_object }
      }
      return null
    })
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT)
      return null
    })
  return results
}

/**
 * get signed URL for uploading a pcd file
 * @param {string} access_token access token
 * @param {string} file_name file name
 * @param {string} content_type content type
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {string} signed URL
 */
export const getSignedUrlForUploadFile = async (
  access_token: string,
  file_name: string,
  content_type: string,
  handleError: (err: unknown, processName: string) => void
): Promise<string | null> => {
  const url = await axios
    .post<{ url: string }>(
      `${ORIGIN_FILE_API_URL}`,
      {
        file_name,
        content_type,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.url)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT)
      return null
    })

  return url
}

/**
 * start down-sampling of a pcd file
 * @param {string} access_token access token
 * @param {string} filename file name
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {string} job token
 */
export const startDownSampling = async (
  access_token: string,
  filename: string,
  handleError: (err: unknown, processName: string) => void
): Promise<string | null> => {
  const result = await axios
    .post<{ job_token: string }>(
      `${DOWN_SAMPLED_FILES_API_URL}`,
      { filename },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.job_token)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT)
      return null
    })

  return result
}

/**
 * get a signed URL for downloading a downsampled pcd file
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {string} signed URL
 */
export const getSignedUrlForGetDownSampledFile = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${DOWN_SAMPLED_FILES_API_URL}?project_id=${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT)
      return null
    })

  return url
}
