import axios from 'extends/axios'
import { Matrix4Tuple } from 'three'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { Project } from 'interfaces/interfaces'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`
const PROJECT_GROUPS_API_URL = `${API_GATEWAY_URL}/project-groups`

/**
 * get projects in a project group
 * @param {string} access_token access token
 * @param {string} project_group_id Project group id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project[]}
 */
export const getProjects = async (
  access_token: string,
  project_group_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Project[] | null> => {
  const projects = await axios
    .get<{ projects: Project[] }>(`${PROJECT_GROUPS_API_URL}/${project_group_id}/projects`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.projects)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_PROJECTS)
      return null
    })

  return projects
}

/**
 * get project by project id
 * @param {string} access_token access token
 * @param {string} project_id Project id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project | null}
 */
export const getProject = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .get<Project>(`${PROJECTS_API_URL}/${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_PROJECTS)
      return null
    })

  return project
}

/**
 * create a project in a project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group id
 * @param {string} project_name project name
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project} project object
 */
export const createProject = async (
  access_token: string,
  project_group_id: string,
  project_name: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .post<{ message: string; project: Project }>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}/projects`,
      {
        project_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.project)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT)
      return null
    })

  return project
}

/**
 * update project name
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} project_name new project name
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project} project object
 */
export const updateProjectName = async (
  access_token: string,
  project_id: string,
  project_name: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        project_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_PROJECT)
      return null
    })

  return project
}

/**
 * Update blackboard memo
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} blackboard_memo texts to be saved in blackboard memo
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project} project object
 */
export const updateProjectBlackboardMemo = async (
  access_token: string,
  project_id: string,
  blackboard_memo: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        blackboard_memo,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_PROJECT)
      return null
    })

  return project
}

/**
 * update pcd file name in a project object with translation vector
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} filename name of pcd file
 * @param {string} translation translation vector to be saved in project and used later
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project} project object
 */
export const updateProjectFileName = async (
  access_token: string,
  project_id: string,
  filename: string,
  translation: number[],
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        filename,
        translation,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_PROJECT)
      return null
    })

  return project
}

/**
 * update registration attribute of pcd file in a project object
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {Matrix4Tuple} transform_public_coordinate transform matrix of pcd file for a public coordinate
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Promise<Project | null>} project object
 */
export const updateProjectIFC = async (
  access_token: string,
  project_id: string,
  transform_public_coordinate: Matrix4Tuple,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        transform_public_coordinate,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_PROJECT)
      return null
    })

  return project
}

/**
 * delete project
 * @param {string} access_token access token
 * @param {string} projectId project id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {{projects: Project[], total_size: number}} {projects: Project[], total_size: number}
 */
export const deleteProject = async (
  access_token: string,
  projectId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${projectId}`, { headers: { 'X-Authorization': `Bearer ${access_token}` } })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_PROJECT)
      return false
    })
  return result
}
