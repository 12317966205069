// eslint-disable-next-line no-restricted-imports
import axios, { AxiosRequestConfig } from 'axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { ServiceStatus } from 'interfaces/interfaces'

/**
 * Get service status, used for displaying maintenance page
 * @param {string} access_token
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {ServiceStatus | null}
 */
export const getServiceStatus = async (
  access_token: string,
  handleError: (err: unknown, processName: string) => void
): Promise<ServiceStatus | null> => {
  const config: AxiosRequestConfig = {
    responseType: 'json',
    headers: access_token ? { 'X-Authorization': `Bearer ${access_token}` } : undefined,
  }
  const status = await axios
    .get<ServiceStatus>(`${API_GATEWAY_URL}/service-status`, config)
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_SERVICE_STATUS)
      return null
    })

  return status
}
