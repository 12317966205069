import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_EXTRA_SHAPE_KEYS, EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'

import {
  Cylinder,
  ExtraShapeKey,
  Overlap,
  Plane,
  Rhombus,
  ShapeKey,
  Shapes,
  SpacerAnnotation,
  Torus,
} from 'interfaces/interfaces'

import { calculateCenterAndDistance } from 'services/Editor'
import { saveOverlap } from 'services/Overlaps'
import { saveShapes } from 'services/Shapes'
import { parsePlane, parseRhombus } from 'services/Spacers'
import { compareSortedArrays, compareUnsortedArrays } from 'services/Util'

import { setSpacerAnnotations } from '../store/spacerAnnotation'

// TODO: Remove these converters when cylinder detection result is the same with shape
const getParametersByShapeType = (shapeType: ShapeKey | ExtraShapeKey, shape: Cylinder | Torus | Plane | Rhombus) => {
  if (shapeType === EDITOR_SHAPE_KEYS.CYLINDERS) {
    const cylinder = shape as Cylinder
    return { parameters_cylinder: { diameter: cylinder.diameter, length: cylinder.length } }
  }
  if (shapeType === EDITOR_SHAPE_KEYS.TORI) {
    const torus = shape as Torus
    return { parameters_torus: { minor_diameter: torus.minor_diameter, major_diameter: torus.major_diameter } }
  }
  if (shapeType === EDITOR_SHAPE_KEYS.PLANES) {
    const plane = shape as Plane
    const is_virtual = shape.is_virtual ? { is_virtual: true } : {}
    return { parameters_plane: { length_1: plane.length_1, length_2: plane.length_2 }, ...is_virtual }
  }
  if (shapeType === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI) {
    const rhombus = shape as Rhombus
    const is_virtual = shape.is_virtual ? { is_virtual: true } : {}
    return { parameters_rhombus: { length: rhombus.length, angle: rhombus.angle }, ...is_virtual }
  }
  return {}
}
const convertShapesToDetectionResult = (
  shapeType: ShapeKey | ExtraShapeKey,
  shapes: Cylinder[] | Torus[] | Plane[] | SpacerAnnotation[]
) =>
  shapes.map((s) => ({
    registration_result: {
      transformation: {
        t_00: s.transformation[0],
        t_01: s.transformation[1],
        t_02: s.transformation[2],
        t_03: s.transformation[3],
        t_10: s.transformation[4],
        t_11: s.transformation[5],
        t_12: s.transformation[6],
        t_13: s.transformation[7],
        t_20: s.transformation[8],
        t_21: s.transformation[9],
        t_22: s.transformation[10],
        t_23: s.transformation[11],
        t_30: s.transformation[12],
        t_31: s.transformation[13],
        t_32: s.transformation[14],
        t_33: s.transformation[15],
      },
    },
    ...getParametersByShapeType(shapeType, s),
  }))

const SaveButton: FC<{
  setShapes: (shapes: Shapes) => void
  setOverlaps: (overlaps: Overlap[]) => void
  resetShapesDistances: () => void
  setIsLayerModifying: (modifying: boolean) => void
}> = ({ setShapes, setOverlaps, resetShapesDistances, setIsLayerModifying }) => {
  const { handleError } = useContext(GlobalModalContext)
  const { t } = useTranslation(['projects'])
  const { getAccessToken } = useContext(UserContext)
  const { project, shapes, overlaps } = useContext(EditorContext)
  const dispatch = useAppDispatch()

  const { spacerAnnotations } = useSelector((state: RootState) => state.spacerAnnotation)

  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const filteredCylinderShapes = shapes.cylinders.filter((shape) =>
    shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)
  )
  const filteredPlaneShapes = shapes.planes.filter((shape) => shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))
  const filteredTorusShapes = shapes.tori.filter((shape) => shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))
  const filteredVirtualPlaneShapes = spacerAnnotations.filter(
    (shape) => shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX) && shape.shape_type === EDITOR_SHAPE_KEYS.PLANES
  )
  const filteredVirtualRhombusShapes = spacerAnnotations.filter(
    (shape) =>
      shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX) && shape.shape_type === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI
  )
  const needToSaveShapes =
    filteredCylinderShapes.length ||
    filteredPlaneShapes.length ||
    filteredTorusShapes.length ||
    filteredVirtualPlaneShapes.length ||
    filteredVirtualRhombusShapes.length

  const filteredOverlaps = overlaps.filter((overlap) =>
    overlap.overlap_length_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)
  )
  const needToSaveOverlaps = filteredOverlaps.length

  if (!project || (!needToSaveShapes && !needToSaveOverlaps)) {
    return null
  }

  const onSave = async () => {
    setIsLoading(true)
    setLoadingText(t('main_canvas.action_buttons.saving', { ns: 'projects' }))
    setIsLayerModifying(true)

    // need to save shapes first before saving overlaps
    if (needToSaveShapes) {
      await saveAllShapes()
    }
    if (needToSaveOverlaps) {
      await saveAllOverlaps()
    }

    resetShapesDistances()
    setLoadingText('')
    setIsLoading(false)
    setIsLayerModifying(false)
  }

  // ------------ Save overlaps ---------- //
  const saveAllOverlaps = async () => {
    // check if belonging cylinders still exist
    const existingOverlaps = overlaps.filter((overlap) =>
      // do not reuse filteredOverlaps, since state object has been updated
      overlap.overlap_length_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)
    )
    if (!existingOverlaps.length) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      setIsLayerModifying(false)
      return false
    }

    const savedOverlaps = await Promise.all(
      existingOverlaps.map(async (overlap) => saveOverlap(token, project.project_id, overlap, handleError))
    )
    const newOverlaps = [...overlaps]
    let counter = 0

    // for each overlap that has been saved,
    // find it's corresponding temporary one and replace it
    // the rest ones (incases there were some errors) will be kept
    savedOverlaps.forEach((overlap) => {
      if (overlap) {
        const overlapIndex = newOverlaps.findIndex(
          (o) =>
            overlap.cylinder_shape_ids &&
            o.cylinder_shape_ids &&
            compareSortedArrays(overlap.cylinder_shape_ids, o.cylinder_shape_ids)
        )
        if (overlapIndex >= 0) {
          // replace saved overlap
          newOverlaps.splice(overlapIndex, 1, {
            ...overlap,
            center: calculateCenterAndDistance({ points: overlap.positions_for_distance })?.[0],
          })
          counter += 1
        }
      }
    })

    setOverlaps(newOverlaps)
    // track with mixpanel
    mixpanel.track('Save Overlap length', {
      'Inspection area ID': project.project_id,
      'Inspection area Name': project.project_name,
      'Number of Saved Overlaps': counter,
    })

    return true
  }

  // ------------ Save shapes ------------ //
  const saveAllShapes = async () => {
    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      setIsLayerModifying(false)
      return false
    }

    const newShapes = { ...shapes }

    if (filteredCylinderShapes.length) {
      const shapeResult = await saveShapes(
        token,
        project.project_id,
        EDITOR_SHAPE_KEYS.CYLINDERS,
        convertShapesToDetectionResult(EDITOR_SHAPE_KEYS.CYLINDERS, filteredCylinderShapes),
        handleError
      )
      if (!shapeResult) {
        setLoadingText('')
        setIsLoading(false)
        setIsLayerModifying(false)
        return false
      }

      const results = shapeResult as Cylinder[]

      // Update the saved cylinder ids on the overlaps if any
      results.forEach((result) => {
        if (result.shape_id) {
          const temporaryCylinderId =
            newShapes.cylinders.find((c) => compareUnsortedArrays(c.transformation, result.transformation))?.shape_id ||
            ''

          if (temporaryCylinderId?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) {
            const targetOverlap = overlaps.find((o) => o.cylinder_shape_ids?.includes(temporaryCylinderId))

            if (targetOverlap?.cylinder_shape_ids) {
              const targetIndex = overlaps.indexOf(targetOverlap)
              const idIndex = targetOverlap.cylinder_shape_ids.indexOf(temporaryCylinderId)

              if (idIndex >= 0) {
                // Update the id
                targetOverlap.cylinder_shape_ids[idIndex] = result.shape_id

                // Save back to the state object
                const newOverlaps = [...overlaps]
                newOverlaps[targetIndex] = targetOverlap
                setOverlaps(newOverlaps)
              }
            }
          }
        }
      })

      newShapes.cylinders = [
        // remove temporary shapes
        ...newShapes.cylinders.filter((c) => !c.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
        // add new shapes
        ...results,
      ]
      // track with mixpanel
      mixpanel.track('Save Shapes', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Type of Saved Shapes': 'cylinder',
        'Number of Saved Shapes': results.length,
      })
    }
    if (filteredPlaneShapes.length) {
      const shapeResult = await saveShapes(
        token,
        project.project_id,
        EDITOR_SHAPE_KEYS.PLANES,
        convertShapesToDetectionResult(EDITOR_SHAPE_KEYS.PLANES, filteredPlaneShapes),
        handleError
      )
      if (!shapeResult) {
        setLoadingText('')
        setIsLoading(false)
        setIsLayerModifying(false)
        return false
      }

      const results = shapeResult as Plane[]
      newShapes.planes = [
        // remove temporary shapes
        ...newShapes.planes.filter((c) => !c.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
        // add new shapes
        ...results,
      ]
      // track with mixpanel
      mixpanel.track('Save Shapes', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Type of Saved Shapes': 'plane',
        'Number of Saved Shapes': results.length,
      })
    }
    if (filteredTorusShapes.length) {
      const shapeResult = await saveShapes(
        token,
        project.project_id,
        EDITOR_SHAPE_KEYS.TORI,
        convertShapesToDetectionResult(EDITOR_SHAPE_KEYS.TORI, filteredTorusShapes),
        handleError
      )
      if (!shapeResult) {
        setLoadingText('')
        setIsLoading(false)
        setIsLayerModifying(false)
        return false
      }

      const results = shapeResult as Torus[]
      newShapes.tori = [
        // remove temporary shapes
        ...newShapes.tori.filter((c) => !c.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
        // add new shapes
        ...results,
      ]
      // track with mixpanel
      mixpanel.track('Save Shapes', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Type of Saved Shapes': 'torus',
        'Number of Saved Shapes': results.length,
      })
    }

    // remove temporary shapes
    const newSpacerAnnotations = [
      ...spacerAnnotations.filter((c) => !c.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    ]
    if (filteredVirtualPlaneShapes.length) {
      const shapeResult = await saveShapes(
        token,
        project.project_id,
        EDITOR_SHAPE_KEYS.PLANES,
        convertShapesToDetectionResult(EDITOR_SHAPE_KEYS.PLANES, filteredVirtualPlaneShapes),
        handleError
      )
      if (!shapeResult) {
        setLoadingText('')
        setIsLoading(false)
        setIsLayerModifying(false)
        return false
      }

      const results = shapeResult as Plane[]
      // track with mixpanel
      mixpanel.track('Save Shapes', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Type of Saved Shapes': 'virtual plane',
        'Number of Saved Shapes': results.length,
      })

      // TODO: refactor the following line into a reducer function in spacerAnnotationSlice
      // like spacerPlaneSaved() so that we can avoid using setter setSpacerAnnotations().
      newSpacerAnnotations.push(...results.map((shape) => parsePlane(shape)))
      dispatch(setSpacerAnnotations(newSpacerAnnotations))
    }
    if (filteredVirtualRhombusShapes.length) {
      const shapeResult = await saveShapes(
        token,
        project.project_id,
        EDITOR_EXTRA_SHAPE_KEYS.RHOMBI,
        convertShapesToDetectionResult(EDITOR_EXTRA_SHAPE_KEYS.RHOMBI, filteredVirtualRhombusShapes),
        handleError
      )
      if (!shapeResult) {
        setLoadingText('')
        setIsLoading(false)
        setIsLayerModifying(false)
        return false
      }

      const results = shapeResult as Rhombus[]
      // track with mixpanel
      mixpanel.track('Save Shapes', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Type of Saved Shapes': 'virtual rhombus',
        'Number of Saved Shapes': results.length,
      })

      setSpacerAnnotations([...newSpacerAnnotations, ...results.map((shape) => parseRhombus(shape))])
    }

    setShapes(newShapes)
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={onSave}
      isLoading={isLoading}
      disabled={isLoading}
      spinnerPlacement="end"
      loadingText={loadingText}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.save', { ns: 'projects' })}
    </Button>
  )
}

export default SaveButton
