import './BlueprintViewer.css'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Image, Text, VStack } from '@chakra-ui/react'
import { usePinch } from '@use-gesture/react'
import AppTitle from 'pages/dashboard/components/AppTitle'
import { useParams } from 'react-router-dom'

import LoadingAnimation from 'assets/imgs/loading-animation-black.gif'

import { BlueprintContext } from 'contexts/Blueprint'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import {
  BLUEPRINT_MAX_SCALE,
  BLUEPRINT_MIN_SCALE,
  BLUEPRINT_MOUSE_EVENT_DELAY,
  DEFAULT_BLUEPRINT_TOOL,
  Z_INDEX,
} from 'config/constants'

import { Blueprint, Project, Timeout } from 'interfaces/interfaces'

import { getBlueprintUrl, getBlueprints } from 'services/Blueprint'
import { getProject } from 'services/Projects'
import { decideActionPermission } from 'services/Validation'

import TopNav, { NAV_TYPES } from '../components/TopNav'
import ActionsPanel from './ActionsPanel'
import DocListButton from './DocListButton'
import MainDocument from './MainDocument'

const BlueprintViewer: FC = () => {
  const { project_id } = useParams<{ project_id: string }>()
  const { userLoaded, userType, userTypeForOrganizations, organizations, getAccessToken } = useContext(UserContext)
  const { projectGroupsLoaded, projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { handleError } = useContext(GlobalModalContext)

  const viewerRef = useRef<HTMLDivElement>(null)

  const [mouseTimeout, setMouseTimeout] = useState<Timeout>()

  const [project, setProject] = useState<Project | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [blueprints, setBlueprints] = useState<Blueprint[] | null>(null)
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(null)
  const [blueprintUrl, setBlueprintUrl] = useState<string>()
  const [totalPages, setTotalPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState(1)
  const [visiblePages, setVisiblePages] = useState<Set<number>>(new Set())
  const [scale, setScale] = useState<number>(100)
  const [isControlKeyDown, setIsControlKeyDown] = useState(false)
  const [isMovingComment, setIsMovingComment] = useState(false)

  const projectGroup = projectGroups
    .concat(invitedProjectGroups)
    .find((proj) => proj.project_group_id === project?.project_group_id)

  // Check if the project is owned by any organization the user belongs to
  const orgOwningProject = organizations.find((org) => org.organization_id === projectGroup?.organization_id)
  const projectOwnedByOrg = orgOwningProject !== undefined
  // userTypeForOrganizations should be used if the user belongs to the organization owning the project.
  // otherwise, use userType.
  let userTypeForPermission = userType
  if (projectOwnedByOrg) {
    const organizationId = orgOwningProject.organization_id
    userTypeForPermission = userTypeForOrganizations[organizationId]
  }

  const isAllowedModify = useMemo(
    () => decideActionPermission(userTypeForPermission, projectOwnedByOrg).ACTIONS_WITH_PROJECT.MODIFY,
    [projectOwnedByOrg, userTypeForPermission]
  )

  // Toolbar
  const [selectedTool, setSelectedTool] = useState(DEFAULT_BLUEPRINT_TOOL)

  const handleKey = (e: KeyboardEvent) => {
    setIsControlKeyDown(e.ctrlKey)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKey)
    window.addEventListener('keyup', handleKey)

    return function cleanup() {
      window.removeEventListener('keydown', handleKey)
      window.removeEventListener('keyup', handleKey)
    }
  }, [])

  useEffect(() => {
    if (userLoaded && projectGroupsLoaded && project_id && (blueprints === null || project === null)) {
      void (async () => {
        setIsLoading(true)
        const token = await getAccessToken()
        if (!token) {
          setIsLoading(false)
          return false
        }

        const [requestedProject, requestedDocs] = await Promise.all([
          getProject(token, project_id, handleError),
          getBlueprints(token, project_id, handleError),
        ])

        setProject(requestedProject)
        setBlueprints(requestedDocs)
        setSelectedBlueprint(requestedDocs?.length ? requestedDocs[0] : null)
        setIsLoading(false)

        return false
      })()
    }
  }, [blueprints, getAccessToken, project_id, projectGroupsLoaded, handleError, userLoaded, project])

  useEffect(() => {
    if (project_id && blueprints?.length && selectedBlueprint) {
      void (async () => {
        setIsLoading(true)
        const token = await getAccessToken()
        if (!token) {
          setIsLoading(false)
          return false
        }

        const url = await getBlueprintUrl(token, project_id, selectedBlueprint.blueprint_id, handleError)
        setBlueprintUrl(url || undefined)
        setVisiblePages(new Set())
        setIsLoading(false)

        return false
      })()
    }
  }, [blueprints, getAccessToken, project_id, selectedBlueprint, handleError, userLoaded])

  const onPageConfirm = (page: number) => {
    if (totalPages && page > 0 && page <= totalPages) {
      const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${page}"]`)
      pageElement?.scrollIntoView()
    }
  }

  const handleZoom = (distance: number) => {
    if (mouseTimeout) {
      clearTimeout(mouseTimeout)
    }

    const timeout = setTimeout(() => {
      const newScale = Math.ceil(scale + distance)
      setScale(Math.min(Math.max(newScale, BLUEPRINT_MIN_SCALE), BLUEPRINT_MAX_SCALE))
    }, BLUEPRINT_MOUSE_EVENT_DELAY)

    setMouseTimeout(timeout)
  }

  usePinch(
    (state) => {
      if (state.type === 'wheel') {
        const e = state.event as WheelEvent
        handleZoom(-e.deltaY)
      } else {
        handleZoom(state.movement[0] * state.direction[0] * 20)
      }
    },
    { target: viewerRef, preventDefault: true }
  )

  const changeTool = useCallback((tool: string) => {
    setSelectedTool(tool)
  }, [])

  const contextValue = useMemo(
    () => ({
      selectedTool,
      changeTool,
    }),
    [changeTool, selectedTool]
  )

  if (!userLoaded || !project_id) {
    return null
  }

  if (isLoading) {
    return (
      <Box
        w="100vw"
        h="100vh"
        position="absolute"
        top={0}
        left={0}
        backgroundColor="black"
        zIndex={Z_INDEX.blueprint_viewer.blueprint_viewer}
      >
        <VStack position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" alignItems="center">
          <Image src={LoadingAnimation} w={160} />
          <Text color="whiteAlpha.700" fontWeight="bold">
            Loading...
          </Text>
        </VStack>
      </Box>
    )
  }

  return (
    <>
      <AppTitle project={project} projectGroup={projectGroup} />
      <BlueprintContext.Provider value={contextValue}>
        <Box w="100svw" h="100svh" backgroundColor="black" className="blueprint" userSelect="none">
          {!!selectedBlueprint?.blueprint_id && !!blueprintUrl && !isLoading && (
            <Box
              className={`blueprint-viewer${isControlKeyDown || isMovingComment ? ' disabled-scroll' : ''}`}
              ref={viewerRef}
            >
              <MainDocument
                project={project}
                isMovingComment={isMovingComment}
                setIsMovingComment={setIsMovingComment}
                blueprintId={selectedBlueprint.blueprint_id}
                url={blueprintUrl}
                totalPages={totalPages || 0}
                scale={scale}
                setTotalPages={setTotalPages}
                toggleVisiblePage={(page: number, visible: boolean) => {
                  const newSet = new Set(visiblePages)
                  if (!visible) {
                    newSet.delete(page)
                  } else {
                    newSet.add(page)
                  }

                  const topVisiblePage = newSet.size ? Math.min(...Array.from(newSet)) : 1
                  setPageNumber(topVisiblePage)
                  setVisiblePages(newSet)
                }}
                selectedTool={selectedTool}
              />
            </Box>
          )}
          <TopNav type={NAV_TYPES.BLUEPRINT} canModify={isAllowedModify} />
          <DocListButton
            isAllowedModify={isAllowedModify}
            blueprints={blueprints || []}
            selectedBlueprint={selectedBlueprint}
            setSelectedBlueprint={setSelectedBlueprint}
            // Refetch the list by setting null value
            onFileChanged={() => {
              setBlueprints(null)
              setSelectedBlueprint(null)
            }}
          />
          <ActionsPanel
            scale={scale}
            currentPage={pageNumber}
            totalPages={totalPages || 0}
            setScale={setScale}
            setPage={(page) => {
              setPageNumber(page)
              onPageConfirm(page)
            }}
          />
        </Box>
      </BlueprintContext.Provider>
    </>
  )
}

export default BlueprintViewer
