import axios from 'extends/axios'

import { API_PROCESS_MAP, PRICING_PLANS, USER_TYPES } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { BillingRecord, Subscription, UserProfile } from 'interfaces/interfaces'

const { CONSTANT, PAY_ON_DEMAND } = PRICING_PLANS
const { PAYING_USER, NON_PAYING_USER, BASE_USER } = USER_TYPES
const USER_API_URL = `${API_GATEWAY_URL}/users`

/**
 * Get user subscriptions status
 * @param {string} access_token
 * @param {string} user_id
 * @param {string} subscription_id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Subscription | null}
 */
export const getUserSubscription = async (
  access_token: string,
  user_id: string,
  subscription_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Subscription | null> => {
  const subscription = await axios
    .get<Subscription>(`${USER_API_URL}/${user_id}/subscriptions/${subscription_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_SUBSCRIPTIONS)
      return null
    })

  return subscription
}

/**
 * Get subscriptions status based on user and their subscription profile
 * @param {UserProfile} user
 * @param {Subscription} subscription
 * @return {Subscription | null}
 */
export const getSubscriptionStatus = (user: UserProfile, subscription: Subscription | null) => {
  const { user_type } = user
  const { pricing_system, subscription_detail } = subscription || {}
  const { additional_feature_enabled } = subscription_detail || {}

  if (pricing_system === PAY_ON_DEMAND && additional_feature_enabled && user_type === PAYING_USER)
    return '年間契約 (有償機能使用)'
  if (pricing_system === PAY_ON_DEMAND && !additional_feature_enabled && user_type === BASE_USER)
    return '年間契約 (有償機能使用なし)'
  if (pricing_system === CONSTANT && user_type === PAYING_USER) return '月額契約'

  if (!subscription && user_type === PAYING_USER) return '旧料金プラン（有償機能使用）'
  if (!subscription && user_type === BASE_USER) return '旧料金プラン（有償機能使用なし）'
  if (!subscription && user_type === NON_PAYING_USER) return '解約'

  return '不明'
}

/**
 * Get billing records
 * @param {string} access_token
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {BillingRecord[] | null}
 */
export const getBillingRecords = async (
  access_token: string,
  handleError: (err: unknown, processName: string) => void
): Promise<BillingRecord[] | null> => {
  const records = await axios
    .get<{ results: BillingRecord[] }>(`${API_GATEWAY_URL}/billing-records`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_BILLING_RECORDS)
      return null
    })

  return records
}

/**
 * Activate additional feature
 * @param {string} access_token
 * @param {string} user_id
 * @param {string} subscription_id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Subscription | null}
 */
export const activateAdditionalFeature = async (
  access_token: string,
  user_id: string,
  subscription_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Subscription | null> => {
  const records = await axios
    .patch<Subscription>(
      `${USER_API_URL}/${user_id}/subscriptions/${subscription_id}`,
      {
        subscription_detail: {
          additional_feature_enabled: true, // boolean; valid val: true
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.ACTIVATE_ADDITIONAL_FEATURE)
      return null
    })

  return records
}

/**
 * Activate premium feature when the organization has a Stripe subscription
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization to activate the premium feature for.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean} True if the premium feature was activated successfully, false otherwise.
 */
export const activateAdditionalFeatureWithStripeSub = async (
  access_token: string,
  organization_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> =>
  axios
    .post(
      `${API_GATEWAY_URL}/organizations/${organization_id}/additional-feature-activation-records`,
      {},
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.ACTIVATE_ADDITIONAL_FEATURE)
      return false
    })
