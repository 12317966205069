import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AnchorPoints, SpacerAnnotation } from 'interfaces/interfaces'

interface SpacerAnnotationState {
  // TODO: putting maps (non-serializable object) in redux store is not recommended.
  // Find a way to avoid this.
  // ref: https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions

  // formed spacer annotations (plane/rhombus)
  spacerAnnotations: SpacerAnnotation[]

  // anchor points for spacer annotations, consisting of 0-4 points
  spacerAnnotationAnchors: AnchorPoints[]
}

const initialState: SpacerAnnotationState = {
  spacerAnnotations: [],
  spacerAnnotationAnchors: [],
}

const spacerAnnotationSlice = createSlice({
  name: 'spacerAnnotation',
  initialState,
  reducers: {
    // setter function for spacerAnnotations
    setSpacerAnnotations: (state, action: PayloadAction<SpacerAnnotation[]>) => ({
      ...state,
      spacerAnnotations: action.payload,
    }),

    // setter function for spacerAnnotationAnchors
    setSpacerAnnotationAnchors: (state, action: PayloadAction<AnchorPoints[]>) => ({
      ...state,
      spacerAnnotationAnchors: action.payload,
    }),

    // higher-level reducer functions to update multiple properties
    // function for when the spacer grid is formed
    spacerGridFormed: (state, action: PayloadAction<SpacerAnnotation[]>) => ({
      ...state,
      spacerAnnotationAnchors: [],
      spacerAnnotations: [...state.spacerAnnotations, ...action.payload], // append spacer annotations
    }),

    reset: () => initialState,
  },
})

export const { setSpacerAnnotations, spacerGridFormed, setSpacerAnnotationAnchors, reset } =
  spacerAnnotationSlice.actions

export default spacerAnnotationSlice.reducer
