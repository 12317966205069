import { FC, useContext, useState } from 'react'

import { Button, HStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { InputEditorCancelIcon, InputEditorConfirmIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { extractSelectedShapes, showErrorModalShapeGroup, updateShapeGroup } from 'services/ShapeGroups'

const ShapeGroupRepickButton: FC = () => {
  const { t } = useTranslation(['projects'])
  const { getAccessToken } = useContext(UserContext)
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { shapes, shapeGroups, selectedShapeIds, project, updateShapeGroups } = useContext(EditorContext)

  const [isLoading, setIsLoading] = useState(false)

  const selectedShapeGroup = shapeGroups.find((group) => group.selected)

  const onPick = async () => {
    if (!selectedShapeGroup || !project?.project_id) {
      return false
    }

    const groupIndex = shapeGroups.indexOf(selectedShapeGroup)

    setIsLoading(true)
    let pickedShapes
    try {
      pickedShapes = extractSelectedShapes(
        shapes,
        shapeGroups.filter((group) => group !== selectedShapeGroup),
        selectedShapeIds
      )
    } catch (e) {
      setIsLoading(false)

      const textModal: Record<string, string> = t('main_canvas.action_buttons.error_shape_group', {
        returnObjects: true,
        ns: 'projects',
      })
      const textConfirm = t('main_canvas.action_buttons.confirm', { ns: 'projects' })
      showErrorModalShapeGroup(e as Error, textModal, textConfirm, showModal)
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const cylinder_ids = pickedShapes.cylinders.map((shape) => shape.shape_id)
    const torus_ids = pickedShapes.tori.map((shape) => shape.shape_id)

    if (!cylinder_ids.length && !torus_ids.length) {
      showModal({
        body: t('main_canvas.action_buttons.error_shape_group.message_no_rebars', { ns: 'projects' }),
        modalType: MODAL_TYPES.ALERT,
        confirmText: t('main_canvas.action_buttons.confirm', { ns: 'projects' }),
      })
      setIsLoading(false)
      return false
    }
    if (
      (selectedShapeGroup.cylinder_ids?.length && torus_ids.length) ||
      (selectedShapeGroup.torus_ids?.length && cylinder_ids.length)
    ) {
      showModal({
        body: t('main_canvas.action_buttons.error_shape_group.message_different_shape', { ns: 'projects' }),
        modalType: MODAL_TYPES.ALERT,
        confirmText: t('main_canvas.action_buttons.confirm', { ns: 'projects' }),
      })
      setIsLoading(false)
      return false
    }

    const result = await updateShapeGroup(
      token,
      project.project_id,
      {
        ...selectedShapeGroup,
        cylinder_ids,
        torus_ids,
      },
      handleError
    )

    if (!result) {
      setIsLoading(false)
      return false
    }

    // track with mixpanel
    mixpanel.track('Update Type (種別)', {
      'Inspection area ID': project.project_id,
      'Updated property': 'used shapes',
      'Type ID': selectedShapeGroup.grouping_shape_type_id,
      'Old shape number': (selectedShapeGroup.cylinder_ids?.length ?? 0) + (selectedShapeGroup.torus_ids?.length ?? 0),
      'New shape number': cylinder_ids.length + torus_ids.length,
      'Shape type': selectedShapeGroup.cylinder_ids?.length ? 'cylinders' : 'tori',
    })

    const groups = [...shapeGroups]
    groups[groupIndex].cylinder_ids = cylinder_ids
    groups[groupIndex].torus_ids = torus_ids
    groups[groupIndex].selected = false
    updateShapeGroups(groups)

    setIsLoading(false)
    return true
  }

  if (!selectedShapeGroup) {
    return null
  }

  return (
    <HStack w="100%">
      <Button
        colorScheme="secondary"
        size={isTablet ? 'lg' : 'sm'}
        maxWidth={12}
        fontSize="md"
        px={1}
        variant="toolbar"
        onClick={() => {
          updateShapeGroups(shapeGroups.map((group) => ({ ...group, selected: false })))
        }}
      >
        <InputEditorCancelIcon size="60%" />
      </Button>
      <Button
        colorScheme="secondary"
        rightIcon={<InputEditorConfirmIcon size={isTablet ? 20 : 12} />}
        size={isTablet ? 'lg' : 'sm'}
        fontSize={isTablet ? 'lg' : 'xs'}
        variant="toolbar"
        onClick={onPick}
        spinnerPlacement="end"
        flex={1}
        justifyContent="space-between"
        disabled={isLoading}
      >
        {t('main_canvas.action_buttons.update_group', { ns: 'projects' })}
      </Button>
    </HStack>
  )
}

export default ShapeGroupRepickButton
