import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { ProjectGroup } from 'interfaces/interfaces'

const PROJECT_GROUPS_API_URL = `${API_GATEWAY_URL}/project-groups`
const INVITE_USER_API_URL = `${API_GATEWAY_URL}/invite-user`

/**
 * Get project groups owned by the specified organization or user (only for admin)
 * @param {string} access_token access token
 * @param {string | undefined} organization_id organization ID
 * @param {string | undefined} user_id user ID. Only for admin.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {{projectGroups: ProjectGroup[], total_size: number}}
 */
export const getProjectGroups = async (
  access_token: string,
  organization_id: string | undefined,
  user_id: string | undefined,
  handleError: (err: unknown, processName: string) => void
): Promise<{ projectGroups: ProjectGroup[]; total_size: number } | null> => {
  const organizationParam = organization_id ? `?organization_id=${organization_id}` : ''
  const userParam = user_id ? `?user_id=${user_id}` : ''
  const projectGroups = await axios
    .get<{ results: ProjectGroup[] }>(`${PROJECT_GROUPS_API_URL}${userParam || organizationParam}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      const total_size = response.data.results.reduce(
        (_total_size, projectGroup) => _total_size + projectGroup.total_size,
        0
      )
      return {
        projectGroups: response.data.results,
        total_size,
      }
    })
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_PROJECT_GROUPS)
      return null
    })

  return projectGroups
}

/**
 * Get invited project groups
 * @param {string} access_token access token
 * @param {string | undefined} user_id user ID. Only for admin.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {{projectGroups: ProjectGroup[], total_size: number}}
 */
export const getInvitedProjectGroups = async (
  access_token: string,
  user_id: string | undefined,
  handleError: (err: unknown, processName: string) => void
): Promise<{ projectGroups: ProjectGroup[] } | null> => {
  const userParam = user_id ? `&user_id=${user_id}` : ''
  const projectGroups = await axios
    .get<{ results: ProjectGroup[] }>(`${PROJECT_GROUPS_API_URL}?shared=true${userParam}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => ({ projectGroups: response.data.results }))
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_PROJECT_GROUPS)
      return null
    })

  return projectGroups
}

/**
 * Create project group
 * @param {string} access_token access token
 * @param {string | undefined} organization_id organization ID
 * @param {string} project_group_name project group name
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {ProjectGroup} project group object
 */
export const createProjectGroup = async (
  access_token: string,
  organization_id: string | undefined,
  project_group_name: string,
  handleError: (err: unknown, processName: string) => void
): Promise<ProjectGroup | null> => {
  const organizationParam = organization_id ? `/organizations/${organization_id}` : ''
  const projectGroup = await axios
    .post<ProjectGroup>(
      `${API_GATEWAY_URL}${organizationParam}/project-groups`,
      {
        project_group_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_PROJECT_GROUP)
      return null
    })

  return projectGroup
}

/**
 * Change project group name
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} project_group_name project group name
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {ProjectGroup} project group object
 */
export const updateProjectGroupName = async (
  access_token: string,
  project_group_id: string,
  project_group_name: string,
  handleError: (err: unknown, processName: string) => void
): Promise<ProjectGroup | null> => {
  const projectGroup = await axios
    .patch<ProjectGroup>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}`,
      {
        project_group_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_PROJECT_GROUP)
      return null
    })

  return projectGroup
}

/**
 * invite user to project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} email_address email address of the user to be invited
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {ProjectGroup} project group object
 */
export const inviteUserToProjectGroup = async (
  access_token: string,
  project_group_id: string,
  email_address: string,
  handleError: (err: unknown, processName: string) => void
): Promise<ProjectGroup | null> => {
  const projectGroup = await axios
    .post<ProjectGroup>(
      `${INVITE_USER_API_URL}`,
      {
        project_group_id,
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.INVITE_USER_TO_PROJECT_GROUP)
      return null
    })

  return projectGroup
}

/**
 * remove invited user from project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} user_id user ID to be removed
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {boolean} success or failure
 */
export const deleteInvitedUserFromProjectGroup = async (
  access_token: string,
  project_group_id: string,
  user_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<ProjectGroup>(`${PROJECT_GROUPS_API_URL}/${project_group_id}/users/${user_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_INVITED_USER_FROM_PROJECT_GROUP)
      return false
    })

  return result
}

/**
 * Delete project group
 * @param {string} access_token access token
 * @param {string} projectGroupId project group id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Promise<boolean>} success or failure
 */
export const deleteProjectGroup = async (
  access_token: string,
  projectGroupId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECT_GROUPS_API_URL}/${projectGroupId}`, { headers: { 'X-Authorization': `Bearer ${access_token}` } })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_PROJECT_GROUP)
      return false
    })
  return result
}
