import { FC, useContext, useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { TOAST_CONFIG } from 'config/styles'

import { inviteUser, sendInvitationEmail } from 'services/Users'
import { isValidEmail } from 'services/Validation'

const InviteUserModal: FC<{
  isOpen: boolean
  onConfirm: () => void
  email?: string
}> = ({ isOpen, onConfirm, email }) => {
  const { handleError } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { t } = useTranslation(['navbar'])

  const toast = useToast()

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (email) {
      setEmailAddress(email)
    }
  }, [email])

  const onInviteUser = async () => {
    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const invitation = await inviteUser(token, emailAddress, handleError)
    if (!invitation) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const sendEmailNotificationResult = await sendInvitationEmail(token, emailAddress, handleError)
    if (sendEmailNotificationResult) {
      toast({
        ...TOAST_CONFIG,
        title: t('invite_user_modal.send_invitation_email_success', { ns: 'navbar' }),
      })
    }

    onConfirm()
    setIsLoading(false)

    // track with mixpanel
    mixpanel.track('Invite to create user account', { 'Target email': emailAddress })

    return true
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => onConfirm()} trapFocus={false} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{t('invite_user_modal.modal_header', { ns: 'navbar' })}</ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody position="relative">
          <FormControl>
            <FormLabel>{t('invite_user_modal.description', { ns: 'navbar' })}</FormLabel>
            <Input
              placeholder={t('invite_user_modal.input_placeholder', { ns: 'navbar' })}
              id="email_address"
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value.trim())}
            />
            <FormHelperText>
              {t('invite_user_modal.invitation_expires_in', { ns: 'navbar' })}:&nbsp;
              <Text fontWeight="bold" display="inline-block">
                {t('invite_user_modal.invitation_duration', { ns: 'navbar', count: 7 })}
              </Text>
            </FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={() => onConfirm()} disabled={isLoading}>
            {t('invite_user_modal.cancel', { ns: 'navbar' })}
          </Button>
          <Button
            disabled={isLoading || !isValidEmail(emailAddress)}
            colorScheme="primary"
            me={3}
            py={2}
            minW="100px"
            onClick={() => onInviteUser()}
            isLoading={isLoading}
            loadingText={t('invite_user_modal.inviting', { ns: 'navbar' })}
            spinnerPlacement="end"
          >
            {t('invite_user_modal.invite', { ns: 'navbar' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InviteUserModal
