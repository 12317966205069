import { FC, useContext, useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { ChevronDownIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { LICENSE_ASSIGNMENT_STATUS } from 'config/constants'

import { Organization } from 'interfaces/user'

import { assignLicenseToUser, stripLicenseFromUser } from 'services/Organizations'

/**
 * modal for updating license assignment on specified user
 *
 * @param {{ isOpen: any; targetUser: any; accountCount: any; status: any; onConfirm: any; }} param0
 * @param {*} param0.isOpen - modal open state
 * @param {*} param0.targetUserEmail - target user's email address
 * @param {*} param0.targetUserId - target user's ID
 * @param {*} param0.accountCount - number of assignable accounts
 * @param {*} param0.status - current license assignment status
 * @param {*} param0.currentOrganization - current organization
 * @param {*} param0.onConfirm - callback function to handle modal close event
 */
const UpdateLicenseAssignmentModal: FC<{
  isOpen: boolean
  targetUserEmail: string
  targetUserId: string
  accountCount: number
  status: string
  currentOrganization: Organization
  onConfirm: (result?: boolean) => void
}> = ({ isOpen, targetUserEmail, targetUserId, accountCount, status, currentOrganization, onConfirm }) => {
  const { t } = useTranslation(['organization', 'global_modal'])
  const { getAccessToken } = useContext(UserContext)
  const { handleError } = useContext(GlobalModalContext)

  const [assignmentStatus, setAssignmentStatus] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onUpdate = async () => {
    if (assignmentStatus === status) {
      onConfirm()
      return false
    }

    setIsLoading(true)
    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const result =
      assignmentStatus === LICENSE_ASSIGNMENT_STATUS.assigned
        ? await assignLicenseToUser(token, currentOrganization.organization_id, targetUserId, handleError)
        : await stripLicenseFromUser(token, currentOrganization.organization_id, targetUserId, handleError)

    setIsLoading(false)
    onConfirm(result)

    return true
  }

  useEffect(() => {
    setAssignmentStatus(status)
  }, [status])

  return (
    <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={onConfirm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('update_license_assignment', { ns: 'organization' })}</ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody>
          <VStack spacing={5}>
            <FormControl>
              <FormLabel>
                {t('update_license_modal.target', {
                  ns: 'organization',
                })}
              </FormLabel>
              <Text>{targetUserEmail}</Text>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="target">
                {t('update_license_modal.status', {
                  ns: 'organization',
                })}
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  textAlign="left"
                  fontWeight="normal"
                  width="100%"
                >
                  {t(`license_assignment.${assignmentStatus}`, { ns: 'organization' })}
                </MenuButton>
                <MenuList>
                  {/* use only assigned/view_only instead of full options */}
                  {[LICENSE_ASSIGNMENT_STATUS.assigned, LICENSE_ASSIGNMENT_STATUS.view_only].map((licenseStatus) => (
                    <MenuItem
                      key={licenseStatus}
                      backgroundColor={licenseStatus === assignmentStatus ? 'primary.50' : 'transparent'}
                      onClick={() => setAssignmentStatus(licenseStatus)}
                    >
                      {t(`license_assignment.${licenseStatus}`, { ns: 'organization' })}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('update_license_modal.account_count', {
                  ns: 'organization',
                })}
              </FormLabel>
              <Text>{accountCount}</Text>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter mt={8}>
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('cancel', {
              ns: 'global_modal',
            })}
          </Button>

          <Button
            colorScheme="primary"
            disabled={isLoading}
            isLoading={isLoading}
            loadingText={t('update_license_modal.updating', {
              ns: 'organization',
            })}
            minW="100px"
            onClick={onUpdate}
            py={2}
          >
            {t('update_license_modal.update', { ns: 'organization' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateLicenseAssignmentModal
