import Decimal from 'decimal.js'
import axios from 'extends/axios'

import { API_PROCESS_MAP, EDITOR_DECIMAL_BASE, EDITOR_SHAPES_SITUATIONS, MILLIMETER_SCALE } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'
import {
  INSPECTION_CONTRACTEE_ERROR_COLOR,
  INSPECTION_CONTRACTOR_ERROR_COLOR,
  INSPECTION_NORMAL_COLOR,
} from 'config/styles'

import {
  Cylinder,
  InspectionItem,
  InspectionItemEvaluation,
  InspectionItemEvaluationResult,
  InspectionItemNumberValues,
  InspectionSheet,
  Plane,
  ShapeDistance,
  TextsInspectionSheet,
  Torus,
} from 'interfaces/interfaces'

import { meterToMillimeter, millimeterToMeter, roundNumber } from './Util'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

const clarifyCylinders = (cylinders: Cylinder[]) =>
  cylinders.map((s) => ({
    transformation: s.transformation,
    diameter: s.diameter,
    length: s.length,
  }))
const clarifyTori = (tori: Torus[]) =>
  tori.map((s) => ({
    transformation: s.transformation,
    major_diameter: s.major_diameter,
    minor_diameter: s.minor_diameter,
  }))
const clarifyPlane = (plane: Plane) => ({
  transformation: plane.transformation,
  length_1: plane.length_1,
  length_2: plane.length_2,
})

const clarifyShapes = (situation: string, shapes: Cylinder[] | Torus[]) => {
  if (
    situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS ||
    situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC
  ) {
    return clarifyCylinders(shapes as Cylinder[])
  }
  if (situation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
    return clarifyTori(shapes as Torus[])
  }
  return []
}

/**
 * returns formatted string of difference between estimated and specified values.
 *
 * @param {(InspectionItemNumberValues | null)} values - values of inspection item (estimated/specified value).
 * @param {?boolean} [needScaleValueUp] - whether to scale up value.
 * @param {?boolean} [hideSignString] - whether to hide sign string.
 * @returns {string} formatted string of difference.
 */
export const formatInspectionDifferentValue = (
  values: InspectionItemNumberValues | null,
  needScaleValueUp?: boolean,
  hideSignString?: boolean
): string => {
  if (
    values === null ||
    values.estimated_value === null ||
    values.specified_value === null ||
    values.estimated_value === undefined ||
    values.specified_value === undefined
  ) {
    return '-'
  }

  const scaledValue = new Decimal(values.estimated_value)
    .mul(needScaleValueUp ? MILLIMETER_SCALE : 1)
    .minus(new Decimal(values.specified_value).mul(needScaleValueUp ? MILLIMETER_SCALE : 1))
    .toNumber()

  if (scaledValue < 0) {
    return scaledValue.toString()
  }
  if (scaledValue > 0) {
    return `${hideSignString ? '' : '+'}${scaledValue.toString()}`
  }
  return `${hideSignString ? '' : '±'}0`
}

export const getMeanDistanceAndIndividualDistance = async (
  access_token: string,
  situation: string,
  shapes: Cylinder[] | Torus[],
  handleError: (err: unknown, processName: string) => void
): Promise<{ mean_distance: number; individual_distance: ShapeDistance[]; permutation_map: number[] } | null> => {
  const meanDistance = await axios
    .post<{ mean_distance: number; individual_distance: ShapeDistance[]; permutation_map: number[] }>(
      `${API_GATEWAY_URL}/evaluate-mean-distance`,
      {
        situation,
        shapes: clarifyShapes(situation, shapes),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => ({
      ...response.data,
      mean_distance: roundNumber(response.data.mean_distance, EDITOR_DECIMAL_BASE),
    }))
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.EVALUATE_MEAN_DISTANCE)
      return null
    })

  return meanDistance
}

export const getCoverDistanceAndIndividualDistance = async (
  access_token: string,
  cylinders: Cylinder[],
  plane: Plane,
  handleError: (err: unknown, processName: string) => void
): Promise<{ cover_distance: number; individual_distance: ShapeDistance[] } | null> => {
  const coverDistance = await axios
    .post<{ cover_distance: number; individual_distance: ShapeDistance[] }>(
      `${API_GATEWAY_URL}/evaluate-cover-distance`,
      {
        cylinders: clarifyCylinders(cylinders),
        plane: clarifyPlane(plane),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => ({
      ...response.data,
      cover_distance: roundNumber(response.data.cover_distance, EDITOR_DECIMAL_BASE),
    }))
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.EVALUATE_COVER_DISTANCE)
      return null
    })

  return coverDistance
}

export const getInspectionSheets = async (
  access_token: string,
  projectId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<InspectionSheet[]> => {
  const sheets = await axios
    .get<{ results: InspectionSheet[] }>(`${PROJECTS_API_URL}/${projectId}/inspection-sheets`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_INSPECTION_SHEET)
      return []
    })

  return sheets
}

export const getInspectionItems = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<InspectionItem[]> => {
  const sheets = await axios
    .get<{ results: InspectionItem[] }>(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) =>
      response.data.results.map((item) => ({
        ...item,
        cover_distance: roundValues(item.cover_distance),
        mean_distance: roundValues(item.mean_distance),
      }))
    )
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_INSPECTION_SHEET)
      return []
    })

  return sheets
}

export const upsertInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  item: InspectionItem,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const { inspection_item_id, need_update, ...values } = item
  const clarifiedItem = {
    ...values,
    diameter: {
      specified_value: item.diameter.specified_value || null,
      estimated_value: item.diameter.estimated_value || null,
    },
    mean_distance: {
      specified_value: item.mean_distance.specified_value || null,
      estimated_value: item.mean_distance.estimated_value || null,
    },
    cover_distance: {
      specified_value: item.cover_distance.specified_value || null,
      estimated_value: item.cover_distance.estimated_value || null,
    },
    number: {
      specified_value: item.number.specified_value || null,
      estimated_value: item.number.estimated_value || null,
    },
    aligned_shapes_individual_distance: item.aligned_shapes_individual_distance?.length
      ? item.aligned_shapes_individual_distance
      : null,
    cylinders_and_plane_individual_distance: item.cylinders_and_plane_individual_distance?.length
      ? item.cylinders_and_plane_individual_distance
      : null,
    permutation_map: item.permutation_map?.length ? item.permutation_map : null,
  }

  if (inspection_item_id) {
    const result = await axios
      .patch(
        `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items/${inspection_item_id}`,
        { ...clarifiedItem, need_update },
        {
          responseType: 'json',
          headers: { 'X-Authorization': `Bearer ${access_token}` },
        }
      )
      .then(() => true)
      .catch((err) => {
        handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
        return false
      })

    return result
  }

  const result = await axios
    .post(`${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items`, clarifiedItem, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
      return false
    })

  return result
}

export const deleteInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  inspectionItemId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}/inspection-items/${inspectionItemId}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
      return false
    })

  return result
}

export const updateInspectionSheet = async (
  access_token: string,
  projectId: string,
  inspectionSheet: InspectionSheet,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { inspection_sheet_id, external_sheet_id, external_sheet_schema, sheet_type, ...values } = inspectionSheet

  if (!inspection_sheet_id) {
    return false
  }

  const result = await axios
    .patch<{ results: InspectionSheet[] }>(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspection_sheet_id}`,
      { ...values },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.MODIFY_INSPECTION_SHEET)
      return false
    })

  return result
}

export const getSignedUrlForUploadXMLFile = async (
  access_token: string,
  project_id: string,
  filename: string,
  handleError: (err: unknown, processName: string) => void
): Promise<{
  external_sheet_id: string
  url: string
  s3_filename: string
  original_filename: string
  content_type: string
} | null> => {
  const result = await axios
    .post<{
      external_sheet_id: string
      url: string
      s3_filename: string
      original_filename: string
      content_type: string
    }>(
      `${PROJECTS_API_URL}/${project_id}/external-sheet-files`,
      {
        filename,
        external_sheet_schema: 'musashi',
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.IMPORT_XML)
      return null
    })

  return result
}

export const importXMLFile = async (
  access_token: string,
  project_id: string,
  inspection_sheet_id: string,
  external_sheet_id: string,
  s3_filename: string,
  original_filename: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .put(
      `${PROJECTS_API_URL}/${project_id}/inspection-sheets/${inspection_sheet_id}/external-sheets/${external_sheet_id}`,
      {
        s3_filename,
        original_filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.IMPORT_XML)
      return false
    })

  return result
}

// https://github.com/DataLabs-Japan/tekkin-app/issues/26#issuecomment-1309844610
/**
 *
 * @param {InspectionItemNumberValues} values
 * @param {TextsInspectionSheet} texts texts for inspection sheet
 * @param {?number} criterion
 * @param {?(number | null)} referValue diameter
 * @param {?boolean} needScaleCriterionUp need to convert meter to millimeter
 * @param {?boolean} needScaleValueUp need to convert meter to millimeter
 * @param {?boolean} emptyIsValid if both estimated and specified values are null, return valid
 * @returns {InspectionItemEvaluationResult}
 *   - null: values are not valid (not exists)
 *   - true: passed
 *   - false: not passed
 */
export const evaluateInspectionItemValue = (
  values: InspectionItemNumberValues,
  texts: TextsInspectionSheet,
  criterion?: number,
  referValue?: number | null,
  needScaleCriterionUp?: boolean,
  needScaleValueUp?: boolean,
  emptyIsValid?: boolean
): InspectionItemEvaluationResult => {
  if (emptyIsValid && values.estimated_value === null && values.specified_value === null) {
    return {
      qualified: true,
      description: '',
    }
  }

  if (values.estimated_value === null || values.specified_value === null || criterion === undefined) {
    return {
      qualified: false,
      description: '',
    }
  }

  const result = {
    qualified: false,
    description: '',
  }

  // values are not valid (not exists)
  if (values.estimated_value === undefined || values.specified_value === undefined) {
    return result
  }

  // Computes criterion_contractee/criterion_contractor using designed value of diameter:
  const criterionReference =
    referValue !== undefined && referValue !== null
      ? roundNumber(referValue * criterion, EDITOR_DECIMAL_BASE)
      : criterion
  // Computes difference of designed/estimated values.
  // The order is estimated value - designed value:
  const difference = roundNumber(values.estimated_value - values.specified_value, EDITOR_DECIMAL_BASE)

  // For description
  const scaledUpDifference = needScaleValueUp ? meterToMillimeter(difference) : difference
  const scaledUpReferValue = !referValue ? referValue : meterToMillimeter(referValue)
  const scaledUpCriterionReference = needScaleCriterionUp ? meterToMillimeter(criterionReference) : criterionReference

  const referenceDescription =
    scaledUpReferValue !== undefined && scaledUpReferValue !== null && criterion !== 0
      ? `${roundNumber(scaledUpReferValue, EDITOR_DECIMAL_BASE)} * ${roundNumber(
          criterion,
          EDITOR_DECIMAL_BASE
        )} = ${roundNumber(scaledUpCriterionReference, EDITOR_DECIMAL_BASE)}`
      : `${criterion}`

  // Check whether the difference is within the criterion
  if (Math.abs(scaledUpDifference) <= scaledUpCriterionReference) {
    return {
      qualified: true,
      description:
        referValue !== undefined && referValue !== null
          ? `(${scaledUpDifference}) ≤ ±(${referenceDescription})`
          : `|${texts.values.actual} - ${texts.values.specification}| ≤ ${texts.tolerances.tolerance}`,
    }
  }
  return {
    qualified: false,
    description:
      referValue !== undefined && referValue !== null
        ? `(${scaledUpDifference}) > ±(${referenceDescription})`
        : `|${texts.values.actual} - ${texts.values.specification}| > ${texts.tolerances.tolerance}`,
  }
}

/**
 * converts input specified/estimated values from meter to millimeter.
 *
 * @param {(InspectionItemNumberValues | undefined)} values - values of inspection item (specfied/estimated values).
 * @param {(number | null)} fallbackSpecifiedValue - fallback value for specified value.
 * @param {(number | null)} fallbackEstimatedValue - fallback value for estimated value.
 * @returns {{ specified_value: number | null; estimated_value: number | null; }} - resultant values.
 */
export const scaleUpValues = (
  values: InspectionItemNumberValues | undefined,
  fallbackSpecifiedValue: number | null,
  fallbackEstimatedValue: number | null
) => ({
  specified_value:
    values?.specified_value !== undefined && values.specified_value !== null
      ? meterToMillimeter(values.specified_value)
      : fallbackSpecifiedValue,
  estimated_value:
    values?.estimated_value !== undefined && values.estimated_value !== null
      ? meterToMillimeter(values.estimated_value)
      : fallbackEstimatedValue,
})

/**
 * converts input specified/estimated values from millimeter to meter.
 *
 * @param {InspectionItemNumberValues} values - values of inspection item (specfied/estimated values).
 * @returns {{ specified_value: number | null; estimated_value: number | null; }} - resultant values.
 */
export const scaleDownValues = (values: InspectionItemNumberValues) => ({
  specified_value:
    values.specified_value !== undefined && values.specified_value !== null
      ? millimeterToMeter(values.specified_value)
      : null,
  estimated_value:
    values.estimated_value !== undefined && values.estimated_value !== null
      ? millimeterToMeter(values.estimated_value)
      : null,
})

/**
 * round values of inspection item.
 *
 * @param {InspectionItemNumberValues} values - values of inspection item (specfied/estimated values).
 * @returns {{ specified_value: any; estimated_value: any; }} - resultant values.
 */
const roundValues = (values: InspectionItemNumberValues) => ({
  specified_value: values.specified_value
    ? roundNumber(values.specified_value, EDITOR_DECIMAL_BASE)
    : values.specified_value,
  estimated_value: values.estimated_value
    ? roundNumber(values.estimated_value, EDITOR_DECIMAL_BASE)
    : values.estimated_value,
})

/**
 * get color of evaluation
 *
 * @param {InspectionItemEvaluation} evaluation - evaluation of inspection item.
 * @returns {string} resultant color.
 */
const getEvaluationColor = (evaluation: InspectionItemEvaluation) => {
  if (!evaluation.qualifiedByContractee) {
    return INSPECTION_CONTRACTEE_ERROR_COLOR
  }

  if (!evaluation.qualifiedByContractor) {
    return INSPECTION_CONTRACTOR_ERROR_COLOR
  }

  return INSPECTION_NORMAL_COLOR
}

/**
 * Evaluate values of inspection item with criteria, and return color and description
 *
 * @param {InspectionItemNumberValues | null} values - values of inspection item.
 * @param {TextsInspectionSheet} texts texts for inspection sheet
 * @param {?number} contracteeCriterion - criterion value of contractee.
 * @param {?number} contractorCriterion - criterion value of contractor.
 * @param {?(number | null)} [contracteeReferValue] - refer value of contractee.
 * @param {?(number | null)} [contractorReferValue] - refer value of contractor.
 * @param {?boolean} [needScaleCriterionUp] - whether to scale up value.
 * @param {?boolean} [needScaleValueUp] - whether to scale up value.
 * @param {?boolean} [emptyIsValid] - whether to judge empty value to be valid.
 * @returns {InspectionItemEvaluation} resultant object.
 */
export const evaluateValues = (
  values: InspectionItemNumberValues | null,
  texts: TextsInspectionSheet,
  contracteeCriterion?: number,
  contractorCriterion?: number,
  contracteeReferValue?: number | null,
  contractorReferValue?: number | null,
  needScaleCriterionUp?: boolean,
  needScaleValueUp?: boolean,
  emptyIsValid?: boolean
): InspectionItemEvaluation => {
  if (values === null) {
    return { description: '', qualifiedByContractee: true, qualifiedByContractor: true, color: INSPECTION_NORMAL_COLOR }
  }

  const contracteeResult = evaluateInspectionItemValue(
    values,
    texts,
    contracteeCriterion,
    contracteeReferValue,
    needScaleCriterionUp,
    needScaleValueUp,
    emptyIsValid
  )

  const contractorResult = evaluateInspectionItemValue(
    values,
    texts,
    contractorCriterion,
    contractorReferValue,
    needScaleCriterionUp,
    needScaleValueUp,
    emptyIsValid
  )

  const result: InspectionItemEvaluation = {
    qualifiedByContractee: contracteeResult.qualified,
    qualifiedByContractor: contractorResult.qualified,
    description:
      !contracteeResult.qualified || (contracteeResult.qualified && contractorResult.qualified)
        ? contracteeResult.description
        : contractorResult.description,
  }

  result.color = getEvaluationColor(result)

  return result
}
