// eslint-disable-next-line no-restricted-imports
import axios from 'axios'

import { AXIOS_ERROR_CODE } from 'config/constants'

import { ErrorResponse, TextsError, TextsErrorSpecificApi } from 'interfaces/interfaces'

/**
 * generate error message for API response for each specified API process
 *
 * @param {} statusId custom status id from API response (this is not HTTP status code)
 * @param {} textCommon common error messages across multiple processes.
 * The key is status id while the value is the error message.
 * @param {} textSpecific error messages object, including process name, status id, and error message.
 * @returns {} resultant error message
 */
export function generateErrorMessageApi(
  statusId: number,
  textCommon: Record<number, string>,
  textSpecific: TextsErrorSpecificApi
): string {
  // common error messages across multiple processes
  const messagesCommon = textCommon[statusId] || ''
  if (messagesCommon) return messagesCommon

  // error messages specific to each process
  const messagesSpecific = textSpecific.messages ? textSpecific.messages[statusId] : ''
  if (messagesSpecific) return messagesSpecific

  return ''
}

/**
 * extracts status ID from the response body of the error response from API
 * It uses the status ID inside the status message
 * if it exists (case of wrapper API of tekkin-detector).
 *
 * @param {} errorResponse error response body from API
 * @returns {} extracted status ID
 */
export function extractStatusId(errorResponse: ErrorResponse): number {
  // status_id is sometimes included in status_message (wrapper API for tekkin-detector)
  if (errorResponse.status_message.includes('status_id')) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      return JSON.parse(errorResponse.status_message).status_id as number
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return errorResponse.status_id
}

/**
 * generates error message for the given error object on API communication.
 * The error message is generated based on the following messages:
 * 1. custom status ID in API response body
 * 2. HTTP status code in API response header
 * 3. network error code given by axios
 * 4. generic error message specifying the process name
 *
 * @param {} err error object
 * @param {} processName name of API process
 * @param {} textMap text object for error messages
 * @returns {} resultant error message
 */
export function generateErrorMessage(err: unknown, processName: string, textMap: TextsError): string {
  if (axios.isAxiosError(err)) {
    // error from API (response.data exists)
    if (err.response?.data) {
      // 1. extract status ID from API response body
      const statusId = extractStatusId(err.response.data as ErrorResponse)
      // Detailed message for each process
      if (statusId) {
        const messages = generateErrorMessageApi(
          statusId,
          textMap.error_common.messages,
          textMap.error_api[processName]
        )
        if (messages) return messages
      }

      // 2. generic error message from HTTP status code
      const httpStatus = err.response.status
      if (httpStatus) {
        const textsHttpStatus = textMap.error_http_status
        const alertMessage = textsHttpStatus.messages[httpStatus] || ''
        if (alertMessage) return alertMessage
      }

      // 4. generic error message, but process is specified
      return textMap.error_message_process
    }

    // 3. Network errors
    if (err.code) {
      const errorCode = AXIOS_ERROR_CODE[err.code] || ''
      if (errorCode) return `${textMap.error_network}\n\n(error code: ${errorCode})`
    }
  }

  // 4. generic error message, but process is specified
  return textMap.error_message_process
}
