import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PointArray, Position } from 'interfaces/interfaces'

interface TemporalCommentState {
  // For picking new comment position
  movingCommentCartesianPosition?: Position

  // Whether the comment is being moved
  isMovingComment?: boolean

  // Position of the comment popup
  commentPopupPosition?: Position
}

const initialState: TemporalCommentState = {}

const temporalCommentSlice = createSlice({
  name: 'temporal_comment',
  initialState,
  reducers: {
    setMovingCommentCartesianPosition: (state, action: PayloadAction<Position | undefined>) => ({
      ...state,
      movingCommentCartesianPosition: action.payload,
    }),
    setIsMovingComment: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isMovingComment: action.payload,
    }),
    resetCommentPopupPosition: (state) => ({
      ...state,
      commentPopupPosition: undefined,
    }),

    // high-level reducer functions to update multiple states

    // function to be called when a point is selected for comment
    commentPointSelected: (state, action: PayloadAction<PointArray>) => {
      const updatedState = state
      // To prevent re-picking position
      if (!state.commentPopupPosition) {
        updatedState.commentPopupPosition = {
          x: action.payload[0],
          y: action.payload[1],
          z: action.payload[2],
        }
      }
      return updatedState
    },

    reset: () => initialState,
  },
})

export const {
  setMovingCommentCartesianPosition,
  commentPointSelected,
  resetCommentPopupPosition,
  setIsMovingComment,
  reset,
} = temporalCommentSlice.actions

export default temporalCommentSlice.reducer
