import axios from 'extends/axios'

import { API_PROCESS_MAP, EDITOR_SHAPE_TEMP_ID_PREFIX, ERROR_SHAPE_GROUP, MODAL_TYPES } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { Cylinder, ModalProps, ShapeGroup, Shapes, Torus } from 'interfaces/interfaces'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * Get all shape groups
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {ShapeGroup[] | null}
 */
export const getShapeGroups = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<ShapeGroup[] | null> => {
  const shapeGroups = await axios
    .get<{ results: ShapeGroup[] }>(`${PROJECTS_API_URL}/${project_id}/grouping-shape-types`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_SHAPE_GROUPS)
      return null
    })

  return shapeGroups
}

/**
 * Create a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {ShapeGroup | null}
 */
export const createShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  handleError: (err: unknown, processName: string) => void
): Promise<ShapeGroup | null> => {
  const shapeIds = shapeGroup.cylinder_ids?.length
    ? { cylinder_ids: shapeGroup.cylinder_ids }
    : { torus_ids: shapeGroup.torus_ids }

  const shapeGroups = await axios
    .post<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types`,
      {
        grouping_shape_type_name: shapeGroup.grouping_shape_type_name,
        color_palette_code: shapeGroup.color_palette_code,
        ...shapeIds,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_SHAPE_GROUP)
      return null
    })

  return shapeGroups
}

/**
 * Update a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group with updated values
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {ShapeGroup | null}
 */
export const updateShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  handleError: (err: unknown, processName: string) => void
): Promise<ShapeGroup | null> => {
  const shapeIds = shapeGroup.cylinder_ids?.length
    ? { cylinder_ids: shapeGroup.cylinder_ids }
    : { torus_ids: shapeGroup.torus_ids }

  const shapeGroups = await axios
    .patch<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types/${shapeGroup.grouping_shape_type_id || ''}`,
      {
        grouping_shape_type_name: shapeGroup.grouping_shape_type_name,
        color_palette_code: shapeGroup.color_palette_code,
        ...shapeIds,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.UPDATE_SHAPE_GROUP)
      return null
    })

  return shapeGroups
}

/**
 * Delete a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean}
 */
export const deleteShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types/${shapeGroup.grouping_shape_type_id || ''}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_SHAPE_GROUP)
      return false
    })

  return result
}

/**
 * Extract the shapes which are valid as a source of a new shape group
 * from the selected shapes and the existing shapes/shape groups.
 * If it is valid, return the extracted shapes (cylinders/tori).
 * Otherwise, throw errors.
 *
 * @param {Shapes} shapes existing shapes
 * @param {ShapeGroup[]} shapeGroups existing shape groups (types)
 * @param {string[]} selectedShapeIds IDs of selected shapes
 * @throws {Error} if the selected shapes are not valid for a new shape group
 * @returns {{ cylinders: Cylinder[]; tori: Torus[]; }} extracted shapes
 */
export const extractSelectedShapes = (
  shapes: Shapes,
  shapeGroups: ShapeGroup[],
  selectedShapeIds: string[]
): { cylinders: Cylinder[]; tori: Torus[] } => {
  const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
  const selectedCylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const selectedPlanes = shapes.planes.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const selectedTori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))

  // User select planes with no cylinders/tori.
  if (selectedPlanes.length) {
    throw new Error(ERROR_SHAPE_GROUP.PlaneSelected)
  }

  // There are multiple shape types such as cylinders and tori in the selected shapes.
  if (selectedCylinders.length && selectedTori.length) {
    throw new Error(ERROR_SHAPE_GROUP.DifferentShapesSelected)
  }

  const allGroupedShapeIds = shapeGroups.reduce(
    (ids: string[], group) => ids.concat(group.cylinder_ids || []).concat(group.torus_ids || []),
    []
  )
  // The selected shapes already belong to another type
  if (
    selectedCylinders.some((shape) => allGroupedShapeIds.includes(shape.shape_id)) ||
    selectedTori.some((shape) => allGroupedShapeIds.includes(shape.shape_id))
  ) {
    throw new Error(ERROR_SHAPE_GROUP.ShapesAlreadyUsed)
  }

  // The selected shapes contain different diameter
  if (
    (selectedCylinders.length && selectedCylinders.some((shape) => shape.diameter !== selectedCylinders[0].diameter)) ||
    (selectedTori.length && selectedTori.some((shape) => shape.minor_diameter !== selectedTori[0].minor_diameter))
  ) {
    throw new Error(ERROR_SHAPE_GROUP.DifferentDiameter)
  }

  return {
    cylinders: selectedCylinders,
    tori: selectedTori,
  }
}

/**
 * Show error modal for creating/editing a shape group
 *
 * @param {Error} error error object
 * @param {Record<string, string>} textModal text object to show in the modal
 * @param {string} text_confirm confirm text for the modal
 * @param {(modalProps: ModalProps) => void} showModal function to show the modal
 */
export function showErrorModalShapeGroup(
  error: Error,
  textModal: Record<string, string>,
  text_confirm: string,
  showModal: (modalProps: ModalProps) => void
): void {
  let errorMessage = textModal.message_generic_error
  switch (error.message) {
    case ERROR_SHAPE_GROUP.PlaneSelected:
      errorMessage = textModal.message_plane_selected
      break
    case ERROR_SHAPE_GROUP.DifferentShapesSelected:
      errorMessage = textModal.message_different_shapes_selected
      break
    case ERROR_SHAPE_GROUP.ShapesAlreadyUsed:
      errorMessage = textModal.message_shapes_already_used
      break
    case ERROR_SHAPE_GROUP.DifferentDiameter:
      errorMessage = textModal.message_different_diameter
      break
    default:
  }
  showModal({
    body: errorMessage,
    confirmText: text_confirm,
    modalType: MODAL_TYPES.ALERT,
  })
}
