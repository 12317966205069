import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Container,
  HStack,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import mixpanel from 'mixpanel-browser'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  MODAL_TYPES,
  MONTHLY_ADDITIONAL_PRICE,
  PRICING_PLANS,
  SELECTED_ORGANIZATION_COOKIE_NAME,
} from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { BillingRecord } from 'interfaces/interfaces'

import {
  activateAdditionalFeature,
  activateAdditionalFeatureWithStripeSub,
  getBillingRecords,
  getUserSubscription,
} from 'services/Usage'

dayjs.extend(localeData)

/**
 * format the year and month to a string
 *
 * @param {number} year year
 * @param {number} month month, which starts from 1 (January corresponds to 1, not 0)
 * @param {string} [locale='ja'] locale
 * @returns {string} formatted string for the specified year/month
 */
function formatMonth(year: number, month: number, locale = 'ja') {
  switch (locale) {
    case 'ja':
      return `${year}年${month}月`
    case 'en':
      return `${dayjs.months()[month - 1]} ${year}` // subtract 1 as month in dayjs starts from 0
    default:
      return `${year}/${month}`
  }
}

const UsageDashboard: FC = () => {
  const { t, i18n } = useTranslation(['usage_dashboard'])
  const navigate = useNavigate()
  const { user } = useAuth0()
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { userLoaded, subscriptionId, organizations, getAccessToken } = useContext(UserContext)
  const [cookies] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])

  const currentOrganization = organizations.find(
    (organization) => organization.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
  )
  const hasStripeSubscription = !!currentOrganization?.stripe_subscription_id

  const [shouldShowSubscribeButton, setShouldShowSubscribeButton] = useState(false)
  const [billingRecords, setBillingRecords] = useState<BillingRecord[]>([])

  const checkStatus = useCallback(async () => {
    if (user?.sub && subscriptionId) {
      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const subscription = await getUserSubscription(token, user.sub, subscriptionId, handleError)
      setShouldShowSubscribeButton(subscription?.subscription_detail.additional_feature_enabled === false)
    }

    return true
  }, [getAccessToken, handleError, subscriptionId, user])

  const fetchBillingRecords = useCallback(async () => {
    if (userLoaded) {
      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const records = await getBillingRecords(token, handleError)
      // check if the last record is for current month and year
      // dayjs month starts from 0
      const currentMonth = dayjs().month() + 1
      const currentYear = dayjs().year()
      if (records?.length && records[0].billing_year === currentYear && records[0].billing_month === currentMonth) {
        setBillingRecords(records || [])
      } else {
        // just show the default message
        handleError(null, '')
      }
    }

    return true
  }, [getAccessToken, handleError, userLoaded])

  useEffect(() => {
    void checkStatus()
  }, [checkStatus, subscriptionId, user])

  useEffect(() => {
    void fetchBillingRecords()
  }, [fetchBillingRecords, userLoaded])

  const showActivationConfirmation = () => {
    showModal({
      body: t('activate_modal.text', { ns: 'usage_dashboard', additional_price: MONTHLY_ADDITIONAL_PRICE }),
      confirmText: t('activate_modal.confirm', { ns: 'usage_dashboard' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const activateFeaturesApi = async () => {
          if (!user?.sub || !subscriptionId) {
            return false
          }

          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const result =
            hasStripeSubscription && currentOrganization
              ? await activateAdditionalFeatureWithStripeSub(token, currentOrganization.organization_id, handleError)
              : await activateAdditionalFeature(token, user.sub, subscriptionId, handleError)
          if (!result) {
            return false
          }

          // Refetch the data
          await Promise.all([checkStatus(), fetchBillingRecords()])
          return true
        }

        void activateFeaturesApi()

        // track with mixpanel
        mixpanel.track('Activate full feature', { 'Activation date': dayjs().format() })

        return true
      },
      title: t('activate_modal.title', { ns: 'usage_dashboard' }),
    })
  }

  const getAdditionalPrice = (record: BillingRecord) => {
    if (record.pricing_system === PRICING_PLANS.CONSTANT) {
      return '-'
    }
    if (record.pricing_system === PRICING_PLANS.PAY_ON_DEMAND) {
      const price = (record.billing_detail.price.additional || 0) * (1 + record.billing_detail.tax_rate)
      return price.toLocaleString('en-US')
    }
    return '-'
  }

  const getActivationStatus = (record: BillingRecord) => {
    if (record.pricing_system === PRICING_PLANS.CONSTANT) {
      return '-'
    }
    if (record.pricing_system === PRICING_PLANS.PAY_ON_DEMAND) {
      return record.billing_detail.price.additional
        ? t('yes', { ns: 'usage_dashboard' })
        : t('no', { ns: 'usage_dashboard' })
    }
    return '-'
  }

  if (userLoaded && !subscriptionId) {
    navigate('/')
  }

  if (!subscriptionId) {
    return null
  }
  const texts: Record<string, string> = t('pricing_systems', {
    ns: 'usage_dashboard',
    returnObjects: true,
  })

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>{t('usage', { ns: 'usage_dashboard' })}</PageHeading>
        <HStack w={{ sm: '100%', md: '50%' }} alignItems="flex-start">
          <VStack fontSize="sm" fontWeight="semibold" my={1.5} alignItems="stretch">
            <Text>
              {t('current_month', { ns: 'usage_dashboard' })}（
              {
                formatMonth(dayjs().year(), dayjs().month() + 1, i18n.language) // add 1 as month in dayjs starts from 0
              }
              )
            </Text>
            <HStack>
              <Text color="secondary.400" minW="92px">
                {t('plan', { ns: 'usage_dashboard' })}：
              </Text>
              <Text flex={1}>{billingRecords.length ? texts[billingRecords[0].pricing_system] || '-' : '-'}</Text>
            </HStack>
            <HStack>
              <Text color="secondary.400" minW="92px">
                {t('additional_fee', { ns: 'usage_dashboard' })}：
              </Text>
              <Text>
                {t('price_text', {
                  ns: 'usage_dashboard',
                  price: billingRecords.length ? getAdditionalPrice(billingRecords[0]) : '-',
                })}
              </Text>
            </HStack>
          </VStack>
          <Spacer />
          {shouldShowSubscribeButton && (
            <Button size="md" mt={10} mb={7} colorScheme="primary" onClick={showActivationConfirmation}>
              {t('activate_premium', { ns: 'usage_dashboard' })}
            </Button>
          )}
        </HStack>
        <Text color="secondary.400" fontSize="80%" my={5}>
          {t('tax_included', { ns: 'usage_dashboard' })}
        </Text>
        <TableContainer mb={10}>
          <Table w="100%" size="sm" className="striped">
            <Thead fontSize="sm">
              <Tr>
                <Th w="10%" textAlign="right">
                  {t('month', { ns: 'usage_dashboard' })}
                </Th>
                <Th textAlign="right">{t('plan', { ns: 'usage_dashboard' })}</Th>
                <Th textAlign="right">{t('used_premium', { ns: 'usage_dashboard' })}</Th>
                <Th textAlign="right">{t('additional_fee', { ns: 'usage_dashboard' })}［¥］</Th>
              </Tr>
            </Thead>
            <Tbody>
              {billingRecords.map((record) => (
                <Tr key={record.billing_id}>
                  <Td textAlign="right">{formatMonth(record.billing_year, record.billing_month, i18n.language)}</Td>
                  <Td textAlign="right">{texts[record.pricing_system] || '-'}</Td>
                  <Td textAlign="right">{getActivationStatus(record)}</Td>
                  <Td textAlign="right">{getAdditionalPrice(record)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  )
}

export default UsageDashboard
