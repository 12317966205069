/* eslint-disable react/no-array-index-key */

/* eslint-disable no-param-reassign */
import { FC, Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { User } from '@auth0/auth0-react'
import { Box, Image, Text, VStack, useToast } from '@chakra-ui/react'
import { ArcballControls, PerspectiveCamera, useContextBridge } from '@react-three/drei'
import { Canvas, RootState } from '@react-three/fiber'
import dayjs from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Provider, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState as ReduxRootState, store, useAppDispatch } from 'store/app'
import {
  AxesHelper,
  BufferGeometry,
  Cache,
  Material,
  Matrix4,
  MeshBasicMaterial,
  MeshPhongMaterial,
  NoToneMapping,
  PerspectiveCamera as PerspectiveCameraImpl,
  Points,
  PointsMaterial,
  Vector2,
  Vector3,
} from 'three'
import { ArcballControls as ArcballControlsImpl } from 'three-stdlib'
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader'

import LoadingAnimation from 'assets/imgs/loading-animation-black.gif'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import {
  API_PROCESS_MAP,
  EDITOR_BACKGROUND_COOKIE_NAME,
  EDITOR_CANVAS_CONTAINER_ID,
  EDITOR_DOUBLE_CLICK_INTERVAL,
  EDITOR_DOUBLE_CLICK_THRESHOLD,
  EDITOR_MASK_POINT_OPACITY,
  EDITOR_MEASURE_KEYS,
  EDITOR_MOUSE_DRAG_THRESHOLD,
  EDITOR_MOUSE_EVENT_DELAY,
  EDITOR_POINT_SIZE_INTERVAL,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_SHAPE_KEYS,
  EDITOR_TOOLS,
  EDITOR_TOOL_CURSOR_CLASSES,
  EDITOR_TOOL_KEYS,
  Z_INDEX,
} from 'config/constants'
import { EDITOR_DEFAULT_BACKGROUND, EDITOR_FRAME_COLORS, TOAST_CONFIG } from 'config/styles'

import {
  Comment,
  CuboidAnchor,
  FlatPosition,
  FocusedPoint,
  IFCFile,
  PointArray,
  ShapeKey,
  TextsError,
  Timeout,
} from 'interfaces/interfaces'

import { extractAnchorPointsListFromShapeDistances } from 'services/Editor'
import { generateErrorMessage } from 'services/ErrorHandler'
import { convertIFCToMeshes, getIFCFiles } from 'services/IFC'
import { getSignedUrlForGetDownSampledFile } from 'services/Pcd'
import { getSelectedPointOnPCD } from 'services/PointPicker'
import { generateCuboidUniforms, getCameraDistance } from 'services/Points'

import { commentPointSelected, setMovingCommentCartesianPosition } from '../store/temporalComment'
import AnchorFrames, { CuboidAnchorFrames } from './AnchorFrames'
import CameraAnimator from './CameraAnimator'
import CameraCuboidAnimator from './CameraCuboidAnimator'
import CameraFocusPoint from './CameraFocusPointIcon'
import Comments from './Comments/Comments'
import CommentCuboidFrame from './Cuboid/CommentCuboidFrame'
import CuboidFrame from './Cuboid/CuboidFrame'
import { CylinderMesh } from './CylinderMesh'
import { PlaneMesh } from './PlaneMesh'
import { SpacerAnnotationMesh } from './SpacerAnnotationMesh'
import { TorusMesh } from './TorusMesh'

Cache.enabled = true

const MainCanvas: FC<{
  user: User | undefined
  windowSize: {
    width: number
    height: number
  }
  cuboidAnchor?: CuboidAnchor
  addAnchor: (point: PointArray) => void
  addCuboidAnchor: (point: Vector3) => void
  addSpacerAnnotationAnchor: (point: PointArray) => void
  setCuboidAnchor: (anchor?: CuboidAnchor) => void
  updateAnchorPoint: (pointInfo: FocusedPoint, point: PointArray) => void
  updateDistanceAnchorPoint: (pointInfo: FocusedPoint, point: PointArray) => void
  updateSpacerAnnotationAnchorPoint: (pointInfo: FocusedPoint, point: PointArray) => void
  setMovingPrefixedPosition: (position: string) => void
  setIFCFiles: (files: IFCFile[]) => void
  setIFCGeometries: (geometries: BufferGeometry[][]) => void
  movingPrefixedPosition: string
  cameraFocusPoint?: Vector3
  setCameraFocusPoint: (point?: Vector3) => void
}> = ({
  user,
  windowSize,
  cuboidAnchor,
  movingPrefixedPosition,
  addAnchor,
  addSpacerAnnotationAnchor,
  addCuboidAnchor,
  setCuboidAnchor,
  updateAnchorPoint,
  updateDistanceAnchorPoint,
  updateSpacerAnnotationAnchorPoint,
  setMovingPrefixedPosition,
  setIFCFiles,
  setIFCGeometries,
  cameraFocusPoint,
  setCameraFocusPoint,
}) => {
  const { userLoaded, getAccessToken } = useContext(UserContext)
  const {
    addDistanceAnchor,
    anchors,
    cuboid,
    changeIsDragging,
    changeProcessingAnchor,
    changeSelectedPoint,
    changeIsMouseDown,
    updateCanvasRenderers,
    distanceAnchors,
    overlaps,
    hoveredPoint,
    IFCFiles,
    IFCGeometries,
    isDragging,
    processingAnchor,
    project,
    selectedIFCGeometryIndex,
    selectedPoint,
    selectedTool,
    selectedShapeIds,
    selectedInspectionItem,
    selectedSpacerInspectionItem,
    shapes,
    shapesDistances,
    shapesPreviewDistances,
    maskRegions,
    maskRegionsOutsideVisible,
  } = useContext(EditorContext)
  const { showModal, showErrorModal, handleError } = useContext(GlobalModalContext)
  const pointSize = useSelector((state: ReduxRootState) => state.editor.pointSize)
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  const toast = useToast()
  const dispatch = useAppDispatch()

  const [cookies] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])
  const backgroundColor =
    (cookies as Record<string, string>)[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND

  const { spacerAnnotations, spacerAnnotationAnchors } = useSelector((state: ReduxRootState) => state.spacerAnnotation)
  const { isPointCloudVisible, isToolProcessing, baseDiameter } = useSelector((state: ReduxRootState) => state.editor)
  const { isMovingComment } = useSelector((state: ReduxRootState) => state.temporal_comment)

  const [startDraggingPoint, setStartDraggingPoint] = useState<FlatPosition | null>(null)

  //* canvas制御用
  const cameraRef = useRef<PerspectiveCameraImpl>(null)
  const arcballControlsRef = useRef<ArcballControlsImpl>(null)
  const ifcGroupRef = useRef<THREE.Group>(null)
  const [isMouseMoving, setIsMouseMoving] = useState(false)

  //* 点群データ制御用
  const [pointCloud, setPointCloud] = useState<Points>()
  const [pcdMaterial, setPcdMaterial] = useState<PointsMaterial>()
  const [pointsLoadingText, setPointCloudLoadingText] = useState<string>('Loading...')
  const [maskModelMatrices, setMaskModelMatrices] = useState<
    {
      modelMatrix: Matrix4
      min: Vector3
      max: Vector3
      unsaved: boolean
      visible: boolean
    }[]
  >([])
  const [allIFCMeshes, setAllIFCMeshes] = useState<
    {
      meshArray: { combinedGeometry: BufferGeometry; mat: MeshPhongMaterial }[]
      transMeshArray: { combinedGeometryTransp: BufferGeometry; matTransp: MeshPhongMaterial }[]
    }[]
  >([])

  //* 初回ローディング用
  const [initCompleted, setInitCompleted] = useState(false)
  // If a project has no down sampled file, the API will raise an error.
  // I need to store the error message (if any) and wait for project being loaded.
  // After that, if project?.down_sampled_file.name exists,
  // show the error message. Otherwise, do not show.
  // This is to make the parallel calling without waiting for project.
  const [downloadErrorMessage, setDownloadErrorMessage] = useState('')

  //* Smooth mouse movement
  const [mouseTimeout, setMouseTimeout] = useState<Timeout>()

  //* Comments
  const [selectedComment, setSelectedComment] = useState<Comment>()

  //* Consecutive clicks
  const [clickEvent, setClickEvent] = useState<{
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
    timestamp: Date
  } | null>(null)

  const getCuboidAnchorFrameColor = () => {
    if (selectedTool === EDITOR_TOOLS.COMMENT_CUBOID) {
      return EDITOR_FRAME_COLORS.comments
    }
    if (selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) {
      return EDITOR_FRAME_COLORS.pcdTrim
    }
    return undefined
  }

  useEffect(() => {
    setMaskModelMatrices(maskRegions.map(generateCuboidUniforms))
  }, [maskRegions])

  // apply point size to the rendered pcd
  useEffect(() => {
    if (!pcdMaterial || !pointCloud) {
      return
    }

    const material = pointCloud?.material as PointsMaterial
    if (material?.size !== pointSize) {
      const newSize = Math.max(pointSize, EDITOR_POINT_SIZE_INTERVAL)

      // Update both the saved and current material
      material.size = newSize
      pcdMaterial.size = newSize
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointSize])

  useEffect(() => {
    if (movingPrefixedPosition) {
      arcballControlsRef.current?.reset()
      let target = pointCloud?.geometry.boundingSphere?.center || new Vector3()
      if (movingPrefixedPosition.startsWith('CUBE')) {
        target = cuboid?.center ? new Vector3(...cuboid.center) : new Vector3()
      }
      arcballControlsRef.current?.setTarget(target.x, target.y, target.z)
    }
  }, [cuboid, movingPrefixedPosition, pointCloud])

  const onMouseDown = (mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    changeIsMouseDown(true)
    setIsMouseMoving(false)
    setStartDraggingPoint({
      x: mouseEvent?.clientX || 0,
      y: mouseEvent?.clientY || 0,
    })

    if (hoveredPoint) {
      changeIsDragging(true)
    }
  }

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLElement).attributes.getNamedItem('aria-controls')?.value === EDITOR_CANVAS_CONTAINER_ID) {
      return
    }

    changeIsMouseDown(false)

    // double click if the current click is done close enough to the previous click
    // both spatially and temporally
    const isDoubleClicked =
      clickEvent != null &&
      Math.abs(clickEvent.event.clientX - e.clientX) < EDITOR_DOUBLE_CLICK_THRESHOLD &&
      Math.abs(clickEvent.event.clientY - e.clientY) < EDITOR_DOUBLE_CLICK_THRESHOLD &&
      Math.abs(clickEvent.timestamp.valueOf() - Date.now()) < EDITOR_DOUBLE_CLICK_INTERVAL

    if (isDoubleClicked) {
      // initialize click event state
      setClickEvent(null)

      if (selectedTool === EDITOR_TOOLS.MOVE) {
        changeCameraFocus(e)

        // track with mixpanel
        mixpanel.track('Fix camera rotation center', {
          'Inspection area ID': project?.project_id,
        })
      } else {
        toast({
          ...TOAST_CONFIG,
          status: 'info',
          position: 'bottom',
          variant: 'left-accent',
          isClosable: false,
          title: t('main_canvas.toasts.warning_double_click', { ns: 'projects' }),
        })
      }
    } else {
      // set click event state
      setClickEvent({ event: e, timestamp: new Date() })
    }

    changeIsDragging(false)

    const currentPoint = {
      x: e?.clientX,
      y: e?.clientY,
    }
    const dragDistance = isMouseMoving
      ? (startDraggingPoint!.x - currentPoint.x) ** 2 + (startDraggingPoint!.y - currentPoint.y) ** 2
      : 0
    if (!isMouseMoving || dragDistance < EDITOR_MOUSE_DRAG_THRESHOLD) {
      setIsMouseMoving(false)

      if (!isMovingComment && selectedTool === EDITOR_TOOLS.MOVE) {
        changeSelectedPoint(hoveredPoint)
      } else if (
        isMovingComment ||
        selectedTool === EDITOR_TOOLS.COMMENT ||
        selectedTool === EDITOR_TOOLS.DISTANCE ||
        selectedTool === EDITOR_TOOLS.SPACER_ANNOTATION ||
        selectedTool === EDITOR_TOOLS.CYLINDER ||
        selectedTool === EDITOR_TOOLS.PLANE ||
        selectedTool === EDITOR_TOOLS.PLANE_VIRTUAL ||
        selectedTool === EDITOR_TOOLS.TORUS ||
        ((!cuboidAnchor || cuboidAnchor.points.length < EDITOR_REQUIRED_ANCHORS.cuboid) &&
          (selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
            selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
            selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ||
            selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID))
      ) {
        if (!isPointCloudVisible) return
        const anchorPoint = getSelectedPointOnPCD(
          document.getElementById('canvasBox'),
          cameraRef.current,
          pointCloud,
          maskModelMatrices,
          e,
          undefined,
          maskRegionsOutsideVisible
        )

        if (!anchorPoint) {
          return
        }

        if (isMovingComment && selectedTool === EDITOR_TOOLS.COMMENT) {
          dispatch(setMovingCommentCartesianPosition({ x: anchorPoint[0], y: anchorPoint[1], z: anchorPoint[2] }))
        } else if (
          selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ||
          selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
          selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
          (selectedTool === EDITOR_TOOLS.COMMENT_CUBOID &&
            ((!isMovingComment && !selectedComment) || (isMovingComment && !cuboid)))
        ) {
          if (
            !cuboidAnchor?.points.length ||
            !new Vector3(...anchorPoint).equals(cuboidAnchor.points[cuboidAnchor.points.length - 1])
          ) {
            addCuboidAnchor(new Vector3(...anchorPoint))
          }
        } else if (selectedTool === EDITOR_TOOLS.DISTANCE) {
          addDistanceAnchor(
            anchorPoint,
            new Vector2(e.clientX, e.clientY),
            getCameraDistance(new Vector3(...anchorPoint), cameraRef.current),
            dayjs().unix()
          )
        } else if (selectedTool === EDITOR_TOOLS.COMMENT) {
          dispatch(commentPointSelected(anchorPoint))
        } else if (selectedTool === EDITOR_TOOLS.SPACER_ANNOTATION) {
          addSpacerAnnotationAnchor(anchorPoint)
        } else {
          addAnchor(anchorPoint)
        }
      }
    }
  }

  const onMoveMouse = (
    mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    touchEvent?: React.TouchEvent<HTMLDivElement>
  ) => {
    if (mouseTimeout) {
      clearTimeout(mouseTimeout)
    }

    const timeout = setTimeout(() => {
      setIsMouseMoving(true)

      const noIntersect = (!isDragging && !isToolProcessing) || !isPointCloudVisible
      if (noIntersect) return

      const anchorPoint = getSelectedPointOnPCD(
        document.getElementById('canvasBox'),
        cameraRef.current,
        pointCloud,
        maskModelMatrices,
        mouseEvent,
        touchEvent,
        maskRegionsOutsideVisible
      )
      if (!anchorPoint) return

      if (isDragging && hoveredPoint) {
        if (hoveredPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE) {
          updateDistanceAnchorPoint(hoveredPoint, anchorPoint)
        } else if (hoveredPoint.shapeKey === EDITOR_MEASURE_KEYS.SPACER_ANNOTATION) {
          updateSpacerAnnotationAnchorPoint(hoveredPoint, anchorPoint)
        } else {
          updateAnchorPoint(hoveredPoint, anchorPoint)
        }
      } else {
        changeProcessingAnchor(anchorPoint)
      }
    }, EDITOR_MOUSE_EVENT_DELAY)

    setMouseTimeout(timeout)
  }

  const changeCameraFocus = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isPointCloudVisible) return
      const anchorPoint = getSelectedPointOnPCD(
        document.getElementById('canvasBox'),
        cameraRef.current,
        pointCloud,
        maskModelMatrices,
        e,
        undefined,
        maskRegionsOutsideVisible
      )
      if (cameraRef.current && arcballControlsRef.current && anchorPoint) {
        arcballControlsRef.current.cursorZoom = false
        arcballControlsRef.current.enablePan = false
        setCameraFocusPoint(new Vector3(...anchorPoint))
      }
    },
    [pointCloud, maskModelMatrices, isPointCloudVisible, maskRegionsOutsideVisible, setCameraFocusPoint]
  )

  // enable zoom and pan when camera focus point is set
  useEffect(() => {
    if (!cameraFocusPoint && arcballControlsRef.current) {
      arcballControlsRef.current.cursorZoom = true
      arcballControlsRef.current.enablePan = true
    }
  }, [cameraFocusPoint])

  // download and render point cloud
  useEffect(() => {
    void (async () => {
      if (!initCompleted && project_id && !pointCloud) {
        let loadPoints: Points<BufferGeometry, Material | Material[]> | undefined

        if (userLoaded && !Cache.get(project_id)) {
          const token = await getAccessToken()
          if (!token) {
            setInitCompleted(true)
          }

          const url = await getSignedUrlForGetDownSampledFile(
            token,
            project_id,
            (err: unknown, processName: string) => {
              // override handleError function
              const textMap: TextsError = t('error_apis', {
                returnObjects: true,
                processName,
                ns: 'error_message',
              })
              const errorMessage = generateErrorMessage(err, processName, textMap)

              setDownloadErrorMessage(errorMessage)
            }
          )
          if (!url) {
            setInitCompleted(true)
            return false
          }

          // download point cloud
          try {
            const loader = new PCDLoader()
            loadPoints = await loader.loadAsync(url, (e) => {
              const loadingPercent = (e.loaded / e.total) * 100

              if (loadingPercent === 100) {
                setPointCloudLoadingText(`Loading...`)
              } else {
                setPointCloudLoadingText(`Download...${Math.floor((loadingPercent * 10) / 10)}%`)
              }
            })

            // cache point cloud
            // TODO: we should remove the cache at some timing. Maybe when logout?
            Cache.add(project_id, loadPoints)
            setPointCloud(loadPoints)

            setInitCompleted(true)
          } catch (err) {
            setInitCompleted(true)
            const textMap: TextsError = t('error_apis', {
              returnObjects: true,
              processName: API_PROCESS_MAP.GET_DOWN_SAMPLED_FILE,
              ns: 'error_message',
            })
            const errorMessage = generateErrorMessage(err, API_PROCESS_MAP.GET_DOWN_SAMPLED_FILE, textMap)

            setDownloadErrorMessage(errorMessage)
            return {}
          }
        }

        // use cached point cloud if there is one
        if (Cache.get(project_id)) {
          loadPoints = Cache.get(project_id) as Points

          setPointCloud(loadPoints)
          setInitCompleted(true)
        }

        if (loadPoints) {
          // Save material for later use
          const mat = loadPoints.material as PointsMaterial
          mat.transparent = true
          setPcdMaterial(mat.clone())
        }
      }
      return {}
    })()
  }, [t, getAccessToken, handleError, initCompleted, pointCloud, project_id, userLoaded])

  // Show error message if any when project has been loaded
  useEffect(() => {
    if (downloadErrorMessage && project?.down_sampled_file.name) {
      showErrorModal(downloadErrorMessage)
    }
  }, [project, downloadErrorMessage, showErrorModal])

  // load IFC files and convert them to meshes
  useEffect(() => {
    const fetchData = async () => {
      if (!project?.project_group_id) {
        return false
      }

      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const results = await getIFCFiles(token, project.project_group_id, handleError)
      if (results) {
        setIFCFiles(results)
        const meshes = await Promise.all(results.map((ifcFile) => convertIFCToMeshes(ifcFile.original_file_url || '')))
        setAllIFCMeshes(meshes)
        setIFCGeometries(meshes.map((mesh) => mesh.geometries))
      }

      return true
    }

    void fetchData()
  }, [getAccessToken, project, setIFCFiles, setIFCGeometries, handleError])

  // update canvas renderers for masked regions
  useEffect(() => {
    if (!pointCloud || !pcdMaterial) {
      return
    }

    // Always start with material that has not have its shaders already modified.
    const newMaterial = pcdMaterial.clone()
    const uniformsData: {
      modelMatrix: Matrix4
      min: Vector3
      max: Vector3
      unsaved: boolean
      visible: boolean
    }[] = []

    // Add the unsaved cuboid we are creating/editing to the list of mask regions
    if (cuboid) {
      uniformsData.push({ ...generateCuboidUniforms(cuboid), unsaved: true })
    }

    // Add the saved mask regions
    // If there are no mask, we need to reset the PCD material.
    // On fresh load this would not be a problem, but if the last
    // region was deleted, the previous mask region will still be
    // applied so we need to reset it.
    // In the case of adding a new mask region, the existing mask regions should not be applied.
    // This is to make the new mask region the only one have full opacity.
    if (maskRegions.length) {
      uniformsData.push(...maskRegions.map(generateCuboidUniforms))
    }

    if (uniformsData.length) {
      newMaterial.onBeforeCompile = (shader) => {
        // Pass the model matrix to the shader as uniforms
        const uniforms = {
          maskRegion: { value: uniformsData },
        }

        shader.uniforms = { ...(shader.uniforms || {}), ...uniforms }

        // Get the position of the fragment in world space by appending it to the beginning of the shader.
        // We still need the original shader for other parts of the rendering.
        shader.vertexShader = shader.vertexShader.replace(
          `void main() {`,
          `
            varying vec4 fragPosition;

            void main() {
                fragPosition = modelMatrix * vec4(position, 1.0);
            `
        )

        // Add a check if the fragment is outside the masks and if so, set a lower opacity
        // or discard it altogether.
        // Replace the original diffuseColor to use the new opacity.
        // The original shader is retained as we still need it to render it as-is,
        // just with lower opacity or none as necessary.
        shader.fragmentShader = shader.fragmentShader
          .replace(
            `void main() {`,
            `
                struct Region {
                  mat4 modelMatrix;
                  vec3 min;
                  vec3 max;
                  bool unsaved;
                  bool visible;
                };

                uniform Region maskRegion[${uniformsData.length}];
                varying vec4 fragPosition;
                bool isOutsideVisible = ${maskRegionsOutsideVisible ? 'true' : 'false'};

                bool checkWithin(Region region)
                {
                  // we need fragment position localized to the mask region
                  vec4 localPos = region.modelMatrix * fragPosition;

                  // Check if the fragment is inside the cube in its local space
                  return all(greaterThanEqual(localPos.xyz, region.min)) && all(lessThanEqual(localPos.xyz, region.max));
                }

                bool isOutOfAllSavedMasks() {
                  for(int i=0; i<maskRegion.length(); i++) {
                    if (!maskRegion[i].unsaved && checkWithin(maskRegion[i])) {
                      // inside of any saved mask region
                      return false;
                    }
                  }
                  // outside of all the mask regions
                  return true;
                }

                int opacityOptionFromSavedMasks(int opacityDefault){
                  int opacityOption = opacityDefault;

                  if (isOutOfAllSavedMasks()) {
                    if (isOutsideVisible) {
                      opacityOption = 0;
                    }
                  }
                  // iterate over saved masks
                  else{
                    for (int i=0; i<maskRegion.length(); i++) {
                      // if the fragment is inside the saved mask region
                      if (checkWithin(maskRegion[i]) && !maskRegion[i].unsaved && maskRegion[i].visible) {
                        opacityOption = 0;
                      }
                    }
                  }

                  return opacityOption;
                }


                int opacityOptionFromUnSavedMasks(int opacityDefault){
                  int opacityOption = opacityDefault;

                  // iterate over unsaved masks
                  for (int i=0; i<maskRegion.length(); i++) {
                    // if the fragment is outside of the unsaved cuboid region
                    if (!checkWithin(maskRegion[i]) && maskRegion[i].unsaved && opacityOption == 0) {
                      // visible point outside of unsaved cuboid should be rendered with partial opacity
                      opacityOption = 1;
                    }
                  }

                  return opacityOption;
                }

                void main() {
                  // flag to specify opacity option
                  // 0: full opacity, 1: intermediate opacity, 2: discard
                  int opacityOption = 2;

                  // determine opacity option
                  if (maskRegion.length() == 1 && maskRegion[0].unsaved) {
                    // there is only one unsaved mask
                    opacityOption = 0;
                  }
                  else{
                    opacityOption = opacityOptionFromSavedMasks(opacityOption);
                  }
                  opacityOption = opacityOptionFromUnSavedMasks(opacityOption);

                  // update opacity
                  float updatedOpacity = opacity;
                  if (opacityOption ==  1) {
                    updatedOpacity = ${EDITOR_MASK_POINT_OPACITY};
                  } else if (opacityOption == 2) {
                    discard;
                    return;
                  }                               `
          )
          .replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            'vec4 diffuseColor = vec4( diffuse, updatedOpacity );'
          )
      }
    }

    // Shaders are compiled but when mask regions change, we need to recompile them.
    // Use IDs of the mask regions as a cache key.
    // If we are editing a cuboid, add that as well. Only need to do it once. If the cuboid changes,
    // uniforms will continue to be updated without needing to recompile the shader.
    newMaterial.needsUpdate = true
    newMaterial.customProgramCacheKey = () =>
      `${maskRegions
        // limit to only regions where uniforms are available.
        // sometime the mesh is not yet rendered and the uniforms can't be calculated.
        .slice(0, uniformsData.length - 1)
        .map((region) => region.masking_region_id)
        .concat([
          maskRegionsOutsideVisible ? 'show-outside' : '',
          `${uniformsData.length}`,
          cuboid ? 'editing-cuboid' : '',
          selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ? 'pcd-trim' : '',
        ])
        .join(',')}`

    pointCloud.material = newMaterial
  }, [selectedTool, maskRegions, pointCloud, pcdMaterial, cuboid, maskRegionsOutsideVisible])

  // Trick to prevent camera flicking on the first load
  useEffect(() => {
    if (pointCloud) {
      arcballControlsRef.current?.setTarget(0, 0, 0)
      arcballControlsRef.current?.reset()
    }
  }, [pointCloud])

  // transform IFC meshes to align with the other 3D objects
  useEffect(() => {
    if (allIFCMeshes.length && project?.transform_public_coordinate && ifcGroupRef.current) {
      // transpose the matrix to match the three.js convention
      const transformFromPcdToIfcs = new Matrix4().fromArray(project?.transform_public_coordinate).transpose()
      ifcGroupRef.current.applyMatrix4(transformFromPcdToIfcs.invert())
    }
  }, [project, allIFCMeshes])

  useEffect(
    () => () => {
      if (pcdMaterial && pointCloud) {
        const newMaterial = pcdMaterial.clone()
        newMaterial.needsUpdate = true
        pointCloud.material = newMaterial
      }
    },
    [pcdMaterial, pointCloud]
  )

  //* canvas内でcontextを使用するための設定
  const ContextBridge = useContextBridge(UserContext, ProjectsContext, EditorContext)

  const filteredSpacerAnchors = spacerAnnotationAnchors.filter((a) => !a.deleted)
  const canvasClassName =
    ((selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
      selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
      selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ||
      selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) &&
      cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid) ||
    (filteredSpacerAnchors.length === 1 &&
      filteredSpacerAnchors[0].points.length === EDITOR_REQUIRED_ANCHORS.spacerAnnotation)
      ? `editor-main-canvas ${EDITOR_TOOL_CURSOR_CLASSES[EDITOR_TOOLS.MOVE] || ''}`
      : `editor-main-canvas ${EDITOR_TOOL_CURSOR_CLASSES[selectedTool] || ''}`

  const drawAnchors = (key: ShapeKey) => {
    let distanceKey = EDITOR_MEASURE_KEYS.DETECTED_CYLINDERS_DISTANCE
    let tool = EDITOR_TOOLS.CYLINDER
    if (key === EDITOR_SHAPE_KEYS.TORI) {
      distanceKey = EDITOR_MEASURE_KEYS.DETECTED_TORI_DISTANCE
      tool = EDITOR_TOOLS.TORUS
    }
    if (key === EDITOR_SHAPE_KEYS.PLANES) {
      distanceKey = EDITOR_MEASURE_KEYS.DETECTED_PLANES_DISTANCE
      tool = EDITOR_TOOLS.PLANE
    }

    return (
      <>
        {!!shapesPreviewDistances && (
          <AnchorFrames
            anchors={extractAnchorPointsListFromShapeDistances(shapesPreviewDistances[key])}
            arcballControls={arcballControlsRef.current}
            shapeKey={distanceKey}
          />
        )}
        {!shapesPreviewDistances && (
          <>
            <AnchorFrames
              anchors={extractAnchorPointsListFromShapeDistances(shapesDistances.distances[key])}
              arcballControls={arcballControlsRef.current}
              shapeKey={distanceKey}
            />
            <AnchorFrames
              anchors={anchors[key]}
              processingAnchor={selectedTool === tool ? processingAnchor : undefined}
              selectedPoint={selectedTool === tool ? selectedPoint : undefined}
              arcballControls={arcballControlsRef.current}
              shapeKey={key}
            />
          </>
        )}
      </>
    )
  }

  const components = (
    <Box
      // please keep this id for moving comment position feature
      id={EDITOR_CANVAS_CONTAINER_ID}
      h="100%"
      onMouseDown={onMouseDown}
      onMouseMove={onMoveMouse}
      onTouchMove={(e) => onMoveMouse(undefined, e)}
      onMouseUp={onMouseUp}
      userSelect="none"
    >
      <Canvas
        className={canvasClassName}
        style={{ width: '100svw', height: '100svh' }}
        onCreated={(state: RootState) => {
          // eslint-disable-next-line no-param-reassign
          state.gl.toneMapping = NoToneMapping
          // eslint-disable-next-line no-param-reassign
          state.gl.outputEncoding = -1
          updateCanvasRenderers({ gl: state.gl, scene: state.scene, camera: state.camera })
        }}
      >
        <ContextBridge>
          <Provider store={store}>
            {/* 点群データ */}
            {pointCloud && isPointCloudVisible && (
              <group>
                <primitive object={pointCloud} />
              </group>
            )}
            {/* IFC */}
            <group ref={ifcGroupRef}>
              {allIFCMeshes.map(
                (allMeshes, groupIndex) =>
                  !IFCFiles[groupIndex].invisible && (
                    <Fragment key={groupIndex}>
                      {allMeshes.meshArray.map(({ combinedGeometry, mat }, index) => (
                        <mesh args={[combinedGeometry, mat]} key={index} />
                      ))}
                      {allMeshes.transMeshArray.map(({ combinedGeometryTransp, matTransp }, index) => (
                        <mesh args={[combinedGeometryTransp, matTransp]} key={index} />
                      ))}
                    </Fragment>
                  )
              )}
              {selectedIFCGeometryIndex &&
                selectedIFCGeometryIndex.fileIndex < IFCFiles.length &&
                !IFCFiles[selectedIFCGeometryIndex.fileIndex].invisible && (
                  <mesh
                    args={[
                      IFCGeometries[selectedIFCGeometryIndex.fileIndex][selectedIFCGeometryIndex.index],
                      new MeshBasicMaterial({
                        color: 'yellow',
                        depthTest: false,
                        depthWrite: false,
                        transparent: true,
                      }),
                    ]}
                  />
                )}
            </group>
            {/* 原点補助軸 */}
            <primitive
              object={new AxesHelper(0.2)}
              position={[
                (pointCloud?.geometry?.boundingSphere?.center.x || 0) -
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
                (pointCloud?.geometry?.boundingSphere?.center.y || 0) +
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
                (pointCloud?.geometry?.boundingSphere?.center.z || 0) -
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
              ]}
            />
            {/* Cylinders */}
            {!selectedSpacerInspectionItem &&
              shapes?.cylinders.map((shape) => {
                if (!shapesPreviewDistances || selectedShapeIds.includes(shape.shape_id)) {
                  return (
                    <CylinderMesh
                      cameraRef={cameraRef}
                      key={shape.shape_id}
                      cylinder={shape}
                      invisible={shape.invisible || shape.deleted}
                      arcballControls={arcballControlsRef.current}
                    />
                  )
                }
                return null
              })}
            {drawAnchors(EDITOR_SHAPE_KEYS.CYLINDERS)}
            {/* Planes */}
            {!selectedSpacerInspectionItem &&
              shapes?.planes.map((shape) => {
                if (!shapesPreviewDistances || selectedShapeIds.includes(shape.shape_id)) {
                  return (
                    <PlaneMesh
                      cameraRef={cameraRef}
                      key={shape.shape_id}
                      plane={shape}
                      invisible={shape.invisible || shape.deleted}
                      arcballControls={arcballControlsRef.current}
                    />
                  )
                }
                return null
              })}
            {drawAnchors(EDITOR_SHAPE_KEYS.PLANES)}
            {/* Tori */}
            {!selectedSpacerInspectionItem &&
              shapes?.tori.map((shape) => {
                if (!shapesPreviewDistances || selectedShapeIds.includes(shape.shape_id)) {
                  return (
                    <TorusMesh
                      cameraRef={cameraRef}
                      key={shape.shape_id}
                      torus={shape}
                      invisible={shape.invisible || shape.deleted}
                      arcballControls={arcballControlsRef.current}
                    />
                  )
                }
                return null
              })}
            {drawAnchors(EDITOR_SHAPE_KEYS.TORI)}
            {/* Distance */}
            {!shapesPreviewDistances && (
              <AnchorFrames
                anchors={distanceAnchors}
                processingAnchor={selectedTool === EDITOR_TOOLS.DISTANCE ? processingAnchor : undefined}
                selectedPoint={selectedPoint}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.DISTANCE}
              />
            )}
            {/* Overlap */}
            {!shapesPreviewDistances && (
              <AnchorFrames
                anchors={overlaps.map((overlap) => ({
                  ...overlap,
                  points: overlap.positions_for_distance,
                  distance: overlap.overlap_length,
                  center: overlap.center,
                }))}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.OVERLAP}
              />
            )}
            {/* Spacer Annotation */}
            {!shapesPreviewDistances && (
              <AnchorFrames
                anchors={spacerAnnotationAnchors}
                processingAnchor={selectedTool === EDITOR_TOOLS.SPACER_ANNOTATION ? processingAnchor : undefined}
                selectedPoint={selectedTool === EDITOR_TOOLS.SPACER_ANNOTATION ? selectedPoint : undefined}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.SPACER_ANNOTATION}
              />
            )}
            {!selectedInspectionItem &&
              spacerAnnotations.map((shape, index) => {
                if (!shapesPreviewDistances || selectedShapeIds.includes(shape.shape_id)) {
                  return (
                    <SpacerAnnotationMesh
                      cameraRef={cameraRef}
                      key={shape.shape_id}
                      spacerAnnotation={shape}
                      spacerIndex={index}
                      arcballControls={arcballControlsRef.current}
                      invisible={shape.invisible || shape.deleted}
                    />
                  )
                }
                return null
              })}
            {/* Cuboid tool */}
            {(!cuboidAnchor || cuboidAnchor.points.length < EDITOR_REQUIRED_ANCHORS.cuboid) &&
              (selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
                selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
                selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ||
                selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) && (
                <CuboidAnchorFrames
                  anchor={cuboidAnchor}
                  processingAnchor={processingAnchor}
                  arcballControls={arcballControlsRef.current}
                  shapeKey={EDITOR_TOOL_KEYS[selectedTool]}
                  baseDiameter={baseDiameter || 0}
                  frameColor={getCuboidAnchorFrameColor()}
                />
              )}
            <CuboidFrame
              cuboidAnchor={cuboidAnchor}
              cuboid={isMovingComment ? cuboid : undefined}
              baseDiameter={baseDiameter || 0}
              maxSize={(pointCloud?.geometry.boundingSphere?.radius || 1) * 2}
            />
            {selectedComment && cuboid && !isMovingComment && <CommentCuboidFrame cuboid={cuboid} />}
            {cameraFocusPoint && <CameraFocusPoint position={cameraFocusPoint} />}
            {/* Prefixed positions */}
            <CameraCuboidAnimator
              distance={pointCloud?.geometry?.boundingSphere?.radius || 1}
              center={pointCloud?.geometry.boundingSphere?.center}
              position={movingPrefixedPosition}
              finish={() => setMovingPrefixedPosition('')}
            />
            {/* Camera focus */}
            {cameraFocusPoint && (
              <CameraAnimator target={cameraFocusPoint} arcballControls={arcballControlsRef.current} />
            )}
            {/* Comments */}
            {pointCloud && !shapesPreviewDistances && (
              <Comments
                user={user}
                project_id={project_id}
                project_group_id={project?.project_group_id}
                selectedComment={selectedComment}
                setSelectedComment={setSelectedComment}
                showModal={showModal}
                handleError={handleError}
                setCuboidAnchor={setCuboidAnchor}
              />
            )}
            {pointCloud && (
              <>
                {/* Camera */}
                <PerspectiveCamera
                  makeDefault
                  ref={cameraRef}
                  fov={90}
                  aspect={windowSize.width / windowSize.height}
                  near={0.01}
                  far={10000}
                  position={[
                    (pointCloud.geometry?.boundingSphere?.center.x || 0) +
                      (pointCloud.geometry?.boundingSphere?.radius || 1),
                    (pointCloud.geometry?.boundingSphere?.center.y || 0) +
                      (pointCloud.geometry?.boundingSphere?.radius || 1),
                    (pointCloud.geometry?.boundingSphere?.center.z || 0) + 1,
                  ]}
                />
                {/* Controls */}
                <ArcballControls
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                  ref={arcballControlsRef as any}
                  makeDefault
                  enabled={!isDragging || selectedTool !== EDITOR_TOOLS.MOVE}
                  enableAnimations={false}
                  cursorZoom
                />
              </>
            )}
            <color attach="background" args={[backgroundColor]} />
            <ambientLight />
          </Provider>
        </ContextBridge>
      </Canvas>
    </Box>
  )

  // In running e2e test at iPad (webkit) env with Playwright, somehow the overlay intercepts
  // mouse events even if the overlay is deactivated, so we need to create the branch below.
  if (initCompleted) {
    return components
  }
  return (
    <Box
      w="100vw"
      h="100vh"
      position="absolute"
      top={0}
      left={0}
      backgroundColor="black"
      zIndex={Z_INDEX.main_canvas.loading_pcd}
    >
      <VStack position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" alignItems="center">
        <Image src={LoadingAnimation} w={160} />
        <Text color="whiteAlpha.700" fontWeight="bold">
          {pointsLoadingText}
        </Text>
      </VStack>
    </Box>
  )
}

// Handle typing for props that is not required
MainCanvas.defaultProps = {
  cuboidAnchor: undefined,
}

export default MainCanvas
