import { AxiosProgressEvent } from 'axios'
import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { CADFileType, Project } from 'interfaces/interfaces'

const CAD_FILES_API_URL = `${API_GATEWAY_URL}/cad-files`

/**
 * generate a CAD file for shape objects in a project
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {FileType} file_type file extension of CAD file
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Project} project object
 */
export const postCadFiles = async (
  access_token: string,
  project_id: string,
  file_type: CADFileType,
  handleError: (err: unknown, processName: string) => void
): Promise<Project | null> => {
  const project = await axios
    .post<{ message: string; project: Project }>(
      CAD_FILES_API_URL,
      { project_id, file_type },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.project)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DOWNLOAD_CAD_FILE)
      return null
    })

  return project
}

/**
 * get signed URL for downloading a CAD file
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {string} signed URL
 */
export const getSignedUrlForCadFile = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${CAD_FILES_API_URL}?project_id=${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DOWNLOAD_CAD_FILE)
      return null
    })

  return url
}

/**
 * Download CAD file in blob format
 * @param {string} url URL for downloading CAD file
 * @param {React.Dispatch<React.SetStateAction<string>> | null} setDownloadText function to show texts during download
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {{blob: BlobPart[], type: string}} BlobPart[], type
 */
export const downloadCadFile = async (
  url: string,
  setDownloadText: React.Dispatch<React.SetStateAction<string>> | null,
  handleError: (err: unknown, processName: string) => void
): Promise<{ blob: BlobPart[]; type: string } | null> => {
  const blob = await axios
    .get<Blob>(url, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
        if (setDownloadText) setDownloadText(`Download File...${percentCompleted}%`)
      },
    })
    .then((response) => ({
      blob: [response.data],
      type: response.data.type,
    }))
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DOWNLOAD_CAD_FILE)
      return null
    })

  return blob
}
