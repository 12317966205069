import axios from 'extends/axios'
import saveAs from 'file-saver'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * get XML string of an inspection sheet by calling the API
 * @param access_token access token
 * @param projectId project ID
 * @param inspectionSheetId inspection sheet ID
 * @param useAbsMeanDistanceCriterion whether to use absolute mean distance criterion
 * @param useAbsCoverDistanceCriterion whether to use absolute cover distance criterion
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {string} XML string
 */
export const getXMLString = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  useAbsMeanDistanceCriterion: boolean,
  useAbsCoverDistanceCriterion: boolean,
  handleError: (err: unknown, processName: string) => void
): Promise<string> => {
  const criterionParams = []
  if (useAbsMeanDistanceCriterion) {
    criterionParams.push('mean_distance')
  }
  if (useAbsCoverDistanceCriterion) {
    criterionParams.push('cover_distance')
  }
  const criterionsString = criterionParams.join(',')
  const absCriterions = criterionsString ? `&abs_criterions=${criterionsString}` : ''

  const xmlString = await axios
    .get<{ external_sheet_data: string }>(
      `${PROJECTS_API_URL}/${projectId}/inspection-sheets/${inspectionSheetId}?external_sheet=true${absCriterions}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.external_sheet_data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DOWNLOAD_XML_FILE)
      return ''
    })

  return xmlString
}

/**
 * Create an XML exported file of an inspection sheet. Will immediately
 * forces the generated file to be saved by the user.
 *
 * @param {string} access_token access token
 * @param {string} projectId project ID
 * @param {string} inspectionSheetId inspection sheet ID
 * @param {string} fileName file name
 * @param {string} useAbsMeanDistanceCriterion whether to use absolute mean distance criterion
 * @param {string} useAbsCoverDistanceCriterion whether to use absolute cover distance criterion
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {boolean}
 */
export const generateXML = async (
  access_token: string,
  projectId: string,
  inspectionSheetId: string,
  fileName: string,
  useAbsMeanDistanceCriterion: boolean,
  useAbsCoverDistanceCriterion: boolean,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const xmlString = await getXMLString(
    access_token,
    projectId,
    inspectionSheetId,
    useAbsMeanDistanceCriterion,
    useAbsCoverDistanceCriterion,
    handleError
  )

  if (!xmlString) {
    return false
  }

  const blob = new Blob([xmlString], { type: 'application/xml' })
  saveAs(blob, fileName)

  return true
}
