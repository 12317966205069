import axios from 'extends/axios'
import { Matrix3 } from 'three'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { AxiosCuboid, Cuboid } from 'interfaces/shape'

const MASKING_REGIONS_URL = (projectId: string) => `${API_GATEWAY_URL}/projects/${projectId}/masking-regions`

/**
 * Fetches the masking regions for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the inspection area.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Promise<Cuboid[] | null>} - A promise that resolves to an array of Cuboids if successful, or null if an error occurs.
 */
export const getMaskingRegions = (
  access_token: string,
  projectId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Cuboid[] | null> =>
  axios
    .get<{ results: AxiosCuboid[] }>(MASKING_REGIONS_URL(projectId), {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) =>
      response.data.results.map((result) => ({
        ...result,
        rotation: new Matrix3().fromArray(result.rotation),
      }))
    )
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_MASK_REGION)
      return null
    })

/**
 * Creates a new masking region for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the inspection area.
 * @param {Cuboid} maskRegion - The masking region to create.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Promise<Cuboid | null>} - A promise that resolves to a Cuboid if successful, or null if an error occurs.
 */
export const createMaskingRegion = (
  access_token: string,
  projectId: string,
  maskRegion: Cuboid,
  handleError: (err: unknown, processName: string) => void
): Promise<Cuboid | null> =>
  axios
    .post<AxiosCuboid>(
      MASKING_REGIONS_URL(projectId),
      {
        center: maskRegion.center,
        extent: maskRegion.extent,
        rotation: maskRegion.rotation.toArray(),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => ({ ...response.data, rotation: new Matrix3().fromArray(response.data.rotation) }))
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.CREATE_MASK_REGION)
      return null
    })

/**
 * Deletes a masking region for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the inspection area.
 * @param {string} maskRegionId - The ID of the masking region to delete.
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Promise<boolean>} - A promise that resolves to true if successful, or false if an error occurs.
 */
export const deleteMaskingRegion = (
  access_token: string,
  projectId: string,
  maskRegionId: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> =>
  axios
    .delete(`${MASKING_REGIONS_URL(projectId)}/${maskRegionId}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_MASK_REGION)
      return false
    })
