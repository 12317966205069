import axios from 'extends/axios'

import { API_PROCESS_MAP } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { Cylinder, Overlap } from 'interfaces/interfaces'

import { calculateCenterAndDistance } from './Editor'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * Get all overlapping length object(s) of particular project.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Overlap[] | null}
 */
export const getOverlaps = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Overlap[] | null> => {
  const overlaps = await axios
    .get<{ results: Overlap[] }>(`${PROJECTS_API_URL}/${project_id}/overlap-lengths`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) =>
      response.data.results.map((overlap) => ({
        ...overlap,
        // Calculate the center point to display the distance label
        center: calculateCenterAndDistance({ points: overlap.positions_for_distance })?.[0],
      }))
    )
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_OVERLAPS)
      return null
    })

  return overlaps
}

/**
 * Get overlapping length of 2 overlapping cylinders
 * @param {string} access_token access token
 * @param {Cylinder[]} cylinders 2 overlapping cylinders
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Overlap | null}
 */
export const evaluateOverlap = async (
  access_token: string,
  cylinders: Cylinder[],
  handleError: (err: unknown, processName: string) => void
): Promise<Overlap | null> => {
  const overlap = await axios
    .post<Overlap>(
      `${API_GATEWAY_URL}/evaluate-overlap-length`,
      {
        cylinders: cylinders.map((cylinder) => ({
          transformation: cylinder.transformation,
          diameter: cylinder.diameter,
          length: cylinder.length,
        })),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.EVALUATE_OVERLAP)
      return null
    })

  return overlap
}

/**
 * Add new overlapping length object.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {Overlap} overlap temporary evaluated overlap
 * @param {function} handleError - Function to handle errors (open error modal).
 * @returns {Overlap | null}
 */
export const saveOverlap = async (
  access_token: string,
  project_id: string,
  overlap: Overlap,
  handleError: (err: unknown, processName: string) => void
): Promise<Overlap | null> => {
  const savedOverlap = await axios
    .post<Overlap>(
      `${PROJECTS_API_URL}/${project_id}/overlap-lengths`,
      {
        cylinder_shape_ids: overlap.cylinder_shape_ids,
        overlap_length: overlap.overlap_length,
        positions_for_distance: overlap.positions_for_distance,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.SAVE_OVERLAPS)
      return null
    })

  return savedOverlap
}

/**
 * Delete a particular overlapping length object.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} overlap_length_id overlap id
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {boolean}
 */
export const deleteOverlap = async (
  access_token: string,
  project_id: string,
  overlap_length_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${project_id}/overlap-lengths/${overlap_length_id}`, {
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_OVERLAP)
      return false
    })
  return result
}
