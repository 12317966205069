import { useCallback, useContext, useEffect, useState } from 'react'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { INPUT_SUGGESTIONS } from 'config/constants'

import { getInspectionSheets } from 'services/InspectionSheet'
import { getProjects } from 'services/Projects'

const useInspectionSheetInputSuggestions = (projectGroupId?: string) => {
  const { getAccessToken } = useContext(UserContext)
  const { handleError } = useContext(GlobalModalContext)

  const [inputSuggestions, setInputSuggestions] = useState<Record<string, string[]>>({})

  const fetchProjects = useCallback(async () => {
    if (!projectGroupId) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const allProjects = await getProjects(token, projectGroupId, handleError)
    if (!allProjects) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const suggestions: Record<string, string[]> = JSON.parse(JSON.stringify(INPUT_SUGGESTIONS))

    // Get all inspection sheets from the provided project
    const allInspectionSheets = await Promise.all(
      allProjects.map(async (project) => {
        const sheets = await getInspectionSheets(token, project.project_id, handleError)
        if (sheets.length) {
          return sheets
        }
        return null
      })
    )

    // Extract the suggestions info from each inspection sheet
    allInspectionSheets.forEach((inspectionSheets) => {
      inspectionSheets?.forEach((inspectionSheet) => {
        if (inspectionSheet?.construction_properties?.construction_project_name) {
          const { construction_properties } = inspectionSheet
          if (construction_properties.construction_project_name) {
            suggestions['工事名'].push(construction_properties.construction_project_name)
          }
          if (construction_properties.construction_type_detailed) {
            suggestions['種別'].push(construction_properties.construction_type_detailed)
          }
          if (inspectionSheet.creator_name) {
            suggestions['作成者'].push(inspectionSheet.creator_name)
          }
        }
      })
    })

    // Remove duplicated suggestions
    suggestions['工事名'] = Array.from(new Set(suggestions['工事名'])).sort()
    suggestions['種別'] = Array.from(new Set(suggestions['種別'])).sort()
    suggestions['作成者'] = Array.from(new Set(suggestions['作成者'])).sort()

    setInputSuggestions(suggestions)
    return true
  }, [getAccessToken, projectGroupId, handleError])

  useEffect(() => {
    void fetchProjects()
  }, [fetchProjects])

  return inputSuggestions
}

export default useInspectionSheetInputSuggestions
