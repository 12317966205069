import { FC, useContext, useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { AxiosError, AxiosHeaders } from 'axios'
import dayjs from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Cache } from 'three'

import { FolderIcon } from 'assets/icons'
import LoadingAnimation from 'assets/imgs/loading-animation.gif'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  API_PROCESS_MAP,
  JOBS_WATCHING_INTERVAL,
  MODAL_TYPES,
  UPLOAD_LIMIT_FILE_BYTE,
  UPLOAD_LIMIT_FILE_GIGABYTE,
} from 'config/constants'

import { ErrorResponse, Project, ProjectGroup } from 'interfaces/interfaces'

import { uploadFile } from 'services/AWS'
import { checkJobStatusForDownsampling, getSignedUrlForUploadFile, startDownSampling } from 'services/Pcd'
import { createProject, deleteProject, updateProjectFileName } from 'services/Projects'
import { getShapes } from 'services/Shapes'
import { returnContentType, shapesExist } from 'services/Util'

const CreateProjectModal: FC<{
  isOpen: boolean
  projectGroup?: ProjectGroup
  selectedProject?: Project
  onConfirm: (result?: boolean) => void
}> = ({ isOpen, projectGroup, selectedProject, onConfirm }) => {
  const { t } = useTranslation(['dashboard'])
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal, handleError } = useContext(GlobalModalContext)

  const uploadFileRef = useRef<HTMLInputElement>(null)

  const [project_name, setProjectName] = useState('')
  const [file_name, setFileName] = useState('')
  const [upload_file, setUploadFile] = useState<File | null>(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [isCreating, setIsCreating] = useState(false)
  const [isConverting, setIsConverting] = useState(false)
  const [runningJobToken, setRunningJobToken] = useState('')
  const [isCheckingJob, setIsCheckingJob] = useState(false)
  const [handlingProject, setHandlingProject] = useState<Project>()
  const [handlingUploadFileName, setHandlingUploadFileName] = useState('')

  const handleFileClick = () => {
    uploadFileRef.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file = e.target.files[0]

    if (file.size > UPLOAD_LIMIT_FILE_BYTE) {
      showErrorModal(
        t('components.create_project_modal.over_filesize_upload_limit', {
          ns: 'dashboard',
          upload_limit_file_byte: UPLOAD_LIMIT_FILE_GIGABYTE,
        })
      )
      setFileName('')
      if (uploadFileRef && uploadFileRef.current) uploadFileRef.current.value = ''
    } else {
      // if inspection area name is empty or same as file name without extension
      if (!project_name || project_name === upload_file?.name.split('.')[0]) {
        // set inspection area name as file name without extension
        setProjectName(file.name.split('.')[0])
      }
      setUploadFile(file)
      setFileName(file.name)
    }
  }

  const resetCreatingStates = () => {
    setUploadPercent(0)
    setIsCreating(false)
    setIsConverting(false)
    setIsCheckingJob(false)
  }

  // If there is any error on uploading file, delete the project that was created along with that file
  const revertCreatingProject = async (token: string, project: Project) =>
    deleteProject(token, project.project_id, handleError)

  const onCreateProject = async () => {
    if (!projectGroup) {
      return false
    }

    if (!selectedProject && !project_name) {
      showErrorModal(
        t('components.create_project_modal.inspection_area_name_is_not_provided', {
          ns: 'dashboard',
        })
      )
      return false
    }

    let uploadFileName = ''
    let content_type = null

    // Optional upload file
    if (file_name) {
      uploadFileName = dayjs().format('YYYYMMDDHHmmss_') + file_name
      content_type = returnContentType(file_name)

      if (!content_type) {
        showErrorModal(
          t('components.create_project_modal.invalid_file_extension', {
            ns: 'dashboard',
          })
        )
        return false
      }
      if (!upload_file) {
        showErrorModal(
          t('components.create_project_modal.invalid_file', {
            ns: 'dashboard',
          })
        )
        return false
      }
      if (upload_file.size > UPLOAD_LIMIT_FILE_BYTE) {
        showErrorModal(
          t('components.create_project_modal.over_filesize_upload_limit', {
            ns: 'dashboard',
            upload_limit_file_byte: UPLOAD_LIMIT_FILE_GIGABYTE,
          })
        )
        return false
      }
    }
    setHandlingUploadFileName(uploadFileName)

    // Reset state
    setIsCreating(true)

    const token = await getAccessToken()
    if (!token) {
      resetCreatingStates()
      return false
    }

    let project: Project | null
    if (!selectedProject) {
      project = await createProject(token, projectGroup.project_group_id, project_name, handleError)
      if (!project) {
        resetCreatingStates()
        return false
      }
    } else {
      project = selectedProject
    }
    setHandlingProject(project)

    // Optional upload file
    if (file_name && uploadFileName && content_type && upload_file) {
      // If file already exists, check if shapes have been created
      if (selectedProject?.down_sampled_file.name) {
        const shapes = await getShapes(token, selectedProject.project_id, handleError)
        if (shapes && shapesExist(shapes)) {
          showModal({
            body: t('components.create_project_modal.remove_shape_from_project_first', {
              ns: 'dashboard',
            }),
            confirmText: t('components.create_project_modal.close', {
              ns: 'dashboard',
            }),
            modalType: MODAL_TYPES.ALERT,
          })
          resetCreatingStates()
          return false
        }
      }

      const presignedUrl = await getSignedUrlForUploadFile(token, uploadFileName, content_type, handleError)
      if (!presignedUrl) {
        // If there is a project created along with this file, remove that project
        if (!selectedProject) {
          await revertCreatingProject(token, project)
        }
        resetCreatingStates()
        return false
      }

      // Upload file
      const uploadResult = await uploadFile(presignedUrl, content_type, upload_file, setUploadPercent, handleError)
      if (!uploadResult) {
        // If there is a project created along with this file, remove that project
        if (!selectedProject) {
          await revertCreatingProject(token, project)
        }
        resetCreatingStates()
        return false
      }

      // Reset state
      setIsConverting(true)

      // Down sample file
      const downSampleTranslationResult = await startDownSampling(token, uploadFileName, handleError)

      if (!downSampleTranslationResult) {
        // If there is a project created along with this file, remove that project
        if (!selectedProject) {
          await revertCreatingProject(token, project)
        }
        resetCreatingStates()
        return false
      }

      // Set the token to state to watch the status
      setRunningJobToken(downSampleTranslationResult)
    } else {
      // track event to mixpanel
      mixpanel.track('Create Inspection Area', {
        'Inspection area ID': project.project_id,
        'Inspection area Name': project.project_name,
        'Project ID': projectGroup.project_group_id,
        'Project Name': projectGroup.project_group_name,
        'Has pcd file': false,
      })

      // Reset states
      setFileName('')
      setProjectName('')
      setUploadFile(null)
      resetCreatingStates()

      if (uploadFileRef && uploadFileRef.current) {
        uploadFileRef.current.value = ''
      }

      onConfirm(true)
    }

    return true
  }

  // ------------ Jobs watching ------------ //
  useEffect(() => {
    const interval = setInterval(() => {
      void (async () => {
        if (projectGroup && runningJobToken && !isCheckingJob && handlingProject) {
          setIsCheckingJob(true)

          const token = await getAccessToken()
          if (!token) {
            resetCreatingStates()
            return false
          }

          const jobResult = await checkJobStatusForDownsampling(token, runningJobToken, handleError)
          // if job has been completed
          if (jobResult !== null) {
            if (uploadFileRef && uploadFileRef.current) {
              uploadFileRef.current.value = ''
            }

            // if job has been succeeded, continue creating project. Or else, just remove the watcher
            if (jobResult.translation.length) {
              // If file already exists, clear the cached pcd file
              if (selectedProject?.project_id && selectedProject.down_sampled_file.name) {
                Cache.remove(selectedProject.project_id)
              }

              // Add file name to project
              const updateResult = await updateProjectFileName(
                token,
                handlingProject.project_id,
                handlingUploadFileName,
                jobResult.translation,
                handleError
              )
              if (!updateResult) {
                // If there is a project created along with this file, remove that project
                if (!selectedProject) {
                  await revertCreatingProject(token, handlingProject)
                }
                resetCreatingStates()
                return false
              }
              // track event to mixpanel
              if (!selectedProject) {
                mixpanel.track('Create Inspection Area', {
                  'Inspection area ID': handlingProject.project_id,
                  'Inspection area Name': handlingProject.project_name,
                  'Project ID': projectGroup.project_group_id,
                  'Project Name': projectGroup.project_group_name,
                  'Has pcd file': true,
                  'Pcd file extension': handlingUploadFileName.split('.').pop(),
                  'Pcd file size (byte)': upload_file?.size,
                })
              } else {
                mixpanel.track('Add or Replace pcd file in Inspection Area', {
                  'Inspection area ID': handlingProject.project_id,
                  'Inspection area Name': handlingProject.project_name,
                  'Project ID': projectGroup.project_group_id,
                  'Project Name': projectGroup.project_group_name,
                  'Pcd file extension': handlingUploadFileName.split('.').pop(),
                  'Pcd file size (byte)': upload_file?.size,
                })
              }

              // Show the success
              onConfirm(true)
            } else if (!selectedProject) {
              // Show error message
              if (jobResult.error_object) {
                try {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const errorObject: { statusCode: number; body: string } = JSON.parse(jobResult.error_object)
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const responseData: ErrorResponse = JSON.parse(errorObject.body)
                  const axiosError = new AxiosError()
                  axiosError.response = {
                    status: errorObject.statusCode,
                    statusText: '',
                    headers: {},
                    config: {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                      headers: new AxiosHeaders(),
                    },
                    data: responseData,
                  }
                  handleError(axiosError, API_PROCESS_MAP.CREATE_PROJECT)
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e)
                }
              }

              // If there is a project created along with this file, remove that project
              await revertCreatingProject(token, handlingProject)
            }

            // Reset states
            setRunningJobToken('')
            setFileName('')
            setProjectName('')
            setUploadFile(null)
            resetCreatingStates()
          }
        }
        return true
      })()
    }, JOBS_WATCHING_INTERVAL)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningJobToken])

  const getHeader = () => {
    if (!selectedProject) {
      return t('components.create_project_modal.create_inspection_area', {
        ns: 'dashboard',
      })
    }
    if (selectedProject.down_sampled_file.name) {
      return t('components.create_project_modal.change_file', {
        ns: 'dashboard',
      })
    }
    return t('components.create_project_modal.upload_file', {
      ns: 'dashboard',
    })
  }
  const getConfirmLabel = () => {
    if (!selectedProject) {
      return t('components.create_project_modal.create', {
        ns: 'dashboard',
      })
    }
    if (selectedProject.down_sampled_file.name) {
      return t('components.create_project_modal.change', {
        ns: 'dashboard',
      })
    }
    return t('components.create_project_modal.upload', {
      ns: 'dashboard',
    })
  }

  return (
    <Modal closeOnOverlayClick={!isCreating} isOpen={isOpen} onClose={onConfirm} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getHeader()}</ModalHeader>
        <ModalCloseButton hidden={isCreating} />
        {isConverting ? (
          <ModalBody display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Image src={LoadingAnimation} w={160} />
            <Box textAlign="center" my={4}>
              <Text color="secondary.400" fontWeight="bold">
                {t('components.create_project_modal.converting_file', {
                  ns: 'dashboard',
                })}
              </Text>
            </Box>
          </ModalBody>
        ) : (
          <>
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel htmlFor="file">
                  {' '}
                  {t('components.create_project_modal.choose_supported_file', {
                    ns: 'dashboard',
                  })}
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    data-testid="file-upload"
                    hidden
                    ref={uploadFileRef}
                    type="file"
                    accept={isMobile ? '' : '.las,.laz,.ply,.pts,.xyz,.xyzrgb'}
                    onChange={handleFileChange}
                  />
                  <Input isReadOnly value={file_name} />
                  <InputRightElement w={14}>
                    <IconButton
                      aria-label="Upload file"
                      borderColor="inherit"
                      borderWidth="1px"
                      className="upload-file-button"
                      disabled={isCreating}
                      fontSize="lg"
                      icon={<FolderIcon />}
                      onClick={handleFileClick}
                      w="100%"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {!selectedProject && (
                <FormControl>
                  <FormLabel htmlFor="project_name">
                    {' '}
                    {t('components.create_project_modal.inspection_area_name', {
                      ns: 'dashboard',
                    })}
                  </FormLabel>
                  <Input
                    id="project_name"
                    type="text"
                    value={project_name}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </FormControl>
              )}
            </ModalBody>

            <ModalFooter mt={8}>
              <Button disabled={isCreating} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
                {t('components.create_project_modal.cancel', {
                  ns: 'dashboard',
                })}
              </Button>

              <Button
                colorScheme="primary"
                disabled={(!selectedProject && !project_name) || (selectedProject && !upload_file) || isCreating}
                isLoading={isCreating}
                loadingText={
                  file_name
                    ? t('components.create_project_modal.upload_in_progress', {
                        ns: 'dashboard',
                        upload_percent: uploadPercent,
                      })
                    : t('components.create_project_modal.creating', {
                        ns: 'dashboard',
                      })
                }
                minW="100px"
                onClick={onCreateProject}
                py={2}
              >
                {getConfirmLabel()}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default CreateProjectModal
