import { FC, useContext, useState } from 'react'

import { ChevronDownIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import fileDownload from 'js-file-download'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { CAD_FILE_TYPES, MODAL_TYPES } from 'config/constants'

import { CADFileType } from 'interfaces/interfaces'

import { downloadCadFile, getSignedUrlForCadFile, postCadFiles } from 'services/Cad'
import { shapesExist } from 'services/Util'

const CADExportButton: FC = () => {
  //* contexts, hooks呼び出し
  const { t } = useTranslation(['projects'])
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { project, shapes } = useContext(EditorContext)
  const { getAccessToken } = useContext(UserContext)

  //* ダウンロード処理用
  const [extension, setExtension] = useState<CADFileType>(CAD_FILE_TYPES.ifc)
  const [isLoading, setIsLoading] = useState(false)
  const [downloadText, setDownloadText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  //* ダウンロード処理
  const download = async () => {
    if (!project?.project_id) {
      showModal({
        body: t('main_canvas.action_buttons.message_no_project', { ns: 'projects' }),
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    //* cadファイルの生成
    setIsLoading(true)
    setIsModalOpen(false)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const generatedCadProject = await postCadFiles(token, project.project_id, extension, handleError)
    if (!generatedCadProject) {
      setIsLoading(false)
      return false
    }

    if (!generatedCadProject.cad_file.name) {
      setIsLoading(false)
      showModal({
        body: t('main_canvas.action_buttons.message_no_cad_data', { ns: 'projects' }),
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    // track with mixpanel
    mixpanel.track('Export CAD file', {
      'Inspection area ID': project.project_id,
      'Inspection area Name': project.project_name,
      'File extension': extension,
    })

    //* cadファイル用の署名付きURL取得
    const downloadUrl = await getSignedUrlForCadFile(token, project.project_id, handleError)
    if (!downloadUrl) {
      setIsLoading(false)
      return false
    }

    //* 署名付きURLからcadファイルをダウンロード
    const downloadResult = await downloadCadFile(downloadUrl, setDownloadText, handleError)
    if (!downloadResult) {
      setIsLoading(false)
      return false
    }

    //* ファイルダウンロード処理
    const { blob, type } = downloadResult
    const fileBlob = new Blob(blob, { type })
    fileDownload(fileBlob, generatedCadProject.cad_file.name)

    setIsLoading(false)
    return true
  }

  if (!shapesExist(shapes)) return null

  return (
    <>
      <Button
        colorScheme="secondary"
        rightIcon={<DownloadIcon />}
        size={isTablet ? 'lg' : 'sm'}
        fontSize={isTablet ? 'lg' : 'xs'}
        variant="toolbar"
        onClick={() => setIsModalOpen(true)}
        isLoading={isLoading}
        disabled={isLoading}
        spinnerPlacement="end"
        loadingText={downloadText.substring(downloadText.indexOf('.'))}
        isFullWidth
        justifyContent="space-between"
        whiteSpace="pre-wrap"
        textAlign="left"
      >
        {t('main_canvas.action_buttons.export_cad', { ns: 'projects' })}
      </Button>

      <Modal
        closeOnOverlayClick
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        trapFocus={false}
        isCentered
        size="xs"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">
            {t('main_canvas.action_buttons.export_cad_modal.title', { ns: 'projects' })}
          </ModalHeader>
          <ModalCloseButton hidden={isLoading} />
          <ModalBody position="relative">
            <FormControl>
              <FormLabel fontSize="sm">
                {t('main_canvas.action_buttons.export_cad_modal.text', { ns: 'projects' })}
              </FormLabel>
              <Menu autoSelect={false}>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  backgroundColor="transparent"
                  borderWidth={1}
                  width="100%"
                  textAlign="left"
                  fontWeight="normal"
                >
                  .{extension}
                </MenuButton>
                <MenuList>
                  {Object.values(CAD_FILE_TYPES).map((val: CADFileType) => (
                    <MenuItem key={val} onClick={() => setExtension(val)}>
                      .{val}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
          </ModalBody>

          <ModalFooter mt={8} justifyContent="center">
            <Button me={3} py={2} minW="100px" onClick={() => setIsModalOpen(false)}>
              {t('main_canvas.action_buttons.export_cad_modal.cancel', { ns: 'projects' })}
            </Button>
            <Button colorScheme="primary" me={3} py={2} minW="100px" onClick={() => download()}>
              {t('main_canvas.action_buttons.export_cad_modal.confirm', { ns: 'projects' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CADExportButton
